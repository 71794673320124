import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory, useNavigate } from 'react-router-dom'
import EmptyLayout from 'layout/EmptyLayout';
import {
    Container, Button, CardFooter, Input, CardHeader, CardBody, Label, ModalHeader, ModalBody, ModalFooter, Card, Col, Modal, Table, Row, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,

} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsSearch, BsTrash } from 'react-icons/bs';
import { FiFilter } from 'react-icons/fi';
import { BiSort } from 'react-icons/bi';
import { AiOutlinePlus } from 'react-icons/ai'
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'
import Sport from '../assets/Sports.png';
import home from '../assets/home-hobbies-img.png';
import excercise from '../assets/excercise.png';
import Sidebar from 'components/Sidebar';
import { Spinner } from 'reactstrap';
import PageSpinner from 'components/PageSpinner';
import axios from 'axios';
import NoImageAvail from '../assets/no-image.png';
import ProfileImage from "../assets/profile_placeholder.png";
import PlaceholderIcon from "../assets/profile_placeholder.png";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GoDeviceDesktop } from 'react-icons/go';
import { GrEdit } from 'react-icons/gr';
import { FaArrowLeft } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { HiArrowLeft } from 'react-icons/hi';
import { useCookies } from 'react-cookie';
import { AiOutlineSend } from 'react-icons/ai';

const initialUserData = Object.freeze({
    first_name: '',
    last_name: '',
    company_name: '',
    company_position: '',
    contact_phone: '',
    company_email: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    province: '',
    company_industry: '',
    company_phone: '',
    email: '',
    image_url: ''
});

const initialMessageData = Object.freeze({
    content: "",
    user_id: "",
    recipient_id: "",
    user_name: "",
});

function AdminMessagesPage(props) {
    // page content
    const navigate = useNavigate();
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const user_id = query.get('id');
    const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn', 'userRole', 'awardStatus', 'token']);
    const currentUser = cookies.currentUser;
    const [user, setUser] = useState([]);
    const [userLoading, setUserLoading] = useState(true);
    const [imgFile, setImgFile] = useState("");
    const [profileData, setProfileData] = useState(initialUserData);
    const [formStatus, setFormStatus] = useState("standby"); 
    const [isSidebar, setIsSidebar] = useState(false);
    const [messages, setMessages] = useState([]);
    const [messagesLoading, setMessagesLoading] = useState(true);
    const [commentAutoScroll, setCommentAutoScroll] = useState(false);
    const [messageData, setMessageData] = useState(initialMessageData);
    const [reloadCount, setReloadCount] = useState(1);
    const [messageRecipient, setMessageRecipient] = useState(initialUserData);
    const [error, setError] = useState();

    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
    })

    function getSidebar() {
        setIsSidebar(!isSidebar);
    }

    // Update Profile
    const [updateProfileShow, setUpdateProfileShow] = useState(false);


    const toggleUpdateProfileShow = () => {
        setUpdateProfileShow(!updateProfileShow);
    }

    const preventDefault = (e) => {
        e.preventDefault();
    }

    const handleChangeMessage = (e) => {
        setMessageData({
            ...messageData,
            [e.target.name]: e.target.value,
        });
    }

    const submitMessage = () => {
        if (messageData.content != "") {
            setFormStatus('loading');
            axios({
                method: 'post',
                url: process.env.REACT_APP_API_ENDPOINT + 'message',
                data: ({
                    user_id: currentUser,
                    recipient_id: user_id,
                    content: messageData.content
                })
            }).then(function (response) {
                if (response.data.status == 'Success') {
                    // toast.success('Listing successfully created!', {
                    //     position: "top-right",
                    //     autoClose: 1500,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    //     theme: "light",
                    // });
                    setFormStatus('standby');
                    setCommentAutoScroll(false);
                    setReloadCount(reloadCount + 1);
                    setMessageData({
                        ...messageData,
                        content: "",
                    });
                    // navigate('/');
                } else {
                    // toast.error('Listing creation failed!', {
                    //     position: "top-right",
                    //     autoClose: 1500,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    //     theme: "light",
                    // });
                    setFormStatus('standby')
                }
            }).catch(function (error) {
                setError(error);
                toast.error('There has been an error with the server, please try again!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
        } else {
            toast.error('Please insert message!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

    };

    useEffect(() => {
        // This is the axios GET

        axios.get(process.env.REACT_APP_API_ENDPOINT + 'user/' + currentUser + "/message")
            .then((response) => {
                const data = response.data;
                const result = data.data;
                if (result) {
                    setUserLoading(false);
                    setMessages(result);
                    setMessagesLoading(false);
                    setCommentAutoScroll(false);
                }
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setMessagesLoading(false);
                setCommentAutoScroll(false);
                setUserLoading(false);
            });

        axios.get(process.env.REACT_APP_API_ENDPOINT + 'user/' + currentUser)
            // axios.get('https://bis.jenocabrera.online/api/user/'+user_id)
            .then((response) => {
                const data = response.data;
                const result = data.data;
                // console.log("Results Data:", result);
                if (result) {
                    setUserLoading(false);
                    setUser(result);
                    setImgFile(result.image_url);
                }
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setUserLoading(false);
            });

        // axios.get(process.env.REACT_APP_API_ENDPOINT + 'user/' + currentUser)
        // // axios.get('https://bis.jenocabrera.online/api/user/'+user_id)
        // .then((response) => {
        //     const data = response.data;
        //     const result = data.data;
        //     // console.log("Results Data:", result);
        //     if (result) {
        //         setMessageRecipient(result);
        //     }
        // }).catch(error => {
        //     // setError(error);
        // });


        setMessageData({
            ...messageData,
            user_id: currentUser,
            recipient_id: user_id
        });
    }, [currentUser, user_id]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCommentAutoScroll(true);
        }, 500);
        return () => clearInterval(timer);
    }, [commentAutoScroll]);

    return (
        <>
            <ToastContainer style={{ width: "370px" }} />
            <section id='user-messages'>
                <Container fluid>
                    <Row>
                        <Col lg='2'>
                            {isSidebar || windowDimension.winWidth > 1024 ?
                                <Sidebar clickSidebar={getSidebar} />
                                :
                                null
                            }
                            <button onClick={getSidebar} className={isSidebar ? "hidden" : "hamburger-btn show"}><GiHamburgerMenu /></button>
                            {/* <Sidebar /> */}
                        </Col>
                        <Col lg='10' className='right-container'>
                            <div className="page-container">
                                <div>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="mobile-display">
                                                <div>
                                                    <h2 className="ss-header mb-0">Messages</h2>
                                                    <div className="divider"></div>
                                                </div>
                                                {/* <button className='button back view-button mb-3' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button> */}
                                            </div>
                                            <div className="desktop-display">
                                                <Row>
                                                    <Col lg="9" className="mb-0">
                                                        <h2 className="ss-header mb-0">Messages</h2>
                                                        <div className="divider"></div>
                                                    </Col>
                                                    <Col lg="3" className="mb-0 text-right">
                                                        <button className='button back view-button mb-3' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Card className="messages mb-3">
                                                {/* <CardHeader>
                                                <h3 className="header-text mb-0" style={{ color: "#1ec2f3" }}>CHAT WITH US</h3>
                                            </CardHeader> */}
                                                {/* <CardBody className="pt-4 pb-0 comment-scroll"> */}
                                                <CardBody className={commentAutoScroll == true ? "comment-auto-scroll text-center" : "comment-scroll  text-center"}>
                                                    <div>
                                                        {!messagesLoading ?
                                                            <>
                                                                {messages.length > 0 ?
                                                                    <>
                                                                        {messages.map(({ id, sender, date, user, content, admin_remarks }, index) => (
                                                                            <div className="entry-comment-container" key={index}>
                                                                                <>
                                                                                    {index < 1 ?
                                                                                        null
                                                                                        :
                                                                                        <hr />
                                                                                    }

                                                                                    <p className="message-date text-center">{new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</p>

                                                                                    <Card className="comment-body" style={{ border: "none" }}>
                                                                                        <CardBody className="px-1">
                                                                                            {sender.user_id == currentUser ?
                                                                                                <Row className="align-items-end" style={{justifyContent: 'right'}}>
                                                                                                    <Col lg="10" className="mb-0 px-0">
                                                                                                        <div className='comment-send'>
                                                                                                            <Card>
                                                                                                                <CardBody className="px-2 py-2">
                                                                                                                    <p className="comment-data">{content}</p>
                                                                                                                </CardBody>
                                                                                                            </Card>
                                                                                                            {sender.sender_name}
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                    <Col lg="2" className="mb-0 px-0 user-image-container">
                                                                                                        <img src={PlaceholderIcon} className="user-icon-image" />
                                                                                                    </Col>
                                                                                                </Row>
                                                                                                :
                                                                                                <Row className="align-items-end">
                                                                                                    <Col lg="2" className="mb-0 px-0 user-image-container">
                                                                                                        <img src={PlaceholderIcon} className="user-icon-image" />
                                                                                                    </Col>
                                                                                                    <Col lg="10" className="mb-0 px-0">
                                                                                                        <div className="comment-recieve">
                                                                                                            <Card>
                                                                                                                <CardBody className="px-1 py-1">
                                                                                                                    <p className="comment-data">{content}</p>
                                                                                                                </CardBody>
                                                                                                            </Card>
                                                                                                            {sender.sender_name}
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            }
                                                                                        </CardBody>
                                                                                    </Card>

                                                                                </>
                                                                            </div>
                                                                        ))}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <p className="text-center mt-3">No messages found.</p>
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            <p className="mt-3"><PageSpinner /></p>
                                                        }
                                                    </div>
                                                </CardBody>
                                                <CardFooter className="px-2 py-2">
                                                    <Row className="align-items-center">
                                                        <Col lg="11" className="mb-0">
                                                            <Input type="textarea" rows="1" placeholder="Send message" className="comment-area" onChange={handleChangeMessage} value={messageData.content} name="content" />
                                                        </Col>
                                                        <Col lg="1" className="mb-0 text-right">
                                                            {formStatus != "standby" ?
                                                                <button className="send-message-button" onClick={preventDefault}><Spinner color="info" size="sm" /></button>
                                                                :
                                                                <button className="send-message-button" onClick={submitMessage}><AiOutlineSend color="#932F8C" size="25px" /></button>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default AdminMessagesPage;