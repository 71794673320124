import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory, useNavigate } from 'react-router-dom'
import EmptyLayout from 'layout/EmptyLayout';
import {
    Container, Button, CardFooter, Input, CardBody, Label, ModalHeader, ModalBody, ModalFooter, Card, Col, Modal, Table, Row, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,

} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from 'components/Sidebar';
import { Spinner } from 'reactstrap';
import PageSpinner from 'components/PageSpinner';
import axios from 'axios';
import NoImageAvail from '../assets/no-image.png';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GoDeviceDesktop } from 'react-icons/go';
import { GrEdit } from 'react-icons/gr';
import ProfileImage from "../assets/profile_placeholder.png";
import { AiFillLock, AiFillUnlock } from 'react-icons/ai';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdViewList } from 'react-icons/md';
import { HiArrowLeft } from 'react-icons/hi';
import { FaEnvelope } from 'react-icons/fa';

function AdminUsersPage(props) {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');
    const [usersLoading, setUsersLoading] = useState(true);
    const [reloadCount, setReloadCount] = useState(1);
    const [isSidebar, setIsSidebar] = useState(false);
    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
    })

  function getSidebar() {
    setIsSidebar(!isSidebar);
  }

    // page content
    const navigate = useNavigate();

    const unlockStatus = (e) => {
        axios.put(process.env.REACT_APP_API_ENDPOINT + 'user/'+e, 
            {
                status: "Unlocked",
            }
        )
        .then(({ data }) => {
            // do something with success
            const json = data;
            if (json.status == "Success") {
                // Success
                toast.success('User unlocked successfully!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setReloadCount(reloadCount + 1);
            } else {
                // Failed
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        })
        .catch((err) => {
            // Catch Server error
            // Alert server error here
            toast.error('An error occured. Please try again or contact the administrator.', {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        });
    }

    const lockStatus = (e) => {
        axios.put(process.env.REACT_APP_API_ENDPOINT + 'user/'+e, 
            {
                status: "Locked",
            }
        )
        .then(({ data }) => {
            // do something with success
            const json = data;
            if (json.status == "Success") {
                // Success
                toast.success('User locked successfully!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setReloadCount(reloadCount + 1);
            } else {
                // Failed
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        })
        .catch((err) => {
            // Catch Server error
            // Alert server error here
            toast.error('An error occured. Please try again or contact the administrator.', {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        });
    }

    useEffect(() => {
        // This is the axios GET
        axios.get(process.env.REACT_APP_API_ENDPOINT + 'user')
        // axios.get('https://bis.jenocabrera.online/api/user')
            .then((response) => {
                const data = response.data;
                const result = data.data;
                // console.log("Results Data:", result);
                if (result) {
                    setUsersLoading(false);
                    setUsers(result);
                }
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setUsersLoading(false);
            });
    }, [reloadCount]);


    return (
        <section id='marketing'>
            <ToastContainer style={{ width: "370px" }} />
            <Container fluid>
                <Row>
                    <Col lg='2'>
                    {isSidebar || windowDimension.winWidth > 1024 ?
                    <Sidebar clickSidebar={getSidebar}/>
                    :
                    null
                    }
                    <button onClick={getSidebar} className={isSidebar ? "hidden" : "hamburger-btn show"}><GiHamburgerMenu /></button>
                        {/* <Sidebar /> */}
                    </Col>
                    <Col lg='10' className='right-container'>
                        <div className="pb-5">
                            <Row>
                                <Col lg="12" className="mb-0">
                                    <h2 className="ss-header mb-0">Users</h2>
                                    <div className="divider"></div>
                                </Col>
                                {/* <Col lg="3" className="mb-0 text-right">
                                    <button className='button back view-button mb-3' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button>
                                </Col> */}
                            </Row>
                            {usersLoading ?
                                <>
                                    <Card>
                                        <CardBody className="text-center">
                                            <PageSpinner />
                                        </CardBody>
                                    </Card>
                                </>
                                :
                                <>
                                    {users.length > 0 ?
                                        <>
                                            {users.map(({ id, name, email, phone, status, image_url, company_name, company_position }, index) => (
                                                <Card className="mb-3 p-2">
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg={1}>
                                                                {image_url != "" ?
                                                                    // <div className='profile-picture-container' style={{ backgroundImage: "url(" + process.env.REACT_APP_URL + "/storage/gallery/"+imgFile+ ")" }}></div>
                                                                    <div className='profile-picture-container' style={{ backgroundImage: "url(https://bis.jenocabrera.online/storage/gallery/"+image_url+ ")", height: '59px', width: '100%' }}></div>
                                                                    :
                                                                    <div className='profile-picture-container' style={{ backgroundImage: "url(" + ProfileImage + ")", height: '59px', width: '100%' }}></div>
                                                                }
                                                            </Col>
                                                            <Col lg={5}>
                                                                <p className="user-name mb-0">
                                                                    <strong>{name}</strong>
                                                                </p>
                                                                <p className="user-email mb-0">
                                                                    <a href={`mailto:${email}`}>{email}</a>
                                                                </p>
                                                                <p className="user-phone mb-0">
                                                                    {/* <a href={`tel:${phone}`}>{phone}</a> */}
                                                                    {phone}
                                                                </p>
                                                            </Col>
                                                            <Col lg={5} className="user-admin-info">
                                                                <p className="user-company-name mb-0">
                                                                    <strong>{company_name}</strong>
                                                                </p>
                                                                <p className="user-company-position mb-0">
                                                                    {company_position}
                                                                </p>
                                                            </Col>
                                                            <div className="action_table_container">
                                                                <div className="action_table">
                                                                    <BsThreeDotsVertical />
                                                                    <Card className="action_content">
                                                                        <CardBody className="action_container">
                                                                            <div className="link_container" onClick={function() { navigate('/admin/user?id='+id) }}>
                                                                                <GoDeviceDesktop /> <a href="javascript:void(0)">VIEW</a>
                                                                            </div>
                                                                            {/* <div className="link_container">
                                                                                <FaEnvelope /> <a href={`/admin/messages?id=${id}`}>MESSAGES</a>
                                                                            </div> */}
                                                                            {/* <div className="link_container">
                                                                            <GrEdit /> <a href={`/user?id=${id}`}>EDIT</a>
                                                                            </div>
                                                                            <div className="link_container d-flex justify-content-between">
                                                                            <BsTrash /> <a href={`/user?id=${id}`}>DELETE</a>
                                                                            </div> */}
                                                                        </CardBody>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                            {/* {status == "Locked" ?
                                                                <div className="lock-unlock-container" onClick={function(){ unlockStatus(id)}}>
                                                                    <AiFillLock size="25px" color="#D9524E" />
                                                                </div>
                                                                :
                                                                <div className="lock-unlock-container" onClick={function(){ lockStatus(id)}}>
                                                                    <AiFillUnlock size="25px" color="#5DB85D" />
                                                                </div>
                                                            } */}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                                
                                            ))}
                                        </>
                                        :
                                        <>
                                            <Card>
                                                <CardBody className="text-center">
                                                    <p className="mb-0">No records found.</p>
                                                </CardBody>
                                            </Card>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AdminUsersPage;