import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory, useNavigate } from 'react-router-dom'
import EmptyLayout from 'layout/EmptyLayout';
import {
    Container, Button, CardFooter, Input, CardBody, Label, ModalHeader, ModalBody, ModalFooter, Card, Col, Modal, Table, Row, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCookies } from 'react-cookie';
import { Spinner } from 'reactstrap';
import PageSpinner from 'components/PageSpinner';
import axios from 'axios';
import NoImageAvail from '../assets/no-image.png';
import ProfileImage from "../assets/profile_placeholder.png";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GoDeviceDesktop } from 'react-icons/go';
import { GrEdit } from 'react-icons/gr';
import { FaArrowLeft } from 'react-icons/fa';
import { HiArrowLeft } from 'react-icons/hi';
import { AiOutlineSend } from 'react-icons/ai';
import PlaceholderIcon from "../assets/profile_placeholder.png";
import Sidebar from 'components/Sidebar';
import { GiHamburgerMenu } from 'react-icons/gi';

const initialUserData = Object.freeze({
    first_name: '',
    last_name: '',
    company_name: '',
    company_position: '',
    contact_phone: '',
    company_email: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    province: '',
    company_industry: '',
    company_phone: '',
    email: '',
    image_url: ''
});

const initialMessageData = Object.freeze({
    content: "",
    user_id: "",
    recipient_id: "",
    user_name: "",
});

function AdminUserProfilePage(props) {
    // page content
    const navigate = useNavigate();
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const user_id = query.get('id');
    const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn', 'userRole', 'awardStatus', 'token']);
    const currentUser = cookies.currentUser;
    const [user, setUser] = useState([]);
    const [userLoading, setUserLoading] = useState(true);
    const [imgFile, setImgFile] = useState("");
    const [profileData, setProfileData] = useState(initialUserData);
    const [formStatus, setFormStatus] = useState("standby");
    const [messages, setMessages] = useState([]);
    const [messagesLoading, setMessagesLoading] = useState(true);
    const [commentAutoScroll, setCommentAutoScroll] = useState(false);
    const [messageData, setMessageData] = useState(initialMessageData);
    const [reloadCount, setReloadCount] = useState(1);
    const [error, setError] = useState();
    const [isSidebar, setIsSidebar] = useState(false);
    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
    })

    function getSidebar() {
        setIsSidebar(!isSidebar);
    }

    // Update Profile
    const [updateProfileShow, setUpdateProfileShow] = useState(false);

    const handleChangeMessage = (e) => {
        setMessageData({
            ...messageData,
            [e.target.name]: e.target.value,
        });
    }

    const toggleUpdateProfileShow = () => {
        setUpdateProfileShow(!updateProfileShow);
    }

    const preventDefault = (e) => {
        e.preventDefault();
    }

    const submitMessage = () => {
        if (messageData.content != "") {
            setFormStatus('loading');
            axios({
                method: 'post',
                url: process.env.REACT_APP_API_ENDPOINT + 'message',
                data: ({
                    user_id: currentUser,
                    recipient_id: user_id,
                    content: messageData.content
                })
            }).then(function (response) {
                if (response.data.status == 'Success') {
                    // toast.success('Listing successfully created!', {
                    //     position: "top-right",
                    //     autoClose: 1500,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    //     theme: "light",
                    // });
                    setFormStatus('standby');
                    setCommentAutoScroll(false);
                    setReloadCount(reloadCount + 1);

                    // navigate('/');
                } else {
                    toast.error('An error occured. Please try again or contact the administrator.', {
                        position: 'top-right',
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setFormStatus('standby');
                }
            }).catch(function (error) {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setFormStatus('standby');
            })
        } else {
            toast.error('Please insert message!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setFormStatus('standby');
        }

    };

    useEffect(() => {
        const timer = setInterval(() => {
            setCommentAutoScroll(true);
        }, 500);
        return () => clearInterval(timer);
    }, [commentAutoScroll]);

    useEffect(() => {
        // This is the axios GET

        axios.get(process.env.REACT_APP_API_ENDPOINT + 'user/' + user_id)
            // axios.get('https://bis.jenocabrera.online/api/user/'+user_id)
            .then((response) => {
                const data = response.data;
                const result = data.data;
                // console.log("Results Data:", result);
                if (result) {
                    setUserLoading(false);
                    setUser(result);
                    setImgFile(result.image_url);
                }
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setUserLoading(false);
            });

        axios.get(process.env.REACT_APP_API_ENDPOINT + 'user/' + user_id + "/message?recipient_id=" + currentUser)
            .then((response) => {
                const data = response.data;
                const result = data.data;
                if (result) {
                    setUserLoading(false);
                    setMessages(result);
                    setMessagesLoading(false);
                    setCommentAutoScroll(false);
                }
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setMessagesLoading(false);
                setCommentAutoScroll(false);
                setUserLoading(false);
            });

        setMessageData({
            ...messageData,
            user_id: currentUser,
            recipient_id: user_id,
            content: ""
        });

    }, [user_id, reloadCount]);

    return (
        <>
            <ToastContainer style={{ width: "370px" }} />
            <section id='user-profile'>
                <Container fluid>
                    <Row>
                        <Col lg='2'>
                        {isSidebar || windowDimension.winWidth > 1024 ?
                            <Sidebar clickSidebar={getSidebar}/>
                        :
                            null
                        }
                        <button onClick={getSidebar} className={isSidebar ? "hidden" : "hamburger-btn show"}><GiHamburgerMenu /></button>
                            {/* <Sidebar /> */}
                        </Col>
                        <Col lg='10' className='right-container'>
                            <div className="page-container">
                                <div>
                                    <div className="mobile-display">
                                        <div>
                                            <h2 className="ss-header mb-0">Profile</h2>
                                            <div className="divider mb-0"></div>
                                        </div>
                                        <div>
                                            <button className='button back view-button' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col lg={3}>
                                            <Card className="mb-3">
                                                <CardBody>
                                                    {imgFile ?
                                                        // <div className='profile-picture-container' style={{ backgroundImage: "url(" + process.env.REACT_APP_URL + "/storage/gallery/"+imgFile+ ")" }}></div>
                                                        <div className='profile-picture-container' style={{ backgroundImage: "url(https://bis.jenocabrera.online/storage/gallery/" + imgFile + ")" }}></div>
                                                        :
                                                        <div className='profile-picture-container' style={{ backgroundImage: "url(" + ProfileImage + ")" }}></div>
                                                    }
                                                    <h3 className="ss-subheader text-center mb-0 mt-3">{user.first_name} {user.last_name}</h3>
                                                    {/* <p className="text-center mb-0">Entrant</p> */}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={9}>
                                            <div className="desktop-display">
                                                <Row>
                                                    <Col lg="9" className="mb-0">
                                                        <h2 className="ss-header mb-0">Profile</h2>
                                                        <div className="divider"></div>
                                                    </Col>
                                                    <Col lg="3" className="mb-0 text-right">
                                                        {/* <a href="/edit-profile">
                                                        <div className="bis-tooltip mr-1"><FaPencilAlt className="text-black edit-icon" />
                                                            <span className="bis-tooltiptext">EDIT</span>
                                                        </div>
                                                    </a> */}
                                                        <button className='button back view-button' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button>
                                                        {/* <div className="ss-tooltip" onClick={() => { navigate(-1); }}><FaArrowLeft className="text-black edit-icon" />
                                                        <span className="ss-tooltiptext">BACK</span>
                                                    </div> */}
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Card className="preview_content">
                                                <CardBody>
                                                    <h3 className="ss-subheader">PERSONAL DETAILS</h3>
                                                    <hr />
                                                    <Row>
                                                        <Col lg="6" className="mb-0">
                                                            <p className="ss_col_title">PHONE NUMBER:</p>
                                                            <p className="ss_entry_content">
                                                                {user.contact_phone != "" ?
                                                                    <>
                                                                        {user.contact_phone}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        -
                                                                    </>
                                                                }
                                                            </p>
                                                            <hr />
                                                        </Col>
                                                        <Col lg="6" className="mb-0">
                                                            <p className="ss_col_title">EMAIL ADDRESS:</p>
                                                            <p className="ss_entry_content">
                                                                {user.email != "" ?
                                                                    <>
                                                                        {user.email}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        -
                                                                    </>
                                                                }
                                                            </p>
                                                            <hr />
                                                        </Col>
                                                        <Col lg="6" className="mb-0">
                                                            <p className="ss_col_title">ADDRESS:</p>
                                                            <p className="ss_entry_content">
                                                                {user.address_line_1 != "" && user.city != "" && user.province ?
                                                                    null
                                                                    :
                                                                    <>
                                                                        -
                                                                    </>
                                                                }
                                                                {user.address_line_1}
                                                                {user.city != "" ?
                                                                    <>
                                                                        , {user.city}
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                                {user.province != "" ?
                                                                    <>
                                                                        , {user.province}
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                            </p>
                                                        </Col>
                                                        {/* <Col lg="6" className="mb-0">
                                                    <p className="ss_col_title">ADDRESS LINE 2:</p>
                                                    <p className="ss_entry_content">{user.address_line_2}, {user.city}, {user.province}</p>
                                                </Col> */}
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            <Card className="preview_content mt-3 mb-3">
                                                <CardBody>
                                                    <h3 className="ss-subheader">COMPANY DETAILS</h3>
                                                    <hr />
                                                    <Row>
                                                        <Col lg="6" className="mb-0">
                                                            <p className="ss_col_title">COMPANY</p>
                                                            <p className="ss_entry_content">{user.company_name ? user.company_name : "-"}</p>
                                                            <hr />
                                                        </Col>
                                                        <Col lg="6" className="mb-0">
                                                            <p className="ss_col_title">POSITION</p>
                                                            <p className="ss_entry_content">{user.company_position ? user.company_position : "-"}</p>
                                                            <hr />
                                                        </Col>
                                                        <Col lg="6" className="mb-0">
                                                            <p className="ss_col_title">INDUSTRY</p>
                                                            <p className="ss_entry_content">{user.company_industry ? user.company_industry : "-"}</p>
                                                            <hr />
                                                        </Col>
                                                        <Col lg="6" className="mb-0">
                                                            <p className="ss_col_title">COMPANY EMAIL</p>
                                                            <p className="ss_entry_content">{user.company_email ? user.company_email : "-"}</p>
                                                            <hr />
                                                        </Col>
                                                        <Col lg="6" className="mb-0">
                                                            <p className="ss_col_title">COMPANY PHONE</p>
                                                            <p className="ss_entry_content">{user.company_phone ? user.company_phone : "-"}</p>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            {user_id != 1 ?
                                                <Card className="messages mb-3">
                                                    {/* <CardHeader>
                                                    <h3 className="header-text mb-0" style={{ color: "#1ec2f3" }}>CHAT WITH US</h3>
                                                </CardHeader> */}
                                                    {/* <CardBody className="pt-4 pb-0 comment-scroll"> */}
                                                    <h3 className="ss-subheader p-3 mb-0">Messages</h3>
                                                    <CardBody className={commentAutoScroll == true ? "comment-auto-scroll text-center" : "comment-scroll  text-center"}>
                                                        <div>
                                                            {!messagesLoading ?
                                                                <>
                                                                    {messages.length > 0 ?
                                                                        <>
                                                                            {messages.map(({ id, sender, date, user, content, admin_remarks }, index) => (
                                                                                <div className="entry-comment-container" key={index}>
                                                                                    <>
                                                                                        {index < 1 ?
                                                                                            <hr className='mt-0' />
                                                                                            :
                                                                                            <hr />
                                                                                        }
                                                                                        <p className="message-date text-center">{new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</p>

                                                                                        <Card className="comment-body" style={{ border: "none" }}>
                                                                                            <CardBody className="px-1">
                                                                                                {sender.user_id == currentUser ?
                                                                                                    <Row className="align-items-end" style={{ justifyContent: 'right' }}>
                                                                                                        <Col lg="9" className="mb-0 px-0">
                                                                                                            <div className='comment-send'>
                                                                                                                <Card>
                                                                                                                    <CardBody className="px-2 py-1">
                                                                                                                        <p className="comment-data">{content}</p>
                                                                                                                    </CardBody>
                                                                                                                </Card>
                                                                                                                {sender.sender_name}
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                        <Col lg="3" className="mb-0 px-0 user-image-container">
                                                                                                            <img src={PlaceholderIcon} className="user-icon-image" />
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                    :
                                                                                                    <Row className="align-items-end">
                                                                                                        <Col lg="3" className="mb-0 px-0 user-image-container">
                                                                                                            <img src={PlaceholderIcon} className="user-icon-image" />
                                                                                                        </Col>
                                                                                                        <Col lg="9" className="mb-0 px-0">
                                                                                                            <div className="comment-recieve">
                                                                                                                <Card>
                                                                                                                    <CardBody className="px-2 py-1">
                                                                                                                        <p className="comment-data">{content}</p>
                                                                                                                    </CardBody>
                                                                                                                </Card>
                                                                                                                {sender.sender_name}
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                }
                                                                                            </CardBody>
                                                                                        </Card>

                                                                                    </>
                                                                                </div>
                                                                            ))}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <p className="text-center mt-3">No messages found.</p>
                                                                        </>
                                                                    }
                                                                </>
                                                                :
                                                                <p className="mt-3"><PageSpinner /></p>
                                                            }
                                                        </div>
                                                    </CardBody>
                                                    <CardFooter className="px-2 py-2">
                                                        <Row className="align-items-center">
                                                            <Col lg="11" className="mb-0">
                                                                <Input type="textarea" rows="1" placeholder="Message" maxLength={255} className="comment-area" onChange={handleChangeMessage} value={messageData.content} name="content" />
                                                            </Col>
                                                            <Col lg="1" className="mb-0 text-right">
                                                                {formStatus != "standby" ?
                                                                    <button className="send-message-button" onClick={preventDefault}><Spinner color="info" size="sm" /></button>
                                                                    :
                                                                    <button className="send-message-button" onClick={submitMessage}><AiOutlineSend color="#932F8C" size="25px" /></button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </CardFooter>
                                                </Card>
                                                :

                                                null
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Update Profile */}
            <Modal
                isOpen={updateProfileShow}
                centered
                fade={false}
            // id="modal"
            >
                <button type="button" className="react-modal-close" data-dismiss="modal" aria-label="Close" onClick={toggleUpdateProfileShow}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <ModalHeader>
                    <strong>NEW TAG</strong>
                </ModalHeader>
                <ModalBody>

                </ModalBody>
                <ModalFooter className="text-end">
                    <button className='button view-button-close me-2' onClick={toggleUpdateProfileShow}>CANCEL</button>
                    {formStatus != "standby" ?
                        <button className='button view-button' onClick={preventDefault}>SENDING...</button>
                        :
                        <button className='button view-button' onClick={preventDefault}>SUBMIT</button>
                    }

                </ModalFooter>
            </Modal>
        </>
    )
}

export default AdminUserProfilePage;