
import React, { useState, useEffect } from 'react';
import {
    Container, CardFooter, Input, CardBody, Label, ModalHeader, ModalBody, ModalFooter, Card, Col, Modal, Table, Row, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { RiAdminFill } from 'react-icons/ri'
import marketingIcon from '../assets/dash-icon-blue.png';
import marketingBlackIcon from '../assets/dash-icon-black.png';
import marketinglistIcon from '../assets/listing-icon.png';
import marketingwatchIcon from '../assets/watchlist-icon.png';
import sellerSuccessLogo from '../assets/sellersuccess-logo-white.png';
import { useCookies } from 'react-cookie';
import { FiLogOut, FiMessageSquare } from 'react-icons/fi';
import { FaUsers, FaUser, FaEnvelope, FaTimes } from 'react-icons/fa';
import { MdSupportAgent } from 'react-icons/md';
// import { TbBottle } from 'react-icons/tb';
// import { BsFillPeopleFill, BsPlusCircle, BsArrowLeft, BsFillFlagFill, BsThreeDotsVertical, BsClipboardCheck, BsEnvelope } from 'react-icons/bs';
// import { BiPackage, BiPrinter, } from 'react-icons/bi';
// import { AiOutlineMail, AiFillBell, AiOutlineSend, AiOutlineUnorderedList } from "react-icons/ai";
// import { MdOutlinePayment, MdOutlineDashboard, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
// import { HiOutlineUserGroup, HiOutlineClipboardList } from 'react-icons/hi';
// import { GiBigGear } from 'react-icons/gi';

const Sidebar = (props) => {
    const navigate = useNavigate();
    const location = useLocation()
    var currentPathName = location.pathname;
    const [open, setOpen] = useState();
    const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn', 'userRole']);
    const currentUser = cookies.currentUser;
    const isLoggedIn = cookies.isLoggedIn;
    const userRole = cookies.userRole;
    const [logoutShow, setLogoutShow] = useState(false);

    const toggleLogout = () => {
        setLogoutShow(true);
    }

    // const handleClose = () => {
    //     setLogoutShow(false);
    // }

    const hideSidebar = () => {
        props.clickSidebar(true);            
    }

    // const Logout = () => {
    //     removeCookies('currentUser', { path: '/' });
    //     removeCookies('isLoggedIn', { path: '/' });
    //     removeCookies('userRole', { path: '/' });
    //     navigate("/login")
    // }

    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    useEffect(() => {
        // This is the axios GET
        if (currentPathName == '/marketplace') {
            if (open != "1") {
                setOpen("1");
            } else {
                setOpen();
            }
        }

    }, []);

    return (
        <>
            <div id="sidebar">
            <button onClick={hideSidebar}  className="close-btn"><FaTimes/></button>
                <div className="sidebar-head d-flex justify-content-center">
                    <div className='cursor-pointer' onClick={() => navigate('/')}>
                        {/* <h1 className="sidebar-title">ROCKET<br />FLIPPERS</h1> */}
                        <img src={sellerSuccessLogo} className="sellersuccess-logo" />
                    </div>
                </div>
                {currentPathName == '/login' || currentPathName == '/registration' ?
                    null
                :
                    <>
                        <div className="row">
                            {userRole != "Admin" ?
                                <div className="col">
                                    <div className="tabs">
                                        <div className="tab">
                                            {currentPathName !== '/login' ?
                                                <>
                                                    <input type="checkbox" id="chck1" />
                                                    <label className="tab-label" htmlFor="chck1"><img src={marketingBlackIcon} className="sidebar-icon" />Deal Flow</label>
                                                    <div className="tab-content">
                                                        <p onClick={() => navigate('/')} style={{ cursor: "pointer" }}><img src={marketinglistIcon} className="sidebar-body-icon" />All Listings</p>
                                                        {userRole != "Admin" ?
                                                            <p onClick={() => navigate('/watchlist')} style={{ cursor: "pointer" }}><img src={marketingwatchIcon} className="sidebar-body-icon" />Watchlist</p>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    <div className="tabs">
                                        {/* <div className="tab" onClick={() => navigate('/profile')}>
                                            <label className="tab-label no-caret" htmlFor="chck3"><FaUser size="26px" style={{marginLeft: '20px'}} /> MY PROFILE</label>
                                        </div> */}
                                        <div className="tab" onClick={() => navigate('/support')}>
                                            <label className="tab-label no-caret"><MdSupportAgent size="26px" style={{marginLeft: '20px'}} /> Support</label>
                                        </div>
                                    </div>
                                    {/* <button type='button' onClick={toggleLogout} className="logout-link"><FiLogOut className="sidebar-icon-logout" /><p className='mb-0'>LOGOUT</p></button> */}
                                </div>
                                :
                                <div className="col">
                                    <div className="tabs">
                                        <div className="tab">
                                            {currentPathName !== '/login' ?
                                                <>
                                                    <input type="checkbox" id="chck1" />
                                                    <label className="tab-label" htmlFor="chck1"><img src={marketingBlackIcon} className="sidebar-icon" />Deal Flow</label>
                                                    <div className="tab-content">
                                                        <p onClick={() => navigate('/')} style={{ cursor: "pointer" }}><img src={marketinglistIcon} className="sidebar-body-icon" />All Listings</p>
                                                        {userRole != "Admin" ?
                                                            <p onClick={() => navigate('/watchlist')} style={{ cursor: "pointer" }}><img src={marketingwatchIcon} className="sidebar-body-icon" />Watchlist</p>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    <div className="tabs">
                                        <div className="tab" onClick={() => navigate('/admin/users')}>
                                            <label className="tab-label no-caret" htmlFor="chck2"><FaUsers size="26px" style={{marginLeft: '20px'}} /> Users</label>
                                        </div>
                                    </div>
                                    <div className="tabs">
                                        <div className="tab" onClick={() => navigate('/admin/inquiries')}>
                                            <label className="tab-label no-caret" htmlFor="chck3"><FaEnvelope size="26px" style={{marginLeft: '20px'}} /> Requests</label>
                                        </div>
                                    </div>
                                    {/* <button type='button' onClick={toggleLogout} className="logout-link"><FiLogOut className="sidebar-icon-logout" /><p className='mb-0'>LOGOUT</p></button> */}
                                </div>
                            }
                            
                        </div>
                    </>
                }
                {/* <Accordion toggle={toggle} open={open}>
                <AccordionItem>
                    <AccordionHeader targetId="1"><img src={marketingIcon} className="sidebar-icon" />MARKETING </AccordionHeader>
                    
                    <AccordionBody accordionId="1" className="sidebar-body">
                        <p onClick={() => navigate('/')} style={{ cursor: "pointer" }}><img src={marketinglistIcon} className="sidebar-body-icon" />ALL LISTINGS</p>
                        <p style={{ cursor: "pointer" }} className={currentPathName == '/' ? 'low-opacity' : null}><img src={marketingwatchIcon} className="sidebar-body-icon" />WATCHLIST</p>
                    </AccordionBody>
                </AccordionItem>
            </Accordion> */}
                {/* <div className='admin' onClick={() => navigate('/marketing')}>
                <RiAdminFill className='admin-icon' size='26px' />ADMIN
            </div> */}
            </div>
            {/* <Modal
                isOpen={logoutShow}
                centered
                fade
                id="modal"
            >
                <ModalHeader>
                    LOGOUT
                </ModalHeader>
                <ModalBody>
                    <p className='mb-0'>Are you sure, you want to log out?</p>
                </ModalBody>
                <ModalFooter className='text-end'>
                    <button className='button-outline cancel-btn me-2' id="login-btn" onClick={handleClose}>CANCEL</button>
                    <button className='button view-button' id="login-btn" onClick={Logout}>LOGOUT</button>
                </ModalFooter>
            </Modal> */}
        </>
    )
}

export default Sidebar;