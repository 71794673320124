import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EmptyLayout from 'layout/EmptyLayout';
import {
    Container, Button, CardFooter, Input, CardBody, Label, ModalHeader, ModalBody, ModalFooter, Card, Col, Modal, Table, Row, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { BsSearch, BsThreeDotsVertical } from 'react-icons/bs';
import { FiFilter } from 'react-icons/fi';
import { BiSort } from 'react-icons/bi';
import { AiOutlinePlus } from 'react-icons/ai'
import { HiArrowLeft } from 'react-icons/hi'
import { GiHamburgerMenu } from 'react-icons/gi';
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'
import Sport from '../assets/Sports.png';
import home from '../assets/home-hobbies-img.png';
import excercise from '../assets/excercise.png';
import Sidebar from 'components/Sidebar';
import { Spinner } from 'reactstrap';
import axios from 'axios';
import PageSpinner from 'components/PageSpinner';
import { FaTimes } from 'react-icons/fa';

const initialFormData = Object.freeze({
    name: '',
});

const AdminTagsCreatePage = (props) => {
    const [formData, setFormData] = useState(initialFormData);
    const [tagsData, setTagsData] = useState([]);
    const [error, setError] = useState('');
    const [addTag, setAddTag] = useState(false);
    const [viewTagShow, setViewTagShow] = useState(false);
    const [deleteTagShow, setDeleteTagShow] = useState(false);
    const [formStatus, setFormStatus] = useState("standby");
    const [reloadCount, setReloadCount] = useState(0);
    const [tagId, setTagId] = useState(0);
    const [tableLoading, setTableLoading] = useState(true);
    const [isSidebar, setIsSidebar] = useState(false);
    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
      })

      function getSidebar() {
        setIsSidebar(!isSidebar);
      }

    const onChangeAddTag = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    }

    const onChangeUpdateTag = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    }

    const preventDefault = (e) => {
        e.preventDefault();
    }

    const toggleViewTag = (id) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + 'tag/' + id)
            .then((response) => {
                const data = response.data;
                const result = data.data;
                setFormData({
                    ...formData,
                    name: result.name,
                });
                // console.log(result);
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        setViewTagShow(true);
        setFormData({
            ...formData,
            name: '',
        });
    }

    const toggleDeleteTag = () => {
        setDeleteTagShow(true);
    }

    const toggleModal = () => {
        setAddTag(true);
    }

    const handleClose = () => {
        setAddTag(false);
        setViewTagShow(false);
        setDeleteTagShow(false);
    }

    // page content
    const navigate = useNavigate();

    const submitNewTag = (e) => {
        setFormStatus("loading");
        if (formData.name != "") {
            // const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'inquiry' + '?user_id=' + currentUser + '&token=' + token, {
            //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
            //     mode: 'cors', // no-cors, cors, *same-origin
            //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //     credentials: 'omit', // include, *same-origin, omit
            //     headers: {
            //         'Content-Type': 'application/json',
            //         "Access-Control-Allow-Origin": "*",
            //         "Access-Control-Allow-Credentials": true,
            //         'Content-Type': 'application/json',
            //         "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
            //         "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
            //     },
            //     redirect: 'follow', // manual, *follow, error
            //     referrer: 'client', // no-referrer, *client
            //     body: JSON.stringify({name: formData.name}), // body data type must match "Content-Type" header
            // })
            axios({
                url: process.env.REACT_APP_API_ENDPOINT + 'tag',
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    name: formData.name,
                }
            })
                .then(function (response) {
                    if (response.data.status == "Success") {
                        toast.success('Tag successfully created!', {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        window.location.reload(false);
                        setFormStatus("standby");
                        setReloadCount(reloadCount + 1);
                    // } else {
                    //     toast.error('An error occured. Please try again or contact the administrator.', {
                    //         position: 'top-right',
                    //         autoClose: 1500,
                    //         hideProgressBar: false,
                    //         closeOnClick: true,
                    //         pauseOnHover: true,
                    //         draggable: true,
                    //         progress: undefined,
                    //         theme: "light",
                    //     });
                    //     setFormStatus("standby");
                    }
                })
                .catch(function (error) {
                    // alert("Something went wrong!");
                    // console.log(error);
                    toast.error('An error occured. Please try again or contact the administrator.', {
                        position: 'top-right',
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setFormStatus("standby");
                });

        } else {
            // alert("Please fill up all fields");
            toast.warning('Please fill the required fields!', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setFormStatus("standby");
        }
    }

    const deleteTag = (e) => {
        setFormStatus("loading");
        if (formData.tag != "") {
            axios({
                url: process.env.REACT_APP_API_ENDPOINT + 'tag/' + tagId,
                method: 'delete',
                data: {
                    tag_id: tagId,
                }
            })
                .then(function (response) {
                    if (response.data.status == "Success") {
                        // alert("Tag submitted successfully!");
                        toast.success('Tag deleted successfully!', {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        window.location.reload(false);
                        setFormStatus("standby");
                        setReloadCount(reloadCount + 1);
                    } else {
                        // alert("Something went wrong!");
                        toast.error('Something went wrong!', {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setFormStatus("standby");
                    }

                })
                .catch(function (error) {
                    // alert("Something went wrong!");
                    toast.error('Tag delete failed!', {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    console.log(error);
                    setFormStatus("standby");
                });

        } else {
            // alert("Please fill up all fields");
            toast.warning('Please fill the required fields!', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setFormStatus("standby");
        }
    }

    const updateTag = (e) => {
        setFormStatus("loading");
        if (formData.tag != "") {
            axios({
                url: process.env.REACT_APP_API_ENDPOINT + 'tag/' + tagId,
                method: 'put',
                data: {
                    name: formData.name,
                }
            })
                .then(function (response) {
                    if (response.data.status == "Success") {
                        // alert("Tag submitted successfully!");
                        toast.success('Tag updated successfully!', {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        window.location.reload(false);
                        setFormStatus("standby");
                        setReloadCount(reloadCount + 1);
                        setDeleteTagShow(false);
                    } else {
                        // alert("Something went wrong!");
                        toast.error('Something went wrong!', {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setFormStatus("standby");
                    }

                })
                .catch(function (error) {
                    // alert("Something went wrong!");
                    toast.error('Something went wrong!', {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    console.log(error);
                    setFormStatus("standby");
                });

        } else {
            // alert("Please fill up all fields");
            toast.warning('Please fill the required fields!', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setFormStatus("standby");
        }
    }

    useEffect(() => {
        // This is the axios GET
        axios.get(process.env.REACT_APP_API_ENDPOINT + 'tag')
            .then((response) => {
                const data = response.data;
                const result = data.data;
                if (result) {
                    setTableLoading(false);
                    setTagsData(result);
                }
                
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTableLoading(false);
            });

    }, [reloadCount]);

    return (
        <section id='marketing'>
            <ToastContainer style={{ width: "370px" }} />
            <Container fluid>
                <Row>
                    <Col lg='2'>
                    {isSidebar || windowDimension.winWidth > 1024 ?
                        <Sidebar clickSidebar={getSidebar}/>
                        :
                        null
                    }
                    <button onClick={getSidebar} className={isSidebar ? "hidden" : "hamburger-btn show"}><GiHamburgerMenu /></button>
                        {/* <Sidebar /> */}
                    </Col>
                    <Col lg='10' className='right-container'>
                    <div className="mobile-display">
                        <div>
                            <h2 className='header'>TAGS</h2>
                            <div className="divider mb-0"></div>
                        </div>
                        <div>
                            <button className='button new-button me-2' onClick={toggleModal}><AiOutlinePlus className='me-1' /> NEW</button>
                            <button className='button back view-button' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button>
                        </div>
                    </div>
                        <div className="desktop-display">
                            <Row className='utility'>
                                <Col lg='8'>
                                    <h2 className='header'>TAGS</h2>
                                    <div className="divider"></div>
                                </Col>
                                <Col lg='4' className='text-end'>
                                    <button className='button new-button me-2' onClick={toggleModal}><AiOutlinePlus className='me-1' /> NEW</button>
                                    <button className='button back view-button' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button>
                                </Col>
                            </Row>
                        </div>
                        <Row className='mb-2'>
                            <Col lg='12'>
                                <Card>
                                    <CardBody className='p-0'>
                                        <Table className="">
                                        <thead className="table-head">
                                            <tr>
                                                {/* <th>Date</th> */}
                                                <th>NAME</th>
                                                <th className='text-right'>ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-row">
                                            {tableLoading ?
                                                <tr>
                                                    <td className="text-center" colSpan={4}>
                                                        <PageSpinner />
                                                    </td>
                                                </tr>
                                                :
                                                <>
                                                    {tagsData.length > 0 ?
                                                        <>
                                                            {tagsData.map(({ id, name, description, created_at }, index) => (
                                                                <tr key={index}>
                                                                    {/* <td>{new Date(created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })}</td> */}
                                                                    <td>{name}</td>
                                                                    <td className="tags-three-dots">
                                                                        <div className="action_table">
                                                                            <BsThreeDotsVertical />
                                                                            <Card className="action_content">
                                                                                <CardBody className="action_container">
                                                                                    <div className="link_container"
                                                                                        onClick={function (e) { toggleViewTag(id); setTagId(id); }}
                                                                                    >
                                                                                        <a href="javascript:void(0)">VIEW</a>
                                                                                    </div>
                                                                                    <div className="link_container"
                                                                                        onClick={function (e) { toggleDeleteTag(id); setTagId(id); }}
                                                                                    >
                                                                                        <a href="javascript:void(0)">DELETE</a>
                                                                                    </div>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                        :
                                                        <tr className="text-center my-5">
                                                            <td colSpan="2"><div className="text-center">No records found</div></td>
                                                        </tr>
                                                    }
                                                </>
                                            }
                                        </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            {/* Add Tag */}
            <Modal
                isOpen={addTag}
                centered
                fade={false}
                id="modal"
            >
                <button type="button" className="react-modal-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <ModalHeader>
                    <strong>NEW TAG</strong>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg="12">
                            <Label className="mb-0">Name</Label>
                            <Input type="text" name="name" onChange={onChangeAddTag} />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="text-end">
                    <button className='button view-button-close me-2' onClick={handleClose}>CANCEL</button>
                    {formStatus != "standby" ?
                        <button className='button view-button' onClick={preventDefault}>SENDING...</button>
                        :
                        <button className='button view-button' onClick={submitNewTag}>SUBMIT</button>
                    }

                </ModalFooter>
            </Modal>
            {/* View Tag */}
            <Modal
                isOpen={viewTagShow}
                centered
                fade={false}
                id="modal"
            >
                <button type="button" className="react-modal-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <ModalHeader>
                    <strong>TAG</strong>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg="12">
                            <Label className="mb-0">Name</Label>
                            <Input type="text" name="name" value={formData.name} onChange={onChangeUpdateTag} />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="text-end">
                    <button className='button view-button-close me-2' onClick={handleClose}>CANCEL</button>
                    {formStatus != "standby" ?
                        <button className='button view-button' onClick={preventDefault}>UPDATING...</button>
                        :
                        <button className='button view-button' onClick={updateTag}>UPDATE</button>
                    }
                </ModalFooter>
            </Modal>
            {/* Delete Tag */}
            <Modal
                isOpen={deleteTagShow}
                centered
                fade={false}
                id="modal"
            >
                <button type="button" className="react-modal-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <ModalHeader>
                    <strong>DELETE TAG</strong>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg="12">
                            Are you sure, you want to delete?
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="text-end">
                    <button className='button view-button-close me-2' onClick={handleClose}>CANCEL</button>
                    {formStatus != "standby" ?
                        <button className='button view-button' onClick={preventDefault}>DELETING...</button>
                        :
                        <button className='button view-button-delete' onClick={deleteTag}>DELETE</button>
                    }
                </ModalFooter>
            </Modal>
        </section>
    )
}

export default AdminTagsCreatePage;