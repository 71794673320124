import React from 'react';
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
// } from 'chart.js';
import { Bar } from 'react-chartjs-2';
// // import faker from 'faker';

// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend
// );

const Chartjs = ({ChartData}) => {
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const options = {
        plugins: {
            title: {
                display: false,
                text: 'Chart.js Bar Chart - Stacked',
            },
            legend: {
                display: false
            }

        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };
    // const data = {
    //     labels,
    //     datasets: [
    //         {
    //             label: 'Gross Revenue',
    //             barThickness: 20,

    //             data: [-10, 20, 30, 40, 50, 60, 70],
    //             backgroundColor: '#932F8C',
    //         },
    //         {
    //             label: 'Net Profit',
    //             barThickness: 20,
    //             data: [-20, 20, 30, 40, 50, 60, 70],
    //             backgroundColor: '#E3072D',
    //         },
    //     ],
    // };

    return (
        <Bar data={ChartData} options={options} />
    )
}

export default Chartjs;
// export function Chartjs() {
//     return <Bar options={options} data={data} />;
// }
