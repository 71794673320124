import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory, useNavigate } from 'react-router-dom'
import EmptyLayout from 'layout/EmptyLayout';
import {
    Container, Button, CardFooter, Input, CardBody, Label, ModalHeader, ModalBody, ModalFooter, Card, Col, Modal, Table, Row, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,

} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsSearch, BsTrash } from 'react-icons/bs';
import { FiFilter } from 'react-icons/fi';
import { BiSort } from 'react-icons/bi';
import { AiOutlinePlus } from 'react-icons/ai'
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'
import Sport from '../assets/Sports.png';
import home from '../assets/home-hobbies-img.png';
import excercise from '../assets/excercise.png';
import Sidebar from 'components/Sidebar';
import { Spinner } from 'reactstrap';
import PageSpinner from 'components/PageSpinner';
import axios from 'axios';
import NoImageAvail from '../assets/no-image.png';
import ProfileImage from "../assets/profile_placeholder.png";
import { BsThreeDotsVertical, BsFillPencilFill } from 'react-icons/bs';
import { GoDeviceDesktop } from 'react-icons/go';
import { GrEdit } from 'react-icons/gr';
import { FaArrowLeft } from 'react-icons/fa';
import { useCookies } from 'react-cookie';
import { HiArrowLeft } from 'react-icons/hi';
import { GiHamburgerMenu } from 'react-icons/gi';

const initialFormData = Object.freeze({
    first_name: '',
    last_name: '',
    company_name: '',
    company_position: '',
    contact_phone: '',
    company_email: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    province: '',
    company_industry: '',
    company_phone: '',
    email: '',
    image_url: ''
  });


function ProfileEditPage(props) {
    // page content
    const navigate = useNavigate();
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const user_id = query.get('user_id');
    const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn', 'userRole', 'awardStatus', 'token']);
    const currentUser = cookies.currentUser;
    const [user, setUser] = useState([]);
    const [userLoading, setUserLoading] = useState(true);
    const [imgFile, setImgFile] = useState("");
    const [formStatus, setFormStatus] = useState("standby");
    const [activeTab, setActivetab] = useState(1);
    const [isSidebar, setIsSidebar] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const [reloadCount, setReloadCount] = useState(0);
    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
    })
  
    function getSidebar() {
        setIsSidebar(!isSidebar);
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    }
    
    const preventDefault = (e) => {
        e.preventDefault();
    }

    const submitUpdateProfile = (e) => {
        setFormStatus("loading");
        if (formData.first_name!= "") {
            axios({
                url: process.env.REACT_APP_API_ENDPOINT + 'profile/'+ user_id+'/update',
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                data:formData
            })
                .then(function (response) {
                    if (response.data.status == "Success") {
                        toast.success('Profile updated successfully!', {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setFormStatus("standby");
                        setReloadCount(reloadCount + 1);
                    } else {
                        toast.error('Profile update failed!', {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setFormStatus("standby");
                    }
                })
                .catch(function (error) {
                    toast.error('An error occured. Please try again or contact the administrator.', {
                        position: 'top-right',
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setFormStatus("standby");
                });
        } else {
            toast.warning('Please fill the required fields!', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setFormStatus("standby");
        }
    }

    useEffect(() => {
        // This is the axios GET
        axios.get(process.env.REACT_APP_API_ENDPOINT + 'user/' + user_id)
            .then((response) => {
                const data = response.data;
                const result = data.data;
                setFormData({
                    ...formData,
                    first_name: result.first_name,
                    last_name: result.last_name,
                    contact_phone: result.contact_phone,
                    email: result.email,
                    address_line_1: result.address_line_1,
                    address_line_2: result.address_line_2,
                    city: result.city,
                    province: result.province,
                    company_name: result.company_name,
                    company_position: result.company_position,
                    company_industry: result.company_industry,
                    company_email: result.company_email,
                    company_phone: result.company_phone,
                })
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    }, [currentUser]);


    return (
        <>
        <section id='user-profile'>
        <ToastContainer style={{ width: "370px" }} />
            <Container fluid>
                <Row>
                    <Col lg='2'>
                        {/* <Sidebar /> */}
                        {isSidebar || windowDimension.winWidth > 1024 ?
                            <Sidebar clickSidebar={getSidebar}/>
                            :
                            null
                        }
                        <button onClick={getSidebar} className={isSidebar ? "hidden" : "hamburger-btn show"}><GiHamburgerMenu /></button>
                    </Col>
                    <Col lg='10' className='right-container'>
                        <div className="page-container">
                            <div>
                                <div className="mobile-display">
                                    <h2 className="ss-header mb-0">Edit Profile</h2>
                                    <div className="divider"></div>
                                </div>
                                <Row>
                                    <Col lg={3}>
                                        <Card className='mb-3'>
                                            <CardBody>
                                                {imgFile ?
                                                    // <div className='profile-picture-container' style={{ backgroundImage: "url(" + process.env.REACT_APP_URL + "/storage/gallery/"+imgFile+ ")" }}></div>
                                                    <div className='profile-picture-container' style={{ backgroundImage: "url(https://bis.jenocabrera.online/storage/gallery/"+imgFile+ ")" }}></div>
                                                    :
                                                    <div className='profile-picture-container' style={{ backgroundImage: "url(" + ProfileImage + ")" }}></div>
                                                }

                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={9}>
                                        <div className="desktop-display">
                                            <Row>
                                                <Col lg="9" className="mb-0">
                                                    <h2 className="ss-header mb-0">Edit Profile</h2>
                                                    <div className="divider"></div>
                                                </Col>
                                                <Col lg="3" className="mb-0 text-right">
                                                    <button className='button back view-button profile-button-mobile' onClick={() => navigate(-1)}><FaArrowLeft className='btn-icon me-3' />BACK</button>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Card className="preview_content">
                                            <CardBody>
                                                <h3 className="ss-subheader">PERSONAL DETAILS</h3>
                                                <hr/>
                                                <Row>
                                                <Col lg="6" className="mb-0">
                                                    <p className="ss_col_title">FIRST NAME</p>
                                                    <Input type="text" className="mb-3" value={formData.first_name} name="first_name" onChange={handleChange}/>
                                                </Col>
                                                <Col lg="6" className="mb-0">
                                                    <p className="ss_col_title">LAST NAME</p>
                                                    <Input type="text" className="mb-3" value={formData.last_name} name="last_name" onChange={handleChange}/>
                                                </Col>
                                                <Col lg="6" className="mb-0">
                                                    <p className="ss_col_title">PHONE NUMBER:</p>
                                                    <Input type="text" className="mb-3" value={formData.contact_phone} name="contact_phone" onChange={handleChange}/>
                                                </Col>
                                                <Col lg="6" className="mb-0">
                                                    <p className="ss_col_title">EMAIL ADDRESS:</p>
                                                    <Input type="text" className="mb-3" value={formData.email} name="email" onChange={handleChange}/>
                                                </Col>
                                                <Col lg="6" className="mb-0">
                                                    <p className="ss_col_title">ADDRESS LINE 1</p>
                                                    <Input type="text" className="mb-3" value={formData.address_line_1} name="address_line_1" onChange={handleChange}/>
                                                </Col>
                                                <Col lg="6" className="mb-0">
                                                    <p className="ss_col_title">ADDRESS LINE 2</p>
                                                    <Input type="text" className="mb-3" value={formData.address_line_2} name="address_line_2" onChange={handleChange}/>
                                                </Col>
                                                <Col lg="6" className="mb-0">
                                                    <p className="ss_col_title">CITY</p>
                                                    <Input type="text" className="mb-3" value={formData.city} name="city" onChange={handleChange}/>
                                                </Col>
                                                <Col lg="6" className="mb-0">
                                                    <p className="ss_col_title">PROVINCE</p>
                                                    <Input type="text" className="mb-3" value={formData.province} name="province" onChange={handleChange}/>
                                                </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Card className="preview_content mt-3">
                                            <CardBody>
                                                <h3 className="ss-subheader">COMPANY DETAILS</h3>
                                                <hr/>
                                                <Row>
                                                    <Col lg="6" className="mb-0">
                                                        <p className="ss_col_title">COMPANY</p>
                                                        <Input type="text" className="mb-3" value={formData.company_name} name="company_name" onChange={handleChange}/>
                                                    </Col>
                                                    <Col lg="6" className="mb-0">
                                                        <p className="ss_col_title">POSITION</p>
                                                        <Input type="text" className="mb-3" value={formData.company_position} name="company_position" onChange={handleChange}/>
                                                    </Col>
                                                    <Col lg="6" className="mb-0">
                                                        <p className="ss_col_title">INDUSTRY</p>
                                                        <Input type="text" className="mb-3" value={formData.company_industry} name="company_industry" onChange={handleChange}/>
                                                    </Col>
                                                    <Col lg="6" className="mb-0">
                                                        <p className="ss_col_title">COMPANY EMAIL</p>
                                                        <Input type="text" className="mb-3" value={formData.company_email} name="company_email" onChange={handleChange}/>
                                                    </Col>
                                                    <Col lg="6" className="mb-0">
                                                        <p className="ss_col_title">COMPANY PHONE</p>
                                                        <Input type="text" className="mb-3" value={formData.company_phone} name="company_phone" onChange={handleChange}/>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Row>
                                            <Col lg="12" className='text-end'>
                                                {formStatus != "standby" ?
                                                    <button className='button back view-button profile-button-mobile mt-3' onClick={preventDefault}>UPDATING...</button>
                                                :
                                                    <button className='button back view-button profile-button-mobile mt-3' onClick={submitUpdateProfile}>UPDATE</button>  
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        </>
    )
}

export default ProfileEditPage;