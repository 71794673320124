import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import EmptyLayout from 'layout/EmptyLayout';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Input, Label, Form } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Sidebar from 'components/Sidebar';
import SellerSuccessLogo from '../assets/seller-success-logo.png';

const initialData = Object.freeze({
  email: '',
  password: ''
});

const LoginPage = (props) => {
  // page content
  const [login, setLogin] = useState(initialData);
  const [formStatus, setFormStatus] = useState("standby");
  const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn', 'userRole', 'ltmGMV', 'ebitda','ebitdaMargin', 'yoyGrowth']);
  const currentUser = cookies.currentUser;
  const isLoggedIn = cookies.isLoggedIn;
  const userRole = cookies.userRole;
  const ltmGMV = cookies.ltmGMV;
  const ebitda = cookies.ebitda;
  const ebitdaMargin = cookies.ebitdaMargin;
  const yoyGrowth = cookies.yoyGrowth;
  const location = useLocation()
  var currentPathName = location.pathname;
  let navigate = useNavigate();

  const handleLogin = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value
    })
  }

  // if (currentUser) {
  //   window.location.href = "/"
  // } else {
  //   window.location.href = "/register"
  // }

  const preventDefaultSend = (e) => {
    e.preventDefault();
  }

  const submitLogin = (e) => {
    e.preventDefault();
    setFormStatus("loading");
    // if (userRole != "Admin") {
    if (login.email != "" && login.password != "") {
      axios({
        url: process.env.REACT_APP_API_ENDPOINT + 'login',
        method: 'post',
        // headers: {
        //     'Content-Type': 'application/json',
        //     "Access-Control-Allow-Origin": "*",
        //     "Access-Control-Allow-Credentials": true,
        //     "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        //     "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
        // },
        data: login
      })
        .then(function (response) {
          // console.log(response);
          var loginResponse = response.data;
          if (loginResponse.status == "Success") {
            if (response.data.data.user.role !== "Admin") {
              if (response.data.status == "Success") {
                toast.success('Welcome' + ' ' + response.data.data.user.first_name + '!', {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  onClose: () => navigate("/")
                });
                setFormStatus("standby");
                // console.log("login", response)
                // alert("test")
                setCookies('currentUser', JSON.stringify(response.data.data.id), { path: '/' });
                setCookies('userRole', response.data.data.user.role, { path: '/' });
                setCookies('isLoggedIn', true, { path: '/' });
              } else {
                toast.error('Wrong Username/Password!', {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setFormStatus("standby");
              }
            } else {
              toast.warning('You are an Admin!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => navigate("/admin/login")
              });
              setFormStatus("standby");
            }
          } else {
            toast.error(loginResponse.errors[0], {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setFormStatus("standby");
          }
          // console.log(response);
        })
        .catch(function (error) {
          toast.error('Something went wrong with the server, please try again later!', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setFormStatus("standby");
        });
    } else {
      // e.preventDefault();
      toast.warning('Please fill the required fields!', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFormStatus("standby");
    }
  }

  useEffect(() => {

    if (isLoggedIn) {
      navigate("/");
    } else {
      navigate("/login");
    }

  }, []);

  return (
    <section id='login'>
      <EmptyLayout>
        <ToastContainer style={{ width: "370px" }} />
        <Container className="login-page-container">
          <Row>
            <Col lg="6">
              <div className="logo-container-login">
                <img src={SellerSuccessLogo} className="login-ss-logo" />
              </div>
            </Col>
            <Col lg="6">
              <div className='login-div'>
                <img src={SellerSuccessLogo} className="login-ss-logo-mobile" />
                <Card className='login-card'>
                  <CardBody>
                    <div className='login-inner'>
                      <h2>Member Login</h2>
                      <Form>
                        <Label>Email</Label>
                        <Input className="mb-3 input-login" name="email" type="email" maxLength={255} onChange={handleLogin} />
                        <Label>Password</Label>
                        <Input name="password" type="password" className="input-login" maxLength={255} onChange={handleLogin} />
                        <Row>
                          <Col lg="12" className='text-center'>
                            {formStatus != "standby" ?
                              <button type='submit' className='button view-button-login me-2' id="login-btn" onClick={preventDefaultSend}>LOGGING IN...</button>
                              :
                              <button type='submit' className='button view-button-login me-2' id="login-btn" onClick={submitLogin}>LOGIN</button>
                            }
                            <p className='mb-0'>Not a member? <a href="/registration">Register</a></p>
                            <a href="https://ssp.jenocabrera.online/password/reset">Forgot Password?</a>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </EmptyLayout>
    </section>
  )
}

export default LoginPage