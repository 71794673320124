import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EmptyLayout from 'layout/EmptyLayout';
import {
    Container, Button, CardFooter, Input, CardBody, Label, ModalHeader, ModalBody, ModalFooter, Card, Col, Modal, Table, Row, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,

} from 'reactstrap';
import Sidebar from 'components/Sidebar';
import { Spinner } from 'reactstrap';
import PageSpinner from 'components/PageSpinner';
import axios from 'axios';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { HiArrowLeft } from 'react-icons/hi';
import { useCookies } from 'react-cookie';

const initialFormData = Object.freeze({
    'ltm_gmv_from': '',
    'ltm_gmv_to': '',
    'ebitda_from': '',
    'ebitda_to': '',
    'ebitda_margin': '',
    'year_on_year_growth': '',
  });

function UserNotificationsPage(props) {
    // page content
    const navigate = useNavigate();
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const user_id = query.get('id');
    const notifications_id = query.get('notifications_id');
    const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn', 'userRole', 'token']);
    const [notifications, setNotifications] = useState();
    const [reloadNotifications, setReloadNotifications] = useState(1);
    const [notificationsLoading, setNotificationsLoading] = useState(true);

    const currentUser = cookies.currentUser;
    const token = cookies.token;

    const [user, setUser] = useState([]);
    const [userLoading, setUserLoading] = useState(true);
    const [imgFile, setImgFile] = useState("");
    const [tagsListShow, setTagsListShow] = useState(false);
    const [tagsBoxStyle, setTagsBoxStyle] = useState('card-show-tags-hidden');
    const [tagsData, setTagsData] = useState([]);
    const [tagId, setTagId] = useState([]);
    const [error, setError] = useState('');
    // const [profileData, setProfileData] = useState(initialUserData);
    const [formData, setFormData] = useState(initialFormData);
    const [formStatus, setFormStatus] = useState("standby");const [isSidebar, setIsSidebar] = useState(false);
    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
      })

      const [tags, setTags] = useState([])

      function handleKeyDown(e){
        if(e.key !== 'Enter') return
        const value = e.target.value
        if(!value.trim()) return
        setTags([...tags, value])
        e.target.value = ''
    }

    const retrieveNotifications = async () => {
        const response = fetch(process.env.REACT_APP_API_ENDPOINT+'notification?user_id='+currentUser, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'omit', // include, *same-origin, omit
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              'Content-Type': 'application/json',
              "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
              "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'client', // no-referrer, *client
          })
            .then((response) => {
              return response.json();
            })
            .then((events) => {
              return events.data;
            });
            // console.log('response:' ,response);
          return response;
    }

    async function markAsReadSubmit(e) {
        if (currentUser != "") {
          const response = await fetch(process.env.REACT_APP_API_ENDPOINT+'notification/'+e+'/read', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'omit', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              'Content-Type': 'application/json',
              "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
              "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'client', // no-referrer, *client
            body: JSON.stringify({user_id: currentUser}), // body data type must match "Content-Type" header
          })
    
          const json = await response.json();
    
          if (json.status == "Success") {
            //alert("Award updated successfully");
            setReloadNotifications(reloadNotifications + 1);
    
          } else {
            
          }
        } else {
          
        }
    }

    useEffect(() => {
        const getNotifications = async () => {
            const selectedNotifications = await retrieveNotifications();
            if (selectedNotifications) {
                setNotifications(selectedNotifications);
                console.log('selected notifications',selectedNotifications)
                setNotificationsLoading(false);
            }
        };

        getNotifications();

    }, [reloadNotifications]);

    const handleChange = (e) => {
        setFormData({
            ...FormData,
            [e.target.name]: e.target.value,
        });
        // setImageUpload(e.target.files[0]);
    };

    const submitItem = () => {
        setFormStatus('loading')
        if (tagId != '') {
            axios({
                method: 'put',
                url: process.env.REACT_APP_API_ENDPOINT + '',
                data: ({
                    tag_ids: tagId,
                    ltm_gmv_from: formData.ltm_gmv_from,
                    ltm_gmv_to: formData.ltm_gmv_to,
                    ebitda_from: formData.ebitda_from,
                    ebitda_to: formData.ebitda_to,
                    ebitda_margin: formData.ebitda_margin,
                    year_on_year_growth: formData.year_on_year_growth,
                })
            }).then(function (response) {
                if (response.data.status == 'Success') {
                    toast.success('Successfully updated!', {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setFormStatus('standby');
                    navigate('/');
                } else {
                    toast.error('Update failed!', {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setFormStatus('standby')
                }
            }).catch(function (error) {
                setError(error)
            })
        } else {
            toast.warning('Please fill in the required fields!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setFormStatus('standby')
        }

    };

    function removeTag(index){
        setTags(tags.filter((el, i) => i !== index))
    }
    
      function getSidebar() {
        setIsSidebar(!isSidebar);
      }

    // Update Profile
    const [updateProfileShow, setUpdateProfileShow] = useState(false);
    

    const toggleUpdateProfileShow = () => {
        setUpdateProfileShow(!updateProfileShow);
    }

    const preventDefault = (e) => {
        e.preventDefault();
    }

    const toggleShowTags = () => {
        setTagsListShow(true);
        setTagsBoxStyle('card-show-tags-show');
    }

    const toggleHideTags = () => {
        setTagsListShow(false);
        setTagsBoxStyle('card-show-tags-hidden');
    }

    const handleChangeCheck = (id) => {
        setTagId(tagId => [...tagId, id]);
        // const tag_id = [...tagId, id];
        // console.log("tag Id's", tag_id);
        // console.log('tagarray id', tagId)
    }

    const handleChangeUnchecked = (id) => {
        let filterId = tagId.filter(item => item !== id);
        // console.log('removeID', filteredArray)
        setTagId(filterId)
    }
    

    useEffect(() => {
        // This is the axios GET

        axios.get(process.env.REACT_APP_API_ENDPOINT + 'user/'+currentUser)
        // axios.get('https://bis.jenocabrera.online/api/user/'+user_id)
            .then((response) => {
                const data = response.data;
                const result = data.data;
                // console.log("Results Data:", result);
                if (result) {
                    setUserLoading(false);
                    setUser(result);
                    setImgFile(result.image_url);
                }
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setUserLoading(false);
            });
    }, [currentUser]);

    useEffect(() => {
        // This is the axios GET
        axios.get(process.env.REACT_APP_API_ENDPOINT + 'tag')
            .then((response) => {
                const data = response.data;
                const result = data.data;
                setTagsData(result);
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

    }, []);

    return (
        <>
        <ToastContainer style={{ width: "370px" }} />
        <section id='notifications'>
            <Container fluid>
                <Row>
                    <Col lg='2'>
                    {isSidebar || windowDimension.winWidth > 1024 ?
                    <Sidebar clickSidebar={getSidebar}/>
                    :
                    null
                    }
                    <button onClick={getSidebar} className={isSidebar ? "hidden" : "hamburger-btn show"}><GiHamburgerMenu /></button>
                        {/* <Sidebar /> */}
                    </Col>
                    <Col lg='10' className='right-container'>
                        <div className="page-container">
                            <div>
                                <Row>
                                    <Col lg={12}>
                                    <div className="mobile-display">
                                        <div>
                                            <h2 className="ss-header mb-0">Notifications</h2>
                                            <div className="divider"></div>
                                        </div>
                                        {/* <button className='button back view-button mb-3' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button> */}
                                    </div>
                                    <div className="desktop-display">
                                        <Row>
                                            <Col lg={9} className="mb-0">
                                                <h2 className="ss-header mb-0">Notifications</h2>
                                                <div className="divider"></div>
                                            </Col>
                                            {notificationsLoading ?
                                                <Card>
                                                    <CardBody>
                                                        <div className="py-2 text-center">
                                                            <PageSpinner />
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                                :
                                                <>
                                                {notifications.length > 0 ?
                                                <>
                                                {notifications.map(({id,message, read_at, subject, url,created_at})=> (
                                                    <>
                                                    {read_at ?
                                                    <a href={url}  className="text-decoration-none">
                                                        <Card className="notification-list p-3 mb-2">
                                                            <Row>
                                                                <Col lg="12" className="pl-0">
                                                                <p><span className='font-weight-bold'>{subject ? subject : "-"}</span></p>
                                                                <p className='text-muted'>{message ? message : "-"}</p>
                                                                <small>{new Date(created_at).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric', hour: '2-digit', minute:'2-digit' })}</small><br />
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </a>
                                                    :
                                                    <Card className="notification-list-unread p-3 mb-2" key={id}>
                                                        <Row>
                                                            <Col lg="12" className="mb-0">
                                                                <Row>
                                                                    <Col lg="9" className="pl-0">
                                                                        <a href={url} className="text-decoration-none">
                                                                            <p><span className='font-weight-bold'>{subject ? subject : "-"}</span></p>
                                                                            <p className='text-muted'>{message ? message : "-"}</p>
                                                                            <small>{new Date(created_at).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric', hour: '2-digit', minute:'2-digit' })}</small><br />
                                                                        </a>

                                                                    </Col>
                                                                    <Col lg="3" className='read-button'>
                                                                        <button className="btn newbtntheme btn-sm mt-2" onClick={function (event) { markAsReadSubmit(id) }}>
                                                                            Mark as Read
                                                                        </button>
                                                                    </Col>

                                                                
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                    }
                                                    </>
                                                ))}

                                                </>
                                                :
                                                <Card className="notification-list p-3 mb-2 text-center">
                                                <Row >
                                                    <Col lg="12" className="mb-0">
                                                        <p>No notifications found</p>
                                                    </Col>
                                                </Row>
                                                </Card>
                                                
                                                }
                                                </>
                                            }
                                        </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Update Profile */}
        <Modal
            isOpen={updateProfileShow}
            centered
            fade={false}
            // id="modal"
        >
            <button type="button" className="react-modal-close" data-dismiss="modal" aria-label="Close" onClick={toggleUpdateProfileShow}>
                <span aria-hidden="true">&times;</span>
            </button>
            <ModalHeader>
                <strong>NEW TAG</strong>
            </ModalHeader>
            <ModalBody>
                
            </ModalBody>
            <ModalFooter className="text-end">
                <button className='button view-button-close me-2' onClick={toggleUpdateProfileShow}>CANCEL</button>
                {formStatus != "standby" ?
                    <button className='button view-button' onClick={preventDefault}>SENDING...</button>
                    :
                    <button className='button view-button' onClick={preventDefault}>SUBMIT</button>
                }
            </ModalFooter>
        </Modal>
        </>
    )
}

export default UserNotificationsPage;