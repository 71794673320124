import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import EmptyLayout from 'layout/EmptyLayout';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Input, Label, Form } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Sidebar from 'components/Sidebar';
import SellerSuccessLogo from '../assets/seller-success-logo.png';

const initialData = Object.freeze({
  email: '',
  password: ''
});

const AdminLoginPage = (props) => {
  // page content
  const [login, setLogin] = useState(initialData);
  const [formStatus, setFormStatus] = useState("standby");
  const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn', 'userRole']);
  const currentUser = cookies.currentUser;
  const isLoggedIn = cookies.isLoggedIn;
  const userRole = cookies.userRole;
  const location = useLocation()
  var currentPathName = location.pathname;
  let navigate = useNavigate();

  const handleLogin = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value
    });
  }

  const preventDefaultSend = (e) => {
    e.preventDefault();
  }

  // if (currentUser) {
  //   window.location.href = "/"
  // } else {
  //   window.location.href = "/register"
  // }

  const submitLogin = (e) => {
    e.preventDefault();
    setFormStatus('loading');
    if (login.email != "" && login.password != "") {
      axios({
        url: process.env.REACT_APP_API_ENDPOINT + 'login',
        method: 'post',
        // headers: {
        //     'Content-Type': 'application/json',
        //     "Access-Control-Allow-Origin": "*",
        //     "Access-Control-Allow-Credentials": true,
        //     "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        //     "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
        // },
        data: login
      })
        .then(function (response) {
          var loginResponse = response.data;
          if (loginResponse.status == "Success") {
            if (response.data.data.user.role == "Admin") {
                toast.success('Welcome' + ' ' + response.data.data.user.first_name + '!', {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  onClose: () => navigate("/")
                });
                // console.log("login", response)
                // alert("test");
                setFormStatus('standby');
                setCookies('currentUser', JSON.stringify(response.data.data.id), { path: '/' });
                setCookies('userRole', response.data.data.user.role, { path: '/' });
                setCookies('isLoggedIn', true, { path: '/' });
            } else {
              toast.warning('You are not an Admin!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => navigate("/login")
              });
              setFormStatus('standby');
              
            }
          } else {
            toast.error(loginResponse.errors[0], {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setFormStatus('standby');
          }
        })
        .catch(function (error) {
          toast.error('User does not exists!', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setFormStatus('standby');
        });
    } else {
      toast.warning('Please fill the required fields!', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFormStatus('standby');
    }
  }

  useEffect(() => {

    if (isLoggedIn) {
      navigate("/");
    } else {
      //   navigate("/login");
      navigate("/admin/login");
    }

  }, []);

  // useEffect(() => {
  //   const listener = event => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       submitLogin();
  //       // callMyFunction();
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, []);

  return (
    <section id='login'>
      <EmptyLayout>
        <ToastContainer style={{ width: "370px" }} />
        <Container className="login-page-container">
          <Row>
            <Col lg="6">
              <div className="logo-container-login">
                <img src={SellerSuccessLogo} className="login-ss-logo" />
              </div>
            </Col>
            <Col lg="6">
              <div className='login-div'>
                <img src={SellerSuccessLogo} className="login-ss-logo-mobile" />
                <Card className='login-card'>
                  <CardBody>
                    <div className='login-inner'>
                      <h2>Admin Login</h2>
                      <Form>
                        <Label>Email</Label>
                        <Input className="mb-3 input-login" name="email" type="email" maxLength={255} onChange={handleLogin} />
                        <Label>Password</Label>
                        <Input name="password" type="password" className="input-login" maxLength={255} onChange={handleLogin} />
                        <Row>
                          <Col lg="12" className='text-center'>
                            {formStatus != "standby" ?
                              <button type='submit' className='button view-button-login me-2' id="login-btn" onClick={preventDefaultSend}>LOGGING IN</button>
                              :
                              <button type='submit' className='button view-button-login me-2' id="login-btn" onClick={submitLogin}>LOGIN</button>
                            }
                            {/* <p>Not a member? <a href="/registration">Register</a></p> */}
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </EmptyLayout>
    </section>
  )
}

export default AdminLoginPage