import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import EmptyLayout from 'layout/EmptyLayout';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Input, Label, Form } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Sidebar from 'components/Sidebar';
import SellerSuccessLogo from '../assets/seller-success-logo.png';
import validator from "validator";

const initialData = Object.freeze({
  email: '',
});

const ForgotPasswordPage = (props) => {
  // page content
  const [forgotFormData, setForgotFormData] = useState(initialData);
  const [formStatus, setFormStatus] = useState("standby");
  const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn', 'userRole']);
  const currentUser = cookies.currentUser;
  const isLoggedIn = cookies.isLoggedIn;
  const userRole = cookies.userRole;
  const location = useLocation()
  var currentPathName = location.pathname;
  let navigate = useNavigate();

  const handleForgotPassword = (e) => {
    setForgotFormData({
      ...forgotFormData,
      [e.target.name]: e.target.value
    });
  }

  const preventDefaultSend = (e) => {
    e.preventDefault();
  }

  const submitForgotPassword = (e) => {
    e.preventDefault();
    setFormStatus('loading');
    if (validator.isEmail(forgotFormData.email)) {
        if (forgotFormData.email !== "") {
            axios({
                url: process.env.REACT_APP_API_ENDPOINT + 'forgot/password',
                method: 'post',
                // headers: {
                //     'Content-Type': 'application/json',
                //     "Access-Control-Allow-Origin": "*",
                //     "Access-Control-Allow-Credentials": true,
                //     "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                //     "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
                // },
                data: forgotFormData
              })
                .then(function (response) {
                  var loginResponse = response.data;
                  if (loginResponse.status == "Success") {
                    toast.success("The instructions to reset your password has been sent to your email", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                      }
                    );
                    setFormStatus("standby");
                  } else {
                    toast.error("Something went wrong!", {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined
                    });
                    setFormStatus("standby");
                  }
                })
                .catch(function (error) {
                  toast.error('Something went wrong!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  setFormStatus('standby');
                });
        } else {
            toast.warning('Please fill up all the required field!', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
            setFormStatus('standby');
        }
    } else {
        toast.warning('Please enter a valid email address!', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        setFormStatus('standby');
    }
  }

  useEffect(() => {

    if (isLoggedIn) {
      navigate("/");
    } else {
      navigate("/forgotpassword");
    } 

  }, []);

  return (
    <section id='login'>
      <EmptyLayout>
        <ToastContainer style={{ width: "370px" }} />
        <Container className="login-page-container">
          <Row>
            <Col lg="6">
              <div className="logo-container-login">
                <img src={SellerSuccessLogo} className="login-ss-logo" />
              </div>
            </Col>
            <Col lg="6">
              <div className='login-div'>
                <img src={SellerSuccessLogo} className="login-ss-logo-mobile" />
                <Card className='login-card'>
                  <CardBody>
                    <div className='login-inner'>
                      <h2 className="mb-2">Forgot Your Password?</h2>
                      <p className="mb-5">Please enter the email you use to sign in.</p>
                      <Form>
                        {/* <Label>Email</Label> */}
                        <Input className="mb-3 input-login" name="email" type="email" placeholder='Email Address' onChange={handleForgotPassword} />
                        <Row>
                          <Col lg="12" className='text-center'>
                            {formStatus != "standby" ?
                              <button type='submit' className='button view-button-login me-2' id="login-btn" onClick={preventDefaultSend}>REQUEST A PASSWORD RESET...</button>
                              :
                              <button type='submit' className='button view-button-login me-2' id="login-btn" onClick={submitForgotPassword}>REQUEST A PASSWORD RESET</button>
                            }
                           <a href="/login">Back to Sign in</a>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </EmptyLayout>
    </section>
  )
}

export default ForgotPasswordPage