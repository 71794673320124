import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory, useNavigate } from 'react-router-dom'
import EmptyLayout from 'layout/EmptyLayout';
import {
    Container, Button, CardFooter, Input, CardBody, Label, ModalHeader, Form, ModalBody, ModalFooter, Card, CardHeader, Col, Modal, Table, Row, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsCheckCircleFill, BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { GoPrimitiveDot } from 'react-icons/go'
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn, FaPencilAlt } from 'react-icons/fa'
import { HiArrowLeft } from 'react-icons/hi'
import { GiHamburgerMenu } from 'react-icons/gi';
import Sport from '../assets/Sports.png';
import home from '../assets/home-hobbies-img.png';
import excercise from '../assets/excercise.png';
import Sidebar from 'components/Sidebar';
import Graph from '../assets/graph-placeholder.png'
import Chartjs from 'components/Chartjs';
import ChartLineGraph from 'components/ChartLineGraph';
import { Spinner } from 'reactstrap';
import axios from 'axios';
import PageSpinner from 'components/PageSpinner.jsx';
import DownloadIcon from "../assets/download-icon.png";
import NoImageAvail from '../assets/no-image-avail.png';
import NoImageAvailable from '../assets/no-image-available.png';

import { useCookies } from 'react-cookie';
import { HiOutlineInformationCircle } from 'react-icons/hi2';

const initialContactFormData = Object.freeze({
    subject: 'Unlock Listing',
    message: '',
});

const initialSingleMarketData = Object.freeze({
    listing_number: '',
    listing_tags: '',
    featured_image: '',
    store_url: '',
    store_name: '',
    brand_name: '',
    brand_amazon: '',
    brand_vendor_central: '',
    web_stores: '',
    business_created: '',
    platform_sales: '',
    country_sales: '',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    linkedin_url: '',
    trademark: '',
    business_growth: '',
    revenue: 0,
    growth_rate_revenue: '',
    asin_sales: '',
    gross_profit_margin: '',
    gross_profit: 0,
    ebitda_margin: '',
    ebitda: '',
    ltm_ebitda: '',
    ltm_gmv: '',
    return_rate: '',
    product_sales_price: '',
    inventory_quantity: '',
    expenses: '',
    reason_category: '',
    certification_requirements: '',
    amazon_brand_registry: '',
    amazon_accelerator: '',
    self_developed_product: '',
    product_development_speed: '',
    product_development_plan: '',
    category_competition: '',
    competitor_brands: '',
    core_quality_issues: '',
    office_address: '',
    warehouse_address: '',
    number_of_employees: '',
    customer_service_process: '',
    refund_process: '',
    ppc_revenue_cost: '',
    ppc_advertising_cost: '',
    ppc_advertising_changes: '',
    asin_ranking: '',
    marketing_agency: '',
    advertising: '',
    marketing_expenses: '',
    giveaways: '',
    account_violations: '',
    asin_violations: '',
    growth_opportunities: '',
    development_plan: '',
    suppliers: '',
    supplier_location: '',
    supplier_proportion: '',
    supplier_account_period: '',
    supplier_length_of_cooperation: '',
    supplier_contracts: '',
    supplier_minimum_order: '',
    supplier_share_control: '',
    supplier_exclusive_contract: '',
    supplier_selection_criteria: '',
    quality_control: '',
    stock_out_occurence: '',
    reason_for_stockout: '',
    ipi_score: 0,
    logistic_providers: '',
    sea_air_freight_proportion: '',
    domestic_warehouse_3rd_party: '',
    overseas_warehouse_3rd_party: '',
    direct_proportion_of_goods: '',
    fbm: '',
    proportion_of_fbm: '',
    reason_for_sale: '',
    valued_transaction_process: '',
    asking_price: 0,
    deal_closure: '',
    upfront_payment: '',
    vps_login_access: '',
});                    

function MarketPlaceSinglePage(props) {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    let query = useQuery();
    const listing_id = query.get('listing_id');
    const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn', 'userRole']);
    const currentUser = cookies.currentUser;
    const isLoggedIn = cookies.isLoggedIn;
    const userRole = cookies.userRole;
    const [singleMarketData, setSingleMarketData] = useState(initialSingleMarketData);
    const [error, setError] = useState('');
    const [selectEarningDate, setSelectEarningDate] = useState(false);
    const [selectAmazonIncome, setSelectAmazonIncome] = useState(false);
    const [selectTrafficDate, setSelectTrafficDate] = useState(false);
    const [tagsData, setTagsData] = useState([]);
    const [startDate, setStartDate] = useState();
    const [dataLoading, setDataLoading] = useState(true);
    const [similarListing, setSimilarListing] = useState([]);
    const [contactModalShow, setContactModalShow] = useState(false);
    const [formStatus, setFormStatus] = useState("standby");
    const [competitorsBrandData, setCompetitorsBrandData] = useState([]);
    const [supplierData, setSupplierData] = useState([]);

    const [user, setUser] = useState({ status: '' });
    const [userLoading, setUserLoading] = useState(true);

    const [contactFormData, setContactFormData] = useState(initialContactFormData);
    // const [amazonIncomeChart, setAmazonIncomeChart] = useState(false);
    // const [amazonCogsChart, setAmazonCogsChart] = useState(false);
    // const [amazonExpensesChart, setAmazonExpensesChart] = useState(false);
    // const [ebitdaChart, setEbitdaChart] = useState(false);
    const [inquiryStatus, setInquiryStatus] = useState([]);
    const [isSidebar, setIsSidebar] = useState(false);

    const [amazonIncomeLabelChart, setAmazonIncomeLabelChart] = useState([]);
    const [amazonCogsLabelChart, setAmazonCogsLabelChart] = useState([]);
    const [amazonExpensesLabelChart, setAmazonExpensesLabelChart] = useState([]);
    const [ebitdaLabelChart, setEbitdaLabelChart] = useState([]);

    const [amazonIncomeDataChart, setAmazonIncomeDataChart] = useState([]);
    const [amazonCogsDataChart, setAmazonCogsDataChart] = useState([]);
    const [amazonExpensesDataChart, setAmazonExpensesDataChart] = useState([]);
    const [ebitdaDataChart, setEbitdaDataChart] = useState([]);

    // console.log("User Role",userRole);

    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
    })
    
    const options = {
        legend: {
            display: false,
        },
    };

    function getSidebar() {
        setIsSidebar(!isSidebar);
    }

    const handleChangeContact = (e) => {
        setContactFormData({
            ...contactFormData,
            [e.target.name]: e.target.value,
        });
    }

    const preventDefault = (e) => {
        e.preventDefault();
    }

    const toggleContactModal = () => {
        setContactModalShow(true);
    }

    const handleClose = () => {
        setContactModalShow(false);
    }

    // page content
    const dateEarningShow = () => {
        setSelectEarningDate(true);
    }

    const dateEarningClose = () => {
        setSelectEarningDate(false);
    }

    const amazonIncomeShow = () => {
        setSelectAmazonIncome(true);
    }

    const amazonIncomeClose = () => {
        setSelectAmazonIncome(false);
    }

    const dateTrafficShow = () => {
        setSelectTrafficDate(true);
    }

    const dateTrafficClose = () => {
        setSelectTrafficDate(false);
    }

    const ITEMS_LIMIT = 4;
    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    }

    const randomStocks = getRandomInt(0, similarListing.length - ITEMS_LIMIT);

    // console.log("Random Stock",randomStocks);

    const navigate = useNavigate();
    // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    // const profitlabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    
    const amazonExpenses = {
        labels: amazonExpensesLabelChart,
        datasets: [{
            label: 'Amazon Expenses',
            // data: [7, 20, 45, 60, 65, 59, 80, 81, 56, 55, 40],
            data: amazonExpensesDataChart,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        }]
    };

    const amazonCogs = {
        labels: amazonCogsLabelChart,
        datasets: [{
            label: 'Amazon CoGs',
            // data: [7, 30, 40, 90, 7, 30, 40, 90],
            data: amazonCogsDataChart,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        }]
    };

    const ebitda = {
        labels: ebitdaLabelChart,
        datasets: [
            {
                label: 'EBITDA',
                barThickness: 10,
                // data: [5000, 1000, 5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 50000, 60000],
                data: ebitdaDataChart,
                backgroundColor: '#932F8C',
            },
        ],
    };

    const amazonIncome = {
        labels: amazonIncomeLabelChart,
        datasets: [
            {
                label: 'Amazon Income',
                barThickness: 20,
                // data: [10, 20, 30, 40, 65, 59, 80, 81, 56, 55, 40],
                data: amazonIncomeDataChart,
                backgroundColor: '#21B1A7',
            },
        ],
    };

    const submitContact = () => {
        setFormStatus('loading')
        if (contactFormData.message != '') {
            axios({
                method: 'post',
                url: process.env.REACT_APP_API_ENDPOINT + 'inquiry',
                data: ({
                    listing_id: listing_id,
                    user_id: currentUser,
                    subject: contactFormData.subject,
                    message: contactFormData.message,
                })
            }).then(function (response) {
                if (response.data.status == 'Success') {
                    toast.success('Request sent successfully!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setFormStatus('standby');
                    setContactModalShow(false);
                } else {
                    toast.error('Request sending failed!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setFormStatus('standby');
                }
            }).catch(function (error) {
                // setError(error);
                console.log(error);
                toast.error('Request sending failed!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setFormStatus('standby');
            })
        } else {
            toast.warning('Please fill in the required fields!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setFormStatus('standby');
        }
    };

        

    useEffect(() => {
        // This is the axios GET
        axios.get(process.env.REACT_APP_API_ENDPOINT + 'listing')
            .then((response) => {
                const data = response.data;
                const result = data.data;
                // console.log("data:", result);
                if (result) {
                    setDataLoading(false);
                    setSimilarListing(result);
                }
            }).catch(error => {
                // setError(error);
                console.log(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setDataLoading(false);
            });

        axios.get(process.env.REACT_APP_API_ENDPOINT + 'listing/' + listing_id + "?user_id=" + currentUser)
            .then((response) => {
                const data = response.data;
                const result = data.data;
                const nth = function (d) {
                    if (d > 3 && d < 21) return 'th';
                    switch (d % 10) {
                        case 1: return "st";
                        case 2: return "nd";
                        case 3: return "rd";
                        default: return "th";
                    }
                }
                const start_year = new Date(result.created_at);
                const start_date = start_year.getDate();
                const start_month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][start_year.getMonth()];
                // console.log(`${start_date}${nth(start_date)} of ${start_month} ${start_year.getFullYear()}`);
                var date_started = `${start_date}${nth(start_date)} of ${start_month}, ${start_year.getFullYear()}`;
                // console.log('Amazon data', result)
                setSingleMarketData({
                    ...singleMarketData,
                    // business_created: new Date(result.business_created).toLocaleDateString('en-US', { year: 'numeric'}),
                    id: result.id,
                    status: result.status,
                    listing_number: result.listing_number,
                    listing_tags: result.listing_tags.id,
                    featured_image: result.featured_image,
                    store_url: result.store_url,
                    store_name: result.store_name,
                    brand_name: result.brand_name,
                    brand_amazon: result.brand_amazon,
                    brand_vendor_central: result.brand_vendor_central,
                    web_stores: result.web_stores,
                    business_created: result.business_created,
                    platform_sales: result.platform_sales,
                    country_sales: result.country_sales,
                    facebook_url: result.facebook_url,
                    twitter_url: result.twitter_url,
                    instagram_url: result.instagram_url,
                    linkedin_url: result.linkedin_url,
                    trademark: result.trademark,
                    business_growth: result.business_growth,
                    revenue: result.revenue,
                    growth_rate_revenue: result.growth_rate_revenue,
                    asin_sales: result.asin_sales,
                    gross_profit_margin: result.gross_profit_margin,
                    gross_profit: result.gross_profit,
                    ebitda_margin: result.ebitda_margin,
                    ebitda: result.ebitda,
                    ltm_ebitda: result.ltm_ebitda,
                    ltm_gmv: result.ltm_gmv,
                    return_rate: result.return_rate,
                    product_sales_price: result.product_sales_price,
                    inventory_quantity: result.inventory_quantity,
                    expenses: result.expenses,
                    reason_category: result.reason_category,
                    certification_requirements: result.certification_requirements,
                    amazon_brand_registry: result.amazon_brand_registry,
                    amazon_accelerator: result.amazon_accelerator,
                    self_developed_product: result.self_developed_product,
                    product_development_speed: result.product_development_speed,
                    product_development_plan: result.product_development_plan,
                    category_competition: result.category_competition,
                    competitor_brands: result.competitor_brands,
                    core_quality_issues: result.core_quality_issues,
                    office_address: result.office_address,
                    warehouse_address: result.warehouse_address,
                    number_of_employees: result.number_of_employees,
                    customer_service_process: result.customer_service_process,
                    refund_process: result.refund_process,
                    ppc_revenue_cost: result.ppc_revenue_cost,
                    ppc_advertising_cost: result.ppc_advertising_cost,
                    ppc_advertising_changes: result.ppc_advertising_changes,
                    asin_ranking: result.asin_ranking,
                    marketing_agency: result.marketing_agency,
                    advertising: result.advertising,
                    marketing_expenses: result.marketing_expenses,
                    giveaways: result.giveaways,
                    account_violations: result.account_violations,
                    asin_violations: result.asin_violations,
                    growth_opportunities: result.growth_opportunities,
                    development_plan: result.development_plan,
                    suppliers: result.suppliers,
                    supplier_location: result.supplier_location,
                    supplier_proportion: result.supplier_proportion,
                    supplier_account_period: result.supplier_account_period,
                    supplier_length_of_cooperation: result.supplier_length_of_cooperation,
                    supplier_contracts: result.supplier_contracts,
                    supplier_minimum_order: result.supplier_minimum_order,
                    supplier_share_control: result.supplier_share_control,
                    supplier_exclusive_contract: result.supplier_exclusive_contract,
                    supplier_selection_criteria: result.supplier_selection_criteria,
                    quality_control: result.quality_control,
                    stock_out_occurence: result.stock_out_occurence,
                    reason_for_stockout: result.reason_for_stockout,
                    ipi_score: result.ipi_score,
                    logistic_providers: result.logistic_providers,
                    sea_air_freight_proportion: result.sea_air_freight_proportion,
                    domestic_warehouse_3rd_party: result.domestic_warehouse_3rd_party,
                    overseas_warehouse_3rd_party: result.overseas_warehouse_3rd_party,
                    direct_proportion_of_goods: result.direct_proportion_of_goods,
                    fbm: result.fbm,
                    proportion_of_fbm: result.proportion_of_fbm,
                    reason_for_sale: result.reason_for_sale,
                    valued_transaction_process: result.valued_transaction_process,
                    asking_price: result.asking_price,
                    deal_closure: result.deal_closure,
                    upfront_payment: result.upfront_payment,
                    vps_login_access: result.vps_login_access,
                    competitor_brands: result.competitor_brands,
                });

                setAmazonIncomeLabelChart(result.amazon_income ? result.amazon_income.months : []);
                setAmazonCogsLabelChart(result.amazon_cogs ? result.amazon_cogs.months : []);
                setAmazonExpensesLabelChart(result.amazon_expenses ? result.amazon_expenses.months : []);
                setEbitdaLabelChart(result.ebitda_chart ? result.ebitda_chart.months : []);

                setAmazonIncomeDataChart(result.amazon_income ? result.amazon_income.values : []);
                setAmazonCogsDataChart(result.amazon_cogs ? result.amazon_cogs.values : []);
                setAmazonExpensesDataChart(result.amazon_expenses ? result.amazon_expenses.values : []);
                setEbitdaDataChart(result.ebitda_chart ? result.ebitda_chart.values : []);

                // setSupplierData(result.suppliers);
                setCompetitorsBrandData(result.competitor_brands);
                setStartDate(date_started);
                setTagsData(result.listing_tags)
                if (result) {
                    setDataLoading(false);
                    const inquiry_status = result.inquiries.status;
                    setInquiryStatus(inquiry_status);
                };

            }).catch(error => {
                // setError(error);
                console.log(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });

        axios.get(process.env.REACT_APP_API_ENDPOINT + 'user/' + currentUser)
            // axios.get('https://bis.jenocabrera.online/api/user/'+user_id)
            .then((response) => {
                const data = response.data;
                const result = data.data;
                // console.log("Results Data:", result);
                if (result) {
                    setUserLoading(false);
                    setUser(result);
                    // console.log(user);
                    // inquiry where user_id = currentUser AND listing_id = listing_id
                }
            }).catch(error => {
                // setError(error);
                console.log(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setUserLoading(false);
            });

        axios.get(process.env.REACT_APP_API_ENDPOINT + 'inquiry/' + listing_id)
            // axios.get('https://bis.jenocabrera.online/api/user')
            .then((response) => {
                const data = response.data;
                const result = data.data;
                // console.log(result);
                if (result) {
                    setInquiryStatus(result.status);
                }
                // setInquiryStatus(result.status);
            }).catch(error => {
                // setError(error);
                console.log(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });

    }, []);

    return (
        <section id='marketing-view'>
            <ToastContainer style={{ width: "370px" }} />
            <Container fluid>
                <Row>
                    <Col lg='2'>
                        {isSidebar || windowDimension.winWidth > 1024 ?
                            <Sidebar clickSidebar={getSidebar} />
                            :
                            null
                        }
                        <button onClick={getSidebar} className={isSidebar ? "hidden" : "hamburger-btn show"}><GiHamburgerMenu /></button>
                        {/* <Sidebar /> */}
                    </Col>
                    <Col lg='10' className='right-container'>
                        <div className="mobile-display">
                            <div>
                                <h2 className="ss-header mb-0">View Listing</h2>
                                <div className="divider mb-0"></div>
                                {userRole == "Admin" ?
                                    <Row>
                                        <Col lg="12" className="mb-0">
                                            <p className="mb-0"><strong>Status: </strong>{singleMarketData.status}</p>
                                        </Col>
                                    </Row>
                                :
                                    null
                                }
                            </div>
                            <div>
                                {userRole == "Admin" ?
                                    <button className='button back-button me-3' onClick={() => navigate("/admin/listing/update?listing_id=" + singleMarketData.id)}><FaPencilAlt className='btn-icon me-3' />EDIT</button>
                                    :
                                    null
                                }
                                <button className='button back-button' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button>
                            </div>
                        </div>
                        <div className="desktop-display">
                            <Row className='top-utility'>
                                <Col lg='8' className="mb-0">
                                    <h2 className="ss-header mb-0">View Listing</h2>
                                    <div className="divider"></div>
                                </Col>
                                <Col lg="4" className='text-end mb-0'>
                                    {userRole == "Admin" ?
                                        <button className='button back-button me-3' onClick={() => navigate("/admin/listing/update?listing_id=" + singleMarketData.id)}><FaPencilAlt className='btn-icon me-3' />EDIT</button>
                                        :
                                        null
                                    }
                                    <button className='button back-button' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button>
                                </Col>
                            </Row>
                            {userRole == "Admin" ?
                                    <Row>
                                        <Col lg="12" className="mb-0">
                                            <p className="mb-0"><strong>Status: </strong>{singleMarketData.status}</p>
                                        </Col>
                                    </Row>
                                :
                                    null
                                }
                        </div>
                        {dataLoading ?
                            <Card>
                                <CardBody>
                                    <div className="text-center"><PageSpinner /></div>
                                </CardBody>
                            </Card>
                        :
                            <>
                                <Col lg='12' className='market-card'>
                                    <Card>
                                        <CardBody>
                                            <Row    >
                                                <Col lg='3'>

                                                    {singleMarketData.featured_image ?
                                                        <img src={singleMarketData.featured_image} width='100%' height='auto' className='item-img' />
                                                        :
                                                        <img src={NoImageAvailable} className='item-img' />
                                                    }

                                                </Col>
                                                <Col lg='3' className='list-container'>
                                                    {/* {dataLoading ?
                                                        <div className="text-center"><PageSpinner /></div>
                                                        : */}
                                                    <>
                                                        <div>
                                                            <span className='serial'>#{singleMarketData.listing_number}</span>
                                                            {/* <p className='list-title'>HOME, HOBBIES</p> */}
                                                            <div className="listing-container">
                                                                {tagsData ?
                                                                    <>
                                                                        {tagsData.length > 0 ?
                                                                            <>
                                                                                {tagsData.map(({ id, name }, tagIndex) => (
                                                                                    <p className='list-title text-uppercase' key={tagIndex}>
                                                                                        {name}
                                                                                        {tagsData.length > tagIndex + 1 ?
                                                                                            ", "
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                    </p>
                                                                                ))}
                                                                            </>
                                                                            :
                                                                            <p>No tags available!</p>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <p>No tags available!</p>
                                                                }
                                                            </div>
                                                            {userRole == "Admin" || inquiryStatus == "Unlocked" ?
                                                                <p className='list-sub-title'>{singleMarketData.brand_name}</p>
                                                                :
                                                                <p className='list-sub-title'></p>
                                                            }
                                                        </div>
                                                    </>
                                                </Col>
                                                <Col lg='6'>
                                                    <Row>
                                                        <Col lg='6'>
                                                            <div className="data-container">
                                                                <p className='list-sub-title mb-0'>LTM GMV</p>
                                                                <p className='data-value mb-0 fw-bold'>{singleMarketData.ltm_gmv ? singleMarketData.ltm_gmv : "-"}</p>
                                                            </div>
                                                        </Col>
                                                        <Col lg='6'>
                                                            <div className="data-container">
                                                                <p className='list-sub-title mb-0'>LTM EBITDA</p>
                                                                <p className='data-value mb-0 fw-bold'>{singleMarketData.ebitda ? singleMarketData.ebitda : "-"}</p>
                                                            </div>
                                                        </Col>
                                                        <Col lg='6'>
                                                            <div className="data-container mt-3">
                                                                <p className='list-sub-title mb-0'>EBITDA MARGIN</p>
                                                                <p className='data-value mb-0 fw-bold'>{singleMarketData.ebitda_margin ? singleMarketData.ebitda_margin : "-"}</p>
                                                            </div>
                                                        </Col>
                                                        <Col lg='6'>
                                                            <div className="data-container mt-3">
                                                                <p className='list-sub-title mb-0'>Year Created</p>
                                                                <p className='data-value mb-0 fw-bold'>{singleMarketData.business_created ? new Date(singleMarketData.business_created).toLocaleDateString('en-US', { year: 'numeric' }) : "-"}</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Card className='market-card'>
                                    <CardHeader className="card-headers">
                                        <p className='list-head mb-0'>FINANCIAL INFORMATION</p>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col lg='4'>
                                                <div className="data-container">
                                                    <p className='list-sub-title mb-0'>Business Started to Increase</p>
                                                    <p className='data-value mb-0 fw-bold'>{singleMarketData.business_growth ? singleMarketData.business_growth : "-"}</p>
                                                </div>
                                            </Col>
                                            <Col lg='4'>
                                                <div className="data-container">
                                                    <p className='list-sub-title mb-0'>Revenue (last 12 mos)</p>
                                                    <p className='data-value mb-0 fw-bold'>${singleMarketData.revenue ? singleMarketData.revenue.toLocaleString(undefined, {maximumFractionDigits:2}) : 0}</p>
                                                </div>
                                            </Col>
                                            <Col lg='4'>
                                                <div className="data-container">
                                                    <p className='list-sub-title mb-0'>Growth Rate Revenue</p>
                                                    <p className='data-value mb-0 fw-bold'>{singleMarketData.growth_rate_revenue ? singleMarketData.growth_rate_revenue : "0"}%</p>
                                                </div>
                                            </Col>
                                            <Col lg='4'>
                                                <div className="data-container mt-3">
                                                    <p className='list-sub-title mb-0'>Top 5 ASINs Shares (last 12 mos)</p>
                                                    <p className='data-value mb-0 fw-bold'>{singleMarketData.asin_sales ? singleMarketData.asin_sales : "-"}</p>
                                                </div>
                                            </Col>
                                            <Col lg='4'>
                                                <div className="data-container mt-3">
                                                    <p className='list-sub-title mb-0'>Gross Profit (last 12 mos)</p>
                                                    <p className='data-value mb-0 fw-bold'>${singleMarketData.gross_profit ? singleMarketData.gross_profit.toLocaleString(undefined, {maximumFractionDigits:2}): "0"}</p>
                                                </div>
                                            </Col>
                                            <Col lg='4'>
                                                <div className="data-container mt-3">
                                                    <p className='list-sub-title mb-0'>EBITDA (last 12 mos)</p>
                                                    <p className='data-value mb-0 fw-bold'>{singleMarketData.ebitda ? singleMarketData.ebitda : "-"}</p>
                                                </div>
                                            </Col>
                                            <Col lg='4'>
                                                <div className="data-container mt-3">
                                                    <p className='list-sub-title mb-0'>Return Rate (last 12 mos)</p>
                                                    <p className='data-value mb-0 fw-bold'>{singleMarketData.return_rate ? singleMarketData.return_rate : "0"}%</p>
                                                </div>
                                            </Col>
                                            <Col lg='4'>
                                                <div className="data-container mt-3 pe-0">
                                                    <p className='list-sub-title mb-0'>Product Sales Prices (last 12 mos)</p>
                                                    <p className='data-value mb-0 fw-bold'>{singleMarketData.product_sales_price ? singleMarketData.product_sales_price : "-"}</p>
                                                </div>
                                            </Col>
                                            <Col lg='4'>
                                                <div className="data-container mt-3">
                                                    <p className='list-sub-title mb-0'>Latest Inventory Quantity</p>
                                                    <p className='data-value mb-0 fw-bold'>{singleMarketData.inventory_quantity ? singleMarketData.inventory_quantity : "-"}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                                <Row>
                                    <Col lg='6'>
                                        <Card className='market-card'>
                                            <CardHeader className="card-headers">
                                                <p className='list-head mb-0'>GENERAL INFORMATION</p>
                                            </CardHeader>
                                            <CardBody>
                                                {inquiryStatus == "Unlocked" || userRole == "Admin" ?
                                                    <>
                                                        {singleMarketData.store_name !== "" && singleMarketData.store_name !== null ?
                                                            <>
                                                                <p className='list-description mb-2'>
                                                                    <b>Store Name:</b> {singleMarketData.store_name}
                                                                </p>
                                                                <hr />
                                                            </>
                                                        :
                                                            null
                                                        }
                                                        {singleMarketData.store_url !== "" && singleMarketData.store_url !== null ?
                                                            <>
                                                                <p className='list-description mb-2'>
                                                                    <b>Store URL:</b> <a href={singleMarketData.store_url} target="_blank">{singleMarketData.store_url}</a>
                                                                </p>
                                                                <hr />
                                                            </>
                                                        :
                                                            null
                                                        }
                                                        {singleMarketData.brand_name !== "" && singleMarketData.brand_name !== null ?
                                                            <>
                                                                <p className='list-description mb-2'>
                                                                    <b>Brand Name:</b> {singleMarketData.brand_name}
                                                                </p>
                                                                <hr />
                                                            </>
                                                        :
                                                            null
                                                        }
                                                        {singleMarketData.trademark !== "" && singleMarketData.trademark !== null ?
                                                            <>
                                                                <p className='list-description mb-2'>
                                                                    <b>Trademark:</b> {singleMarketData.trademark}
                                                                </p>
                                                                <hr />
                                                            </>
                                                        :
                                                            null
                                                        }
                                                        {singleMarketData.facebook_url != "" && singleMarketData.facebook_url != null &&
                                                         singleMarketData.instagram_url != "" && singleMarketData.instagram_url !== null &&
                                                         singleMarketData.twitter_url != "" && singleMarketData.twitter_url !== null && 
                                                         singleMarketData.linkedin_url != "" && singleMarketData.linkedin_url !== null ?
                                                            <>
                                                                <p className='list-description mb-2'>
                                                                    <div className='d-flex align-items-center'>
                                                                        <b>Social Media Accounts:</b>
                                                                        {singleMarketData.facebook_url != "" && singleMarketData.facebook_url ?
                                                                            <a href={singleMarketData.facebook_url} target="_blank" className='soc-med-container'><FaFacebookF className='soc-med' /></a>
                                                                            :
                                                                            null
                                                                        }
                                                                        {singleMarketData.instagram_url != "" && singleMarketData.instagram_url ?
                                                                            <a href={singleMarketData.instagram_url} target="_blank" className='soc-med-container'><FaInstagram className='soc-med' /></a>
                                                                            :
                                                                            null
                                                                        }
                                                                        {singleMarketData.twitter_url != "" && singleMarketData.twitter_url ?
                                                                            <a href={singleMarketData.twitter_url} target="_blank" className='soc-med-container'><FaTwitter className='soc-med' /></a>
                                                                            :
                                                                            null
                                                                        }
                                                                        {singleMarketData.linkedin_url != "" && singleMarketData.linkedin_url ?
                                                                            <a href={singleMarketData.linkedin_url} target="_blank" className='soc-med-container'><FaLinkedinIn className='soc-med' /></a>
                                                                            :
                                                                            null
                                                                        }
                                                                        {/* <a href="#" className='soc-med-container'><FaInstagram className='soc-med' /></a>
                                                                                <a href="#" className='soc-med-container'><FaTwitter className='soc-med' /></a>
                                                                                <a href="#" className='soc-med-container'><FaLinkedinIn className='soc-med' /></a> */}
                                                                    </div>
                                                                </p>
                                                                <hr />
                                                            </>
                                                        :
                                                            null
                                                        }
                                                    </>
                                                    :
                                                    <Card className="unlock-feature mb-3 text-center">
                                                        <CardBody>
                                                            <button className='button back view-button mx-auto' onClick={toggleContactModal}>Unlock Listing</button>
                                                            <p className="unlock-text text-small"><HiOutlineInformationCircle color="#d9524e" className="info-circle" />You need to unlock this listing to view this information</p>
                                                        </CardBody>
                                                    </Card>
                                                }
                                                {singleMarketData.brand_amazon !== "" && singleMarketData.brand_amazon !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Are the brands being used in Amazon Store:</b> {singleMarketData.brand_amazon == 1 ? "Yes" : "No"}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.brand_vendor_central !== "" && singleMarketData.brand_vendor_central !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Are the brands being used in Vendor Central?:</b> {singleMarketData.brand_vendor_central == 1 ? "Yes" : "No"}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.web_stores !== "" && singleMarketData.web_stores !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Are the brands being sold in other platforms?:</b> {singleMarketData.web_stores == 1 ? "Yes" : "No"}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                
                                                {/* <p className='list-description mb-2'>
                                                            <b>Brands Under Amazon Stores:</b> {singleMarketData.brand_amazon == 1 ? "Yes" : "No"}
                                                        </p>
                                                        <p className='list-description mb-2'>
                                                            <b>Brands Under Vendor Central:</b> {singleMarketData.brand_vendor_central == 1 ? "Yes" : "No"}
                                                        </p>
                                                        <p className='list-description mb-2'>
                                                            <b>Web Stores:</b> {singleMarketData.web_stores == 1 ? "Yes" : "No"}
                                                        </p> */}
                                                {singleMarketData.business_created !== "" && singleMarketData.business_created !== null ? 
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Year Created:</b> {new Date(singleMarketData.business_created).toLocaleDateString('en-US', { year: 'numeric' })}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }     
                                                {singleMarketData.platform_sales !== "" && singleMarketData.platform_sales !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Platform Approximate Sales:</b> {singleMarketData.platform_sales}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.country_sales !== "" && singleMarketData.country_sales !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Country Approximate Sales:</b> {singleMarketData.country_sales}
                                                        </p>
                                                    </>
                                                :
                                                    null
                                                }
                                              
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg='6'>
                                        <Card className='market-card'>
                                            <CardHeader className="card-headers">
                                                <p className='list-head mb-0'>PRODUCTS, BRANDS AND COMPETITION</p>
                                            </CardHeader>
                                            <CardBody>
                                                {singleMarketData.reason_category !== "" && singleMarketData.reason_category !== null ?
                                                    <>
                                                        <p className='list-description mb-2'><b>Reason for Choosing the Products and Category:</b> {singleMarketData.reason_category}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.certification_requirements !== "" && singleMarketData.certification_requirements !== null ?
                                                    <>
                                                        <p className='list-description mb-2'><b>Certification Requirements:</b> {singleMarketData.certification_requirements == 1 ? "Yes" : "No"}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.amazon_brand_registry !== "" && singleMarketData.amazon_brand_registry !== null ?
                                                    <>
                                                        <p className='list-description mb-2'><b>Amazon Brand Registry Enrolled:</b> {singleMarketData.amazon_brand_registry == 1 ? "Yes" : "No"}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                               
                                                {/* <p className='list-description mb-2'><b>Amazon Accelerator Program Enrolled:</b> {singleMarketData.amazon_accelerator == 1 ? "Yes" : "No"}
                                                    </p>
                                                    <p className='list-description mb-2'><b>Self Developed Products:</b> {singleMarketData.self_developed_product == 1 ? "Yes" : "No"}
                                                    </p> */}
                                                {singleMarketData.product_development_speed !== "" && singleMarketData.product_development_speed !== null ?
                                                    <>
                                                        <p className='list-description mb-2'><b>Speed of New Product Developments:</b> {singleMarketData.product_development_speed}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                
                                                {/* <p className='list-description mb-2'><b>New Product Development:</b> {singleMarketData.product_development_plan}
                                                    </p>
                                                    <p className='list-description mb-2'><b>Category Competition:</b> {singleMarketData.category_competition}
                                                    </p> */}
                                                {singleMarketData.core_quality_issues !== "" && singleMarketData.core_quality_issues !== null ?
                                                    <>
                                                        <p className='list-description mb-2'><b>Core Quality Issues:</b> {singleMarketData.core_quality_issues}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.competitor_brands !== "" && singleMarketData.competitor_brands !== null?
                                                    <>
                                                        <p className='list-description mb-2'><b>Top 5 Competitors:</b> <br></br>
                                                            {competitorsBrandData ?
                                                                <>
                                                                    {competitorsBrandData.length > 0 ?
                                                                        <>
                                                                            {competitorsBrandData.map(({ id, name, code }, index) => (
                                                                                <>
                                                                                    <div className="mt-2" key={index}>
                                                                                        <BsCheckCircleFill color='#932F8C' className='me-2' />{name ? name : "-"}：{code ? code : "-"}
                                                                                    </div>
                                                                                </>
                                                                            ))}
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                </>
                                                                :
                                                                null
                                                            }
                                                        </p>
                                                    </>
                                                :
                                                    null
                                                }
                                                
                                            </CardBody>
                                        </Card>
                                    </Col>

                                </Row>

                                <Card className='market-card'>
                                    <CardHeader className="card-headers">
                                        <p className='list-head mb-0'>OPERATIONS</p>
                                    </CardHeader>
                                    <CardBody>
                                        {inquiryStatus == "Unlocked" || userRole == "Admin" ?
                                            <>
                                                {singleMarketData.office_address !== "" && singleMarketData.office_address !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Office Address:</b> {singleMarketData.office_address}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.warehouse_address !== "" && singleMarketData.warehouse_address !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Warehouse Address:</b> {singleMarketData.warehouse_address}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                
                                            </>
                                            :
                                            <Card className="unlock-feature mb-3 text-center py-5">
                                                <CardBody>
                                                    <button className='button back view-button' onClick={toggleContactModal}>Unlock Listing</button>
                                                    <p className="unlock-text text-small"><HiOutlineInformationCircle color="#d9524e" className="info-circle" />You need to unlock this listing to view this information</p>
                                                </CardBody>
                                            </Card>
                                        }
                                        {singleMarketData.growth_opportunities !== "" && singleMarketData.growth_opportunities !== null ? 
                                            <>
                                                <p className='list-description mb-2'>
                                                    <b>Five Future Growth Opportunities:</b> {singleMarketData.growth_opportunities}
                                                </p>
                                                <hr />
                                            </>
                                        :
                                            null
                                        }
                                        {singleMarketData.development_plan !== "" && singleMarketData.development_plan !== null ?
                                            <>
                                                <p className='list-description mb-2'>
                                                    <b>If there is no such acquisition opportunity, how do you plan to develop the business?</b><br /> {singleMarketData.development_plan}
                                                </p>
                                                <hr />
                                            </>
                                        :
                                            null
                                        }
                                        {singleMarketData.number_of_employees !== "" && singleMarketData.number_of_employees !== null ?
                                            <>
                                                <p className='list-description mb-2'>
                                                    <b>Number of Employees:</b> {singleMarketData.number_of_employees}
                                                </p>
                                                <hr />
                                            </>
                                        :
                                            null
                                        }
                                        {singleMarketData.customer_service_process !== "" && singleMarketData.customer_service_process !== null ? 
                                            <>
                                                <p className='list-description mb-2'>
                                                    <b>Customer Service:</b> {singleMarketData.customer_service_process}
                                                </p>
                                                <hr />
                                            </>
                                        :
                                            null
                                        }
                                        {singleMarketData.refund_process !== "" && singleMarketData.refund_process !== null ?
                                            <>
                                                <p className='list-description mb-2'>
                                                    <b>Return and Refund:</b> {singleMarketData.refund_process}
                                                </p>
                                                <hr />
                                            </>
                                        :
                                            null
                                        }
                                        {singleMarketData.ppc_revenue_cost !== "" && singleMarketData.ppc_revenue_cost !== null ?
                                            <>
                                                <p className='list-description mb-2'>
                                                    <b>Revenue of PPC Advertising Cost (last 12 mos):</b> {singleMarketData.ppc_revenue_cost} %
                                                </p>
                                                <hr />
                                            </>
                                        :
                                            null
                                        }
                                        {singleMarketData.ppc_advertising_cost !== "" &&  singleMarketData.ppc_advertising_cost !== null ? 
                                            <>
                                                <p className='list-description mb-2'>
                                                    <b>Sale of PPC Advertising Cost (last 12 mos):</b> {singleMarketData.ppc_advertising_cost} %
                                                </p>
                                                <hr />
                                            </>
                                        :
                                            null
                                        }
                                        {singleMarketData.ppc_advertising_changes !== "" && singleMarketData.ppc_advertising_changes !== null ?
                                            <>
                                                <p className='list-description mb-2'>
                                                    <b>Have there been any significant changes in the PPC advertising cost as a percentage of total revenue in the last 12 months?</b> <br /> {singleMarketData.ppc_advertising_changes}
                                                </p>
                                                <hr />
                                            </>
                                        :
                                            null
                                        }
                                        {singleMarketData.asin_ranking !== "" && singleMarketData.asin_ranking !== null ?
                                            <>
                                                <p className='list-description mb-2'>
                                                    <b>Top 5 ASINs Ranking:</b> {singleMarketData.asin_ranking}
                                                </p>
                                                <hr />
                                            </>
                                        :
                                            null
                                        }
                                        {singleMarketData.advertising !== "" && singleMarketData.advertising !== null ?
                                            <>
                                                <p className='list-description mb-2'>
                                                    <b>Whether using Amazon video Ads, Amazon DSP, Facebook Ads, Google Ads, advertising network, etc.?</b><br /> {singleMarketData.advertising}
                                                </p>
                                                <hr />
                                            </>
                                        :
                                            null
                                        }
                                        {singleMarketData.marketing_expenses !== "" && singleMarketData.marketing_expenses !== null ?
                                            <>
                                                <p className='list-description mb-2'>
                                                    <b>Marketing Expenses:</b> {singleMarketData.marketing_expenses}
                                                </p>
                                                <hr />
                                            </>
                                        :
                                            null
                                        }
                                        {singleMarketData.giveaways !== "" && singleMarketData.giveaways !== null ?
                                            <>
                                                <p className='list-description mb-2'>
                                                    <b>Whether using or used any giveaways, rebate solicitations (including insert cards) or other tactics to improve product ratings?</b><br /> {singleMarketData.giveaways}
                                                </p>
                                                <hr />
                                            </>
                                        :
                                            null
                                        }
                                        {singleMarketData.account_violations !== "" && singleMarketData.account_violations !== null ?
                                            <>
                                                <p className='list-description mb-2'>
                                                    <b>Has the account ever been suspended, including misjudgments?</b> <br /> {singleMarketData.account_violations}
                                                </p>
                                                <hr />
                                            </>
                                        :
                                            null
                                        }
                                        {singleMarketData.asin_violations !== "" && singleMarketData.asin_violations !== null ?
                                            <>
                                                <p className='list-description mb-2'>
                                                    <b>Has any ASIN ever been removed or received warnings?</b> <br /> {singleMarketData.asin_violations}
                                                </p>
                                            </>
                                        :
                                            null
                                        }
                                        
                                    </CardBody>
                                </Card>

                                <Card className='market-card'>
                                    <CardHeader className="card-headers">
                                        <p className='list-head mb-0'>Amazon Income</p>
                                    </CardHeader>
                                    <CardBody>
                                        <Chartjs ChartData={amazonIncome} options={options} />
                                    </CardBody>
                                </Card>

                                <Card className='market-card'>
                                    <CardHeader className="card-headers">
                                        <p className='list-head mb-0'>Amazon CoGs</p>
                                    </CardHeader>
                                    <CardBody>
                                        <Chartjs ChartData={amazonCogs} options={options} />
                                    </CardBody>
                                </Card>

                                <Card className='market-card'>
                                    <CardHeader className="card-headers">
                                        <p className='list-head mb-0'>Amazon Expenses</p>
                                    </CardHeader>
                                    <CardBody>
                                        <Chartjs ChartData={amazonExpenses} options={options}/>
                                    </CardBody>
                                </Card>

                                <Card className='market-card'>
                                    <CardHeader className="card-headers">
                                        <p className='list-head mb-0'>EBITDA</p>
                                    </CardHeader>
                                    <CardBody>
                                        <Chartjs ChartData={ebitda} options={options} />
                                    </CardBody>
                                </Card>

                                <Row>
                                    <Col lg='12'>
                                        <Card className='market-card'>
                                            <CardHeader className="card-headers">
                                                <p className='list-head mb-0'>SUPPLY CHAIN</p>
                                            </CardHeader>
                                            <CardBody>
                                                {singleMarketData.suppliers !== "" && singleMarketData.suppliers !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b className='mb-3'>The types of top 5 suppliers (factory, wholesaler or trader)</b> <br />
                                                            {singleMarketData.suppliers}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.supplier_location !== "" && singleMarketData.supplier_location !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Supplier Locations:</b> {singleMarketData.supplier_location}
                                                        </p>
                                                        <hr />  
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.supplier_proportion !== "" && singleMarketData.supplier_proportion !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>The proportion of purchase amount of top 5 suppliers in the last 12 months:</b> {singleMarketData.supplier_proportion}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.supplier_account_period !== "" && singleMarketData.supplier_account_period !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>The account period of top 5 suppliers:</b> {singleMarketData.supplier_account_period}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.supplier_length_of_cooperation !== "" && singleMarketData.supplier_length_of_cooperation !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>The length of cooperation of the top 5 suppliers:</b> {singleMarketData.supplier_length_of_cooperation}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.supplier_length_of_cooperation !== "" && singleMarketData.supplier_length_of_cooperation !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Whether there are contracts with the top 5 suppliers?</b> {singleMarketData.supplier_length_of_cooperation}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.supplier_minimum_order !== "" && singleMarketData.supplier_minimum_order !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>The minimum order requirement of top 5 suppliers:</b> {singleMarketData.supplier_minimum_order}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.supplier_share_control !== "" && singleMarketData.supplier_share_control !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Whether the top 5 suppliers offer exclusive contracted development of products?</b> {singleMarketData.supplier_share_control}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.supplier_exclusive_contract !== "" && singleMarketData.supplier_exclusive_contract !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Whether the top 5 suppliers include companies in which you own shares or control?</b> {singleMarketData.supplier_exclusive_contract}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.supplier_selection_criteria !== "" && singleMarketData.supplier_selection_criteria !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>The supplier selection criteria:</b> {singleMarketData.supplier_selection_criteria}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.quality_control !== "" &&  singleMarketData.quality_control !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Quality Control:</b> {singleMarketData.quality_control}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.quality_control !== "" && singleMarketData.quality_control !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Quality Control:</b> {singleMarketData.quality_control}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.ipi_score !== "" && singleMarketData.ipi_score !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>IPI Score:</b> {singleMarketData.ipi_score}
                                                        </p>
                                                    </>
                                                :
                                                    null
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg='6'>
                                        <Card className='market-card'>
                                            <CardHeader className="card-headers">
                                                <p className='list-head mb-0'>LOGISTICS</p>
                                            </CardHeader>
                                            <CardBody>
                                                {singleMarketData.logistic_providers !== "" && singleMarketData.logistic_providers !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Top 5 Logistic Providers Cost (last 12 mos):</b> {singleMarketData.logistic_providers}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.sea_air_freight_proportion !== "" && singleMarketData.sea_air_freight_proportion !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Sea and Air Freight Proportion (last 12 mos):</b> {singleMarketData.sea_air_freight_proportion}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.fbm !== "" && singleMarketData.fbm !== null ?
                                                    <>
                                                        <p className='list-description mb-0'>
                                                            <b>FBM:</b> {singleMarketData.fbm}
                                                        </p>
                                                    </>
                                                :
                                                    null
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg='6'>
                                        <Card className='market-card'>
                                            <CardHeader className="card-headers">
                                                <p className='list-head mb-0'>ACQUISITION</p>
                                            </CardHeader>
                                            <CardBody>
                                                {singleMarketData.reason_for_sale !== "" && singleMarketData.reason_for_sale !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Reason for Selling the Business:</b> {singleMarketData.reason_for_sale}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                {singleMarketData.valued_transaction_process !== "" && singleMarketData.valued_transaction_process !== null ?
                                                    <>
                                                        <p className='list-description mb-2'>
                                                            <b>Most Valued Transaction Process:</b> {singleMarketData.valued_transaction_process}
                                                        </p>
                                                        <hr />
                                                    </>
                                                :
                                                    null
                                                }
                                                
                                                {/* <p className='list-description mb-2'>
                                                            <b>Price:</b> No
                                                        </p> */}
                                                {singleMarketData.vps_login_access !== "" && singleMarketData.vps_login_access !== null ?
                                                    <>
                                                        <p className='list-description mb-0'>
                                                            <b>VSP Login Access:</b> {singleMarketData.vps_login_access}
                                                        </p>
                                                    </>
                                                :
                                                    null
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg='12' className='market-card mb-0'>
                                        <p className='list-head'>SIMILAR LISTINGS</p>
                                    </Col>
                                </Row>
                                
                                <Row className='market-card'>
                                    {!dataLoading ?
                                        <>
                                            {similarListing.length > 0 ?
                                                <>
                                                    {similarListing.slice(randomStocks, randomStocks + ITEMS_LIMIT).map(({ id, tags, brand_name, listing_price, expenses, monetization, monthly_net_profit, featured_image, asking_price }, listingIndex) => (
                                                        <>
                                                            {id != listing_id && listingIndex < 3 ?
                                                                <Col lg='4' key={listingIndex}>
                                                                    <Card>
                                                                        <div className='featuredimage-container'>
                                                                            {featured_image != '' ?
                                                                                <div className="listing-thumbnail-container" style={{ backgroundImage: "url(" + process.env.REACT_APP_URL + "/storage/gallery/"+featured_image + ")" }}>
                                                                                    {/* <img src={featured_image} className='other-list-img' /> */}
                                                                                </div>
                                                                                :
                                                                                <div className="listing-thumbnail-container" style={{ backgroundImage: "url(" + NoImageAvailable + ")", backgroundSize: 'contain' }}>
                                                                                    {/* <img src={featured_image} className='other-list-img' /> */}
                                                                                </div>
                                                                                // <CardBody>
                                                                                //     <img src={NoImageAvail} className='other-list-img' />
                                                                                // </CardBody>
                                                                            }
                                                                        </div>
                                                                        <CardBody>
                                                                            {/* Store URL  */}
                                                                            {/* <p><span className='list-head'>SPORTS</span><br /> */}
                                                                            <p>
                                                                                <div>
                                                                                    {tags ?
                                                                                        <>
                                                                                            {tags.length ?
                                                                                                <>
                                                                                                    {tags.map(({ id, name }, tagsIndex) => (
                                                                                                        <span className='list-head' key={tagsIndex}>
                                                                                                            {name}
                                                                                                            {tags.length > tagsIndex + 1 ?
                                                                                                                ","
                                                                                                                :
                                                                                                                null
                                                                                                            }
                                                                                                        </span>
                                                                                                    ))}
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        "-"
                                                                                    }
                                                                                    
                                                                                </div>
                                                                                <span className='list-sub-title'>{brand_name ? brand_name : "-"}</span>
                                                                            </p>
                                                                            {/* <span className='list-sub-title'>Display Advertising | Amazon Associates</span> */}
                                                                            {/* </p> */}
                                                                            <Row>
                                                                                <p className='list-price mb-2'>${asking_price}</p>
                                                                                <Col lg='6'>
                                                                                    Expenses
                                                                                </Col>
                                                                                <Col lg='6'>
                                                                                    <span className='similar-listing-prices'>${expenses ? expenses : "0"}</span>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className='mb-3'>
                                                                                <Col lg='6'>
                                                                                    Net Profit
                                                                                </Col>
                                                                                <Col lg='6'>
                                                                                    <span className='similar-listing-prices'>${monthly_net_profit ? monthly_net_profit : "0"}</span>
                                                                                    {/* <span className='similar-listing-prices'>$2,343</span> */}
                                                                                </Col>
                                                                            </Row>
                                                                            {/* <p className='list-price mb-2'>$818,092</p> */}
                                                                            <Row>
                                                                                <Col lg='12'>
                                                                                    <a href={`/listing/view?listing_id=${id}`}>
                                                                                        <button className='button view-button w-100 mb-3' onClick={() => navigate('/listing/view?listing_id=' + id)}>VIEW LISTING</button>
                                                                                    </a>
                                                                                </Col>
                                                                            </Row>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                                :
                                                                null
                                                            }
                                                        </>

                                                    ))}
                                                </>
                                                :
                                                <>
                                                    <Card className='market-card'>
                                                        <CardBody className='market-card-body'>
                                                            <div className="text-center">No record found</div>
                                                        </CardBody>
                                                    </Card>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <Card className='market-card'>
                                                <CardBody className='market-card-body'>
                                                    <div className="text-center"><PageSpinner /></div>
                                                </CardBody>
                                            </Card>
                                        </>
                                    }
                                </Row>
                            </>
                        }
                    </Col>
                </Row >
            </Container >
            <Modal
                isOpen={contactModalShow}
                centered
                fade={false}
                id="modal"
            >
                <ModalHeader>
                    <strong>REQUEST</strong>
                    <button type="button" className="react-modal-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody>
                    {/* <Card>
                    <CardBody> */}
                    <Form>
                        {/* <p className='list-sub-title mb-0'>Subject</p>
                        <Input type="text" name="subject" className='mb-3' onChange={handleChangeContact} /> */}
                        <p className='list-sub-title mb-0'>Message</p>
                        <Input type="textarea" rows={5} name="message" maxLength={255} onChange={handleChangeContact} />
                    </Form>
                    {/* </CardBody>
                </Card> */}
                </ModalBody>
                <ModalFooter className="text-end">
                    <button className='button view-button-close me-2' onClick={handleClose}>CANCEL</button>
                    {formStatus != "standby" ?
                        <button className='button view-button' onClick={preventDefault}>SENDING...</button>
                        :
                        <button className='button view-button' onClick={submitContact}>SUBMIT</button>
                    }
                </ModalFooter>
            </Modal>
        </section >
    )
}

export default MarketPlaceSinglePage;