import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory, useNavigate } from 'react-router-dom'
import EmptyLayout from 'layout/EmptyLayout';
import {
    Container, Button, CardFooter, Input, CardBody, Label, ModalHeader, ModalBody, ModalFooter, Card, Col, Modal, Table, Row, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import { FiFilter } from 'react-icons/fi';
import { BiSort } from 'react-icons/bi';
import { AiOutlinePlus } from 'react-icons/ai'
import { GiHamburgerMenu } from 'react-icons/gi';
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'
import Sport from '../assets/Sports.png';
import home from '../assets/home-hobbies-img.png';
import excercise from '../assets/excercise.png';
import Sidebar from 'components/Sidebar';
import { Spinner } from 'reactstrap';
import PageSpinner from 'components/PageSpinner.jsx';
import axios from 'axios';
import NoImageAvail from '../assets/no-image.png';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { useCookies } from 'react-cookie';
import { AiFillEye, AiFillEyeInvisible, AiFillEdit } from 'react-icons/ai';

const initialFilterData = Object.freeze({
    ltm_gmv_from: '',
    ltm_gmv_to: '',
    ebitda_from: '',
    ebitda_to: '',
    ebitda_margin: '',
    yoy_growth_from: '',
    categories: [],
    tags: [],
});

function WatchlistPage(props) {
    const [cookies, setCookies, removeCookie] = useCookies(['currentUser', 'isLoggedIn', 'userRole', 'ltm_gmv_from','ltm_gmv_to', 'ebitda_from','ebitda_to','ebitda_margin', 'yoy_growth_from', 'listing_number', 'sorting', 'filterCategories', 'filterTags']);
    const currentUser = cookies.currentUser;
    const isLoggedIn = cookies.isLoggedIn;
    const userRole = cookies.userRole;
    const ltm_gmv_from = cookies.ltm_gmv_from;
    const ltm_gmv_to = cookies.ltm_gmv_to;
    const ebitda_from = cookies.ebitda_from;
    const ebitda_to = cookies.ebitda_to;
    const ebitda_margin = cookies.ebitda_margin;
    const yoy_growth_from = cookies.yoy_growth_from;
    const listing_number = cookies.listing_number;
    const sorting = cookies.sorting;
    const filterCategories = cookies.filterCategories;
    const filterTags = cookies.filterTags;
    const [marketData, setMarketData] = useState([]);
    const [error, setError] = useState('');
    const [tableLoading, setTableLoading] = useState(true);
    const [isSidebar, setIsSidebar] = useState(false);
    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
    });
    const [formStatus, setFormStatus] = useState("standby");
    const [filter, setFilter] = useState([]);
    const [filterShow, setFilterShow] = useState(false);
    const [listingNumber, setListingNumber] = useState("");
    const [sortSearch, setSortSearch] = useState("");
    const [tagsFilter, setTagsFilter] = useState([]);
    const [tagIds, setTagIds] = useState([]);
    const [tagsData, setTagsData] = useState([]);
    const [tagsDataLength, setTagsDataLength] = useState(0);
    const [tagsBoxStyle, setTagsBoxStyle] = useState('card-show-tags-hidden');
    const [tagsListShow, setTagsListShow] = useState(false);
    const [tagsReady, setTagsReady] = useState(false);
    const [filterData, setFilterData] = useState(initialFilterData);
    const [ltmGmvFrom, setLtmGmvFrom] = useState();
    const [reloadCount, setReloadCount] = useState(0);

    function getSidebar() {
        setIsSidebar(!isSidebar);
    }

    const handleChangeCheck = (id) => {
        setTagIds([...tagIds, id]);
        setFilterData({
            ...filterData,
            categories: [...tagIds, id],
        });
        setCookies('filterCategories', [...tagIds, id], { path: '/' });
        // console.log('tagarray id', tagIds);
    }

    const handleChangeUnchecked = (id) => {
        let filterIds = tagIds.filter(item => item !== id);
        // console.log('removeID', filteredArray)
        setTagIds(filterIds);
        setFilterData({
            ...filterData,
            categories: filterIds,
        });
        setCookies('filterCategories', filterIds, { path: '/' });
        // console.log("tag IDs uncheck", filterId);
    }

    const handleChangeFilter = (e) => {
        setFilterData({
            ...filterData,
            [e.target.name]: e.target.value,
        });
        if(e.target.name == 'ltm_gmv_from') {
            setCookies('ltm_gmv_from', e.target.value, { path: '/' });
        } 
        else if (e.target.name == "ltm_gmv_to") {
            setCookies('ltm_gmv_to', e.target.value, { path: '/' });
        } 
        else if (e.target.name == "ebitda_from") {
            setCookies('ebitda_from', e.target.value, { path: '/' });
        } 
        else if (e.target.name == "ebitda_to") {
            setCookies('ebitda_to', e.target.value, { path: '/' });
        } 
        else if (e.target.name == "ebitda_margin") {
            setCookies('ebitda_margin', e.target.value, { path: '/' });
        }
        else if (e.target.name == "yoy_growth_from") {
            setCookies('yoy_growth_from', e.target.value, { path: '/' });
        }
    }

    function removeTag(index) {
        setTagsFilter(tagsFilter.filter((el, i) => i !== index))
        setFilterData({
            ...filterData,
            tags: tagsFilter.filter((el, i) => i !== index),
        });
        setCookies('filterTags', tagsFilter.filter((el, i) => i !== index), { path: '/' });
    }

    const toggleHideTags = () => {
        setTagsListShow(false);
        setTagsBoxStyle('card-show-tags-hidden');
    }

    const toggleShowTags = () => {
        setTagsListShow(true);
        setTagsBoxStyle('card-show-tags-show');
    }

    // page content
    const navigate = useNavigate();

    const sortListing = (e) => {
        const sort = e.target.value;
        setSortSearch(sort);
        setTableLoading(true);
        setCookies('sorting', sort, { path: '/' });

        axios.get(process.env.REACT_APP_API_ENDPOINT + 'user/'+currentUser+'/watchlists?sort=' + sort + '&listing_number=' + listingNumber+'&ltm_gmv_from='+filterData.ltm_gmv_from+'&ltm_gmv_from='+filterData.ltm_gmv_to+'&ebitda_from='+filterData.ebitda_from+'&ebitda_to='+filterData.ebitda_to+'&ebitda_margin='+filterData.ebitda_margin+'&yoy_growth_from='+filterData.yoy_growth_from+'&tags='+filterData.tags+'&categories='+filterData.categories+'&user_id='+currentUser)

            .then((response) => {
                const data = response.data;
                const result = data.data;
                if (result) {
                    setMarketData(result);
                    setTableLoading(false);
                }
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTableLoading(false);
                
            });
    }

    const toggleFilterShow = (e) => {

        // setLtmGmvFrom(cookies.ltm_gmv_from);
        // console.log('ltm_gmv_from', ltmGmvFrom);
        setFilterShow(!filterShow);
    }

    const clearFilter = (e) => {
        setFilterShow(false);
        setFilterData(initialFilterData);
        setTagsFilter([]);
        setTagIds([]);
        removeCookie('ltm_gmv_from', { path: '/' });
        removeCookie('ltm_gmv_to', { path: '/' });
        removeCookie('ebitda_from', { path: '/' })
        removeCookie('ebitda_to', { path: '/' });
        removeCookie('ebitda_margin', { path: '/' });
        removeCookie('yoy_growth_from', { path: '/' });
        removeCookie('filterTags', { path: '/' });
        removeCookie('filterCategories', { path: '/' });
        setTableLoading(true);
        setReloadCount(reloadCount + 1);
    }

    const searchListingNumber = (e) => {
        const query = e.target.value;
        setTableLoading(true);
        setListingNumber(query);
        setCookies('listing_number', query, { path: '/' });
        axios.get(process.env.REACT_APP_API_ENDPOINT + 'user/'+currentUser+'/watchlists?listing_number=' + query + '&sort=' + sortSearch+'&ltm_gmv_from='+filterData.ltm_gmv_from+'&ltm_gmv_from='+filterData.ltm_gmv_to+'&ebitda_from='+filterData.ebitda_from+'&ebitda_to='+filterData.ebitda_to+'&ebitda_margin='+filterData.ebitda_margin+'&yoy_growth_from='+filterData.yoy_growth_from+'&tags='+filterData.tags+'&categories='+filterData.categories+'&user_id='+currentUser)

            .then((response) => {
                const data = response.data;
                const result = data.data;
                if (result) {
                    setMarketData(result);
                    setTableLoading(false);
                }
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTableLoading(false);
                
            });
    }

    const addToWatchlist = (e) => {
        setFormStatus('loading');
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_ENDPOINT + 'watchlist',
            data: ({
                listing_id: e,
                user_id: currentUser
            })
        }).then(function (response) {
            if (response.data.status == 'Success') {
                toast.success('Listing added to watchlist!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setFormStatus('standby');
                // navigate('/');
            } else {
                toast.error('Adding to watchlist failed!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setFormStatus('standby');
            }
        }).catch(function (error) {
            toast.error('There has beeen an error with the server, please try again later!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setFormStatus('standby');
        })


    };

    function handleKeyDown(e) {
        if (e.key !== 'Enter') return
        const value = e.target.value
        if (!value.trim()) return
        if (tagsFilter) {
            if (tagsFilter.length > 0) {
                setTagsFilter([...tagsFilter, value]);
                setFilterData({
                    ...filterData,
                    tags: [...tagsFilter, value],
                });
                setCookies('filterTags', [...tagsFilter, value], { path: '/' });
            } else {
                setTagsFilter([value])
                setFilterData({
                    ...filterData,
                    tags: [value],
                });
                setCookies('filterTags', [value], { path: '/' });
            }
        } else {
            setTagsFilter([value]);
            setFilterData({
                ...filterData,
                tags: [value],
            });
            setCookies('filterTags', [value], { path: '/' });
        }
        e.target.value = ''
    }

    const searchFilter = (e) => {
        setTableLoading(true);
        setFilterShow(false);
        axios.get(process.env.REACT_APP_API_ENDPOINT + 'user/'+currentUser+'/watchlists?listing_number=' + listingNumber + '&sort=' + sortSearch+'&ltm_gmv_from='+filterData.ltm_gmv_from+'&ltm_gmv_from='+filterData.ltm_gmv_to+'&ebitda_from='+filterData.ebitda_from+'&ebitda_to='+filterData.ebitda_to+'&ebitda_margin='+filterData.ebitda_margin+'&yoy_growth_from='+filterData.yoy_growth_from+'&tags='+filterData.tags+'&categories='+filterData.categories+'&user_id='+currentUser)
            .then((response) => {
                const data = response.data;
                const result = data.data;
                if (result) {
                    setMarketData(result);
                    setTableLoading(false);
                }
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTableLoading(false);
            });
    }

    useEffect(() => {
        // This is the axios GET
        const listing_number_cookie = listing_number ? listing_number : "";
        const sorting_cookie = sorting ? sorting : "";
        const ltm_gmv_from_cookie = ltm_gmv_from ? ltm_gmv_from : "";
        const ltm_gmv_to_cookie = ltm_gmv_to ? ltm_gmv_to : "";
        const ebitda_from_cookie = ebitda_from ? ebitda_from : "";
        const ebitda_to_cookie = ebitda_to ? ebitda_to : "";
        const ebitda_margin_cookie = ebitda_margin ? ebitda_margin : "";
        const yoy_growth_from_cookie = yoy_growth_from ? yoy_growth_from : "";
        const filterTagsArrayCookie = filterTags ? filterTags : [];
        const filterTagsCookie = filterTags ? filterTags : "";
        const filterCategoriesArrayCookie = filterCategories ? filterCategories : [];
        const filterCategoriesCookie = filterCategories ? filterCategories : "";

        setFilterData({
            ...filterData,
            ltm_gmv_from: ltm_gmv_from_cookie,
            ltm_gmv_to: ltm_gmv_to_cookie,
            ebitda_from: ebitda_from_cookie,
            ebitda_to: ebitda_to_cookie,
            ebitda_margin: ebitda_margin_cookie,
            yoy_growth_from: yoy_growth_from_cookie,
            tags: filterTagsArrayCookie,
            categories: filterCategoriesArrayCookie
        });
        setTagsFilter(filterTagsArrayCookie);
        setTagIds(filterCategoriesArrayCookie);
        setListingNumber(listing_number_cookie);
        setSortSearch(sorting_cookie);

        if (tagsDataLength < 1) {
            axios.get(process.env.REACT_APP_API_ENDPOINT + 'tag')
                .then((response) => {
                    const data = response.data;
                    const result = data.data;
                    // console.log("Result Data Tags", result);
                    if (result) {
                        setTagsData(result);
                        setTagsDataLength(result.length);
                        setTagsReady(true);
                    } else {
                        setTagsReady(false);
                    }
                }).catch(error => {
                    // setError(error);
                    toast.error('An error occured. Please try again or contact the administrator.', {
                        position: 'top-right',
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTagsReady(false);
                });
        }

        axios.get(process.env.REACT_APP_API_ENDPOINT + 'user/'+currentUser+'/watchlists?user_id='+currentUser)
        // axios.get(process.env.REACT_APP_API_ENDPOINT + 'user/'+currentUser+'/watchlists?listing_number=' + listing_number_cookie + '&sort=' +sorting_cookie+'&ltm_gmv_from='+ltm_gmv_from_cookie+'&ltm_gmv_to='+ltm_gmv_to_cookie+'&ebitda_from='+ebitda_from_cookie+'&ebitda_to='+ebitda_to_cookie+'&ebitda_margin='+ebitda_margin_cookie+'&yoy_growth_from='+yoy_growth_from_cookie+'&tags='+filterTagsCookie+'&categories='+filterCategoriesCookie+'&user_id='+currentUser)
            .then((response) => {
                const data = response.data;
                const result = data.data;
                if (result) {
                    setMarketData(result);
                    setTableLoading(false);
                }
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTableLoading(false);
            });
    }, [reloadCount]);

    return (
        <section id='marketing'>
            <ToastContainer style={{ width: "370px" }} />
            <Container fluid>
                <Row>
                    <Col lg='2'>
                        {/* <Sidebar /> */}
                        {isSidebar || windowDimension.winWidth > 1024 ?
                            <Sidebar clickSidebar={getSidebar} />
                            :
                            null
                        }
                        <button onClick={getSidebar} className={isSidebar ? "hidden" : "hamburger-btn show"}><GiHamburgerMenu /></button>
                    </Col>

                    <Col lg='10' className='right-container'>
                        <Row className='top-utility'>
                            <Col lg='8' className="mb-0">
                                <h2 className="ss-header mb-0">Watchlist</h2>
                                <div className="divider"></div>
                            </Col>
                        </Row>

                        {tableLoading ?
                            <>
                                <Card className='market-card'>
                                    <CardBody className='market-card-body'>
                                        <div className="text-center"><PageSpinner /></div>
                                    </CardBody>
                                </Card>
                            </>
                            :
                            <>
                                {marketData.length > 0 ?
                                    <>
                                        {marketData.map(({ id, listing_number, listing_price, brand_name, featured_image, ltm_gmv, ebitda, tags, business_started, monthly_revenue, monetization, created_at, monthly_net_profit, pricing_period, multiple, listing_summary, ebitda_margin, business_created, asking_price, listing }, index) => (
                                            <Card className='market-card' key={index}>
                                                <CardBody className='market-card-body'>
                                                    <Row className='card-align-items'>
                                                        <Col lg='3'>
                                                            <div className='relative'>
                                                                {listing.featured_image ?
                                                                    <img src={process.env.REACT_APP_URL + "/storage/gallery/"+listing.featured_image} className='image-preview' />
                                                                    :
                                                                    <img src={NoImageAvail} className='no-image-preview' />
                                                                }

                                                            </div>
                                                        </Col>
                                                        <Col lg='3' className='list-container'>
                                                            <p className='serial mb-0'>#{listing.listing_number ? listing.listing_number : "-"}</p>
                                                            <div className="list-tags">
                                                            {listing.tags ?
                                                                <>
                                                                    {listing.tags.length ?
                                                                        <>
                                                                            {listing.tags.map(({ id, name }, tagsIndex) => (
                                                                                <span className='list-title' key={tagsIndex}>
                                                                                    {name}
                                                                                    {listing.tags.length > tagsIndex + 1 && tagsIndex < listing.tags.length ?
                                                                                        ","
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </span>
                                                                            ))}
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                </>
                                                                :
                                                                null
                                                            }
                                                            </div>
                                                            {userRole != "Admin" ?
                                                                <p className="brand"></p>
                                                                :
                                                                <p className="brand">{listing.brand_name}</p>
                                                            }
                                                            <p className="price-title mb-0">Price</p>
                                                            <p className='list-price'>${listing.asking_price ? listing.asking_price : "0"}</p>
                                                            {/* <button className="button edit-button w-100 mb-3" onClick={() => navigate('/admin/marketplace/update?marketplace_id=' + id)}>EDIT</button> */}
                                                        </Col>
                                                        <Col lg='6'>
                                                            <Row>
                                                                <Col lg='12' className="button-listing-row">
                                                                    {userRole != "Admin" ?
                                                                        null
                                                                        :
                                                                        <div className="ssp-tooltip mb-3 me-2">
                                                                            <button className="button-outline px-3 watch-button" onClick={() => navigate('/admin/listing/update?listing_id=' + id)}><AiFillEdit fontSize="25"/></button>
                                                                            <span className="ssp-tooltiptext">EDIT LISTING</span>
                                                                        </div>
                                                                        // <button className="button view-button  w-100 mb-3" onClick={() => navigate('/admin/listing/update?listing_id=' + id)}>EDIT</button>
                                                                    }
                                                                {/* </Col>
                                                                <Col lg='6'> */}
                                                                    <div className="ssp-tooltip mb-3">
                                                                        <button className='button px-3 view-button' onClick={() => navigate('/listing/view?listing_id=' + id)}><AiFillEye fontSize="25"/></button>
                                                                        <span className="ssp-tooltiptext">VIEW LISTING</span>
                                                                    </div>
                                                                    {/* <button className='button view-button w-100 mb-3' onClick={() => navigate('/listing/view?listing_id=' + id)}>VIEW LISTING</button> */}
                                                                </Col>
                                                                <Col lg='6'>
                                                                    <div className="data-container">
                                                                        <p className='list-sub-title'>LTM GMV</p>
                                                                        <p className='data-value mb-0'>{listing.ltm_gmv ? listing.ltm_gmv : "-"}</p>
                                                                    </div>
                                                                </Col>
                                                                <Col lg='6'>
                                                                    <div className="data-container">
                                                                        <p className='list-sub-title'>LTM EBITDA</p>
                                                                        <p className='data-value mb-0'>{listing.ebitda ? listing.ebitda : "-"}</p>
                                                                    </div>
                                                                </Col>
                                                                <Col lg='6'>
                                                                    <div className="data-container mt-3">
                                                                        <p className='list-sub-title'>EBITDA MARGIN</p>
                                                                        <p className='data-value mb-0'>{listing.ebitda_margin ? listing.ebitda_margin : "-"}</p>
                                                                    </div>
                                                                </Col>
                                                                {listing.business_created ?
                                                                    <Col lg='6'>
                                                                        <div className="data-container mt-3">
                                                                            <p className='list-sub-title'>Year Created</p>
                                                                            <p className='data-value mb-0'>{new Date(listing.business_created).toLocaleDateString('en-US', { year: 'numeric' })}</p>
                                                                        </div>
                                                                    </Col>
                                                                    :
                                                                    null
                                                                }
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                </CardBody>
                                            </Card>
                                        ))}
                                    </>
                                    :
                                    <>
                                        <Card className='market-card'>
                                            <CardBody className='market-card-body'>
                                                <div className="text-center">No record found</div>
                                            </CardBody>
                                        </Card>
                                    </>
                                }
                            </>
                        }
                    </Col>
                </Row>
            </Container>
            <Modal
                isOpen={filterShow}
                centered
                fade
                id="modal"
                style={{ maxWidth: '800px' }}
            >
                <button type="button" className="react-modal-close" data-dismiss="modal" aria-label="Close" onClick={toggleFilterShow}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <ModalHeader>
                    FILTERS
                </ModalHeader>
                <ModalBody >
                    <>
                        <Card className='mb-3'>
                            <CardBody>
                                <Row>
                                    <Col lg={12}>
                                        <div className="data-container">
                                            <Row>
                                                <Col lg={8}>
                                                    <div className="data-value">
                                                        <p className='text-left fw-bold'>LTM GMV</p>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="d-flex">
                                                        <Input type='number' className='me-3' placeholder='From' name='ltm_gmv_from' value={filterData.ltm_gmv_from} onChange={handleChangeFilter} />
                                                        <Input type='number' className='me-3' placeholder='To' name='ltm_gmv_to' value={filterData.ltm_gmv_to} onChange={handleChangeFilter} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='mb-3'>
                            <CardBody>
                                <Row>
                                    <Col lg={12}>
                                        <div className="data-container">
                                            <Row>
                                                <Col lg={8}>
                                                    <div className="data-value">
                                                        <p className='text-left fw-bold'>EBITDA</p>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="d-flex">

                                                        <Input className='me-3' type='number' placeholder='From' name='ebitda_from' value={filterData.ebitda_from} onChange={handleChangeFilter} />

                                                        <Input className='me-3' type='number' placeholder='To' name='ebitda_to' value={filterData.ebitda_to} onChange={handleChangeFilter} />
                                                    </div>

                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='mb-3'>
                            <CardBody>
                                <Row>
                                    <Col lg={12}>
                                        <Row>
                                            <Col lg={8}>
                                                <div className="data-value">
                                                    <p className='text-left fw-bold'> EBITDA Margin</p>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="d-flex">
                                                    <Input type='number' className='me-3' placeholder='From' name='ebitda_margin' value={filterData.ebitda_margin} onChange={handleChangeFilter} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                        <Card className='mb-3'>
                            <CardBody>
                                <Row>
                                    <Col lg={8}>
                                        <div className="data-value">
                                            <p className="text-left fw-bold">Year on Year Growth</p>
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="d-flex">
                                            <Input className='me-3' type='number' placeholder='From (Number of Years)' name='yoy_growth_from' value={filterData.yoy_growth_from} onChange={handleChangeFilter} />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col lg={6}>
                                <Card>
                                    <CardBody className='mb-6'>
                                        <div className="data-value">
                                            <p className="text-left fw-bold">Keywords</p>
                                            <div className="data-value d-flex">
                                                <div className="tags-input-container">
                                                    {tagsFilter ?
                                                        <>
                                                            {tagsFilter.length > 0 ?
                                                                <>
                                                                    {tagsFilter.map((tagFilter, index) => (
                                                                        <div className="tag-item" key={index}>
                                                                            <span className="text">{tagFilter}</span>
                                                                            <span className="close" onClick={() => removeTag(index)}>&times;</span>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                                :
                                                                null
                                                            }
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    <input type="text" onKeyDown={handleKeyDown} className="tags-input" placeholder="Type keywords here..." />
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card>
                                    <CardBody className='mb-6'>
                                        <div className="data-container">
                                            <p className='text-left fw-bold'>Categories</p>
                                            <div className="data-value d-flex">
                                                {tagsListShow ?
                                                    <button type="button" className="button-tags" onClick={toggleHideTags}>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <span className="">
                                                                {tagsData ?
                                                                    <>
                                                                        {tagsData.length > 0 ?
                                                                            <>
                                                                                {tagsData.map(({ id, name }, index) => (
                                                                                    <>
                                                                                        {tagIds.indexOf(id) > -1 ?
                                                                                            <>
                                                                                                {name}{index + 1 < tagsData.length ? ", " : null}
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                    </>
                                                                                ))}
                                                                            </>
                                                                            :
                                                                            null
                                                                        }
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                                {tagIds.length > 0 ?
                                                                    null
                                                                    :
                                                                    <>Choose categories here...</>
                                                                }
                                                            </span>
                                                            <IoIosArrowUp />
                                                        </div>
                                                    </button>
                                                    :
                                                    <button type="button" className="button-tags" onClick={toggleShowTags}>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <span className="">
                                                                {tagsData ?
                                                                    <>
                                                                        {tagsData.length > 0 ?
                                                                            <>
                                                                                {tagsData.map(({ id, name }, index) => (
                                                                                    <>
                                                                                        {tagIds.indexOf(id) > -1 ?
                                                                                            <>
                                                                                                {name}{index + 1 < tagsData.length ? ", " : null}
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                    </>
                                                                                ))}
                                                                            </>
                                                                            :
                                                                            null
                                                                        }
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                                {tagIds.length > 0 ?
                                                                    null
                                                                    :
                                                                    <>Choose categories here...</>
                                                                }
                                                            </span>
                                                            <IoIosArrowDown />
                                                        </div>
                                                    </button>
                                                }
                                            </div>
                                            <Card className={tagsBoxStyle}>
                                                <CardBody>
                                                    <Row>
                                                        {tagsData ?
                                                            <>
                                                                {tagsData.length > 0 ?
                                                                    <>
                                                                        {tagsData.map(({ id, name }, index) => (
                                                                            <Col lg="12" key={index}>
                                                                                {tagIds.indexOf(id) > -1 ?
                                                                                    <p className='mb-0'><input type="checkbox" className='me-2' value={id} onChange={function (event) { handleChangeUnchecked(id); }} defaultChecked />{name}</p>
                                                                                    :
                                                                                    <p className='mb-0'><input type="checkbox" className='me-2' value={id} onChange={function (event) { handleChangeCheck(id); }} />{name}</p>
                                                                                }
                                                                            </Col>
                                                                        ))}
                                                                    </>
                                                                    :
                                                                    <div className='text-center'><PageSpinner /></div>
                                                                }
                                                            </>
                                                            :
                                                            <p>No tags available!</p>
                                                        }
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                </ModalBody>
                <ModalFooter className='text-end'>
                    <button className='button-outline cancel-btn me-2' id="login-btn" onClick={clearFilter}>CLEAR</button>
                    <button className='button view-button' id="login-btn" onClick={searchFilter}>SAVE</button>
                </ModalFooter>
            </Modal>
        </section>
    )
}

export default WatchlistPage;