import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import EmptyLayout from 'layout/EmptyLayout';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Input, Label, Form } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import validator from 'validator';
import Sidebar from 'components/Sidebar';
import SellerSuccessLogo from '../assets/seller-success-logo.png';

const initialData = Object.freeze({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
});

const RegistrationPage = (props) => {
    // page content
    const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn', 'userRole']);
    const currentUser = cookies.currentUser;
    const isLoggedIn = cookies.isLoggedIn;
    const location = useLocation()
    var currentPathName = location.pathname;
    const [register, setRegister] = useState(initialData);
    const [formStatus, setFormStatus] = useState('standby');

    let navigate = useNavigate();

    const handleRegister = (e) => {
        setRegister({
            ...register,
            [e.target.name]: e.target.value
        });
       
    }

    const preventDefault = (e) => {
        e.preventDefault();
    }

    // if(currentUser) {
    //     window.location.href = "/"
    // } else {
    //     window.location.href = "/register"
    // }
    
        
    const sumbitRegister = (e) => {
        e.preventDefault();
        // if (register.password != register.password_confirmation) {
            if (validator.isEmail(register.email)) {
                if (register.email != "" && register.password != "") {
                    setFormStatus('loading');
                    axios({
                        url: process.env.REACT_APP_API_ENDPOINT + 'register',
                        method: 'post',
                        // headers: {
                        //     'Content-Type': 'application/json',
                        //     "Access-Control-Allow-Origin": "*",
                        //     "Access-Control-Allow-Credentials": true,
                        //     "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                        //     "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
                        // },
                        data: register
                    })

                        .then(function (response) {
                            if (response.data.status == "Success") {
                                toast.success('Your account has been successfully created.', {
                                    position: "top-right",
                                    autoClose: 1500,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                // console.log("login", response)
                                // alert("Moving to Landing Page")
                                setCookies('currentUser', JSON.stringify(response.data.data.id), { path: '/' });
                                setCookies('userRole', response.data.data.user.role, { path: '/' });
                                setCookies('isLoggedIn', true, { path: '/' });
                                navigate("/");
                                    
                                // console.log(response);
                            } else {
                                toast.warning('Email address is already in use!', {
                                    position: "top-right",
                                    autoClose: 1500,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            }
                        })
                        .catch(function (error) {
                            toast.error('Something went wrong!', {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            console.log(error);
                        });
                } else {
                    toast.warning('Please fill the required fields!', {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
        } else {
            toast.error("Please enter a valid email address", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    // } else {
    //     toast.warning('Password do not match!', {
    //         position: "top-right",
    //         autoClose: 1500,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //     });
    // }
    }

    useEffect(() => {

        if(isLoggedIn) {
          navigate("/");
        } else {
          navigate("/registration");
        }
        
      }, []);

    // useEffect(() => {

    //     if (isLoggedIn) {
          
    //       if(currentPathName == "/users" || currentPathName == "/register") {
    
    //       } else {
    //         navigate("/");
    //       }
    //     } else {
    //       navigate("/login");
    //       navigate("/registration");
    //     }
    
    //   }, []);

    return (
        <section id="registration">
            <EmptyLayout>
                <ToastContainer style={{ width: "370px" }} />
                <Container className='login-page-container'>
                    <Row>
                        <Col lg="6">
                            <div className="logo-container-login">
                                <img src={SellerSuccessLogo} className="login-ss-logo"/>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="login-div">
                            <img src={SellerSuccessLogo} className="login-ss-logo-mobile"/>
                                <Card className="registration-card">
                                    <CardBody>
                                        <div className='register-inner'>
                                        <h2>Register</h2>
                                            <Form>
                                                <Row>
                                                    <Col lg="12">
                                                        <p className="input-head-text mb-1">First Name</p>
                                                        <Input name="first_name" className="mb-3 register-input" maxLength={255} onChange={handleRegister} />
                                                    </Col>
                                                    <Col lg="12">
                                                        <p className="input-head-text mb-1">Last Name</p>
                                                        <Input name="last_name" className="mb-3 register-input" maxLength={255} onChange={handleRegister} />
                                                    </Col>
                                                    <Col lg="12">
                                                        <p className="input-head-text mb-1">Email</p>
                                                        <Input name="email" className="mb-3 register-input" maxLength={255} onChange={handleRegister} />
                                                    </Col>
                                                    <Col lg="12">
                                                        <p className="input-head-text mb-1">Password</p>
                                                        <Input name="password" type="password" maxLength={255} className="mb-3 register-input" onChange={handleRegister} />
                                                    </Col>
                                                    <Col lg="12">
                                                        <p className="input-head-text mb-1">Confirm Password</p>
                                                        <Input name="password_confirmation" type="password" maxLength={255} className="register-input" onChange={handleRegister} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12" className='text-center'>
                                                        {formStatus != "standby" ?
                                                            <button className='button view-button-login' onClick={preventDefault}>REGISTERING...</button>
                                                        :
                                                            <button type='submit' className='button view-button-login' onClick={sumbitRegister}>REGISTER</button>
                                                        }
                                                        <p>Already have an Account? <a href="/login">Login</a></p>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </EmptyLayout>
        </section>
    )
}

export default RegistrationPage