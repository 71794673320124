import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory, useNavigate } from 'react-router-dom'
import EmptyLayout from 'layout/EmptyLayout';
import {
    Container, Button, CardFooter, Input, CardBody, Label, ModalHeader, ModalBody, ModalFooter, Card, Col, Modal, Table, Row, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsSearch, BsThreeDotsVertical } from 'react-icons/bs';
import { FiFilter } from 'react-icons/fi';
import { BiSort } from 'react-icons/bi';
import { AiOutlinePlus } from 'react-icons/ai'
import { HiArrowLeft } from 'react-icons/hi'
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'
import Sport from '../assets/Sports.png';
import home from '../assets/home-hobbies-img.png';
import excercise from '../assets/excercise.png';
import Sidebar from 'components/Sidebar';
import { Spinner } from 'reactstrap';
import axios from 'axios';
import PageSpinner from 'components/PageSpinner';

const initialFormData = Object.freeze({
    serial_number: '',
    name: '',
    price: '',
    monthly_net_profit: '',
    monthly_multiple: '',
    monthly_revenue: '',
    business_Date_created: '',
    monetization: '',
    profit: '',
    revenue: '',
    traffic: ''
});

const AdminMarketPlaceDashboardPage = (props) => {
    const [marketData, setMarketData] = useState([]);
    const [formData, setFormData] = useState(initialFormData);
    const [error, setError] = useState('');
    const [actionCard, setActionCard] = useState(false)

    // page content
    const navigate = useNavigate();

    const toggleEditView = () => {
        setActionCard(!actionCard);
    };

    useEffect(() => {
        // This is the axios GET
        axios.get(process.env.REACT_APP_API_ENDPOINT + 'listing').then((response) => {
            const data = response.data;
            const result = data.data;
            setMarketData(result);
        }).catch(error => {
            // setError(error);
            toast.error('An error occured. Please try again or contact the administrator.', {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }, []);


    return (
        <section id='marketing'>
            <ToastContainer style={{ width: "370px" }} />
            <Container fluid>
                <Row>
                    <Col lg='2'>
                        <Sidebar />
                    </Col>
                    <Col lg='10' className='right-container'>
                        <Row className='utility'>
                            <Col lg='8'>
                                <h2 className='header'>ALL LISTING TABLE</h2>
                            </Col>
                            <Col lg='4' className='text-end'>
                                <button className='button view-button me-2' onClick={() => navigate('/admin/marketplace/create')}><AiOutlinePlus className='me-1' />ADD</button>
                            <button className='button back view-button' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button>
                            </Col>
                        </Row>
                        <Table striped>
                            <thead>
                                <th>ID</th>
                                <th>Tags</th>
                                <th>Listing Price</th>
                                <th></th>
                            </thead>
                            <tbody>
                                {marketData ?
                                    <>
                                        {marketData.length > 0 ?
                                            <>
                                                {marketData.map(({ id, code, tags, listing_price }, index) => (
                                                    <tr key={index}>
                                                        <td>{id}</td>
                                                        <td className='d-flex'>{tags.map(({ name }, index) => (
                                                            <div key={index}>
                                                                {name}
                                                                {marketData.length > index + 1 ?
                                                                    ", "
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        ))}
                                                        </td>
                                                        <td>${listing_price}</td>
                                                        <td>
                                                            <div className="action_table">
                                                                <BsThreeDotsVertical />
                                                                <Card className="action_content">
                                                                    <CardBody className="action_container">
                                                                        <div className="link_container"
                                                                            onClick={() => navigate('/admin/marketplace/update?market_id=' + id)}
                                                                        >
                                                                            <a href="javascript:void(0)">EDIT</a>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                            :
                                            <tr><td className='text-center' colSpan='4'>No record found</td></tr>
                                        }
                                    </>
                                    :
                                    <tr><td className='text-center' colSpan='4'><PageSpinner /></td></tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AdminMarketPlaceDashboardPage;