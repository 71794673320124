import React, { useState, useEffect } from 'react';
// import 'index.css';
import { Container, Spinner } from "react-bootstrap";
import { BrowserRouter, Redirect, Route, Switch, Routes } from 'react-router-dom';

// Layout
import Layout from "layout/Layout";
import EmptyLayout from 'layout/EmptyLayout';
import LayoutRoute from 'layout/LayoutRoute';
import MainLayout from 'layout/MainLayout';

// pages
// import Home from "pages/Home";
import About from "pages/About";
import NotFound from "pages/NotFound";
import LoginPage from "pages/LoginPage";
import ListingsPage from 'pages/ListingsPage';
import ListingSinglePage from 'pages/ListingSinglePage';
import AdminCreateListingPage from 'pages/AdminCreateListingPage';
import AdminMarketPlaceDashboardPage from 'pages/AdminMarketPlaceDashboardPage';
import AdminTagsCreatePage from 'pages/AdminTagsCreatePage';
import AdminEditListingPage from 'pages/AdminEditListingPage';
import RegistrationPage from 'pages/RegistrationPage';
import AdminUsersPage from 'pages/AdminUsersPage';
import AdminUserProfilePage from 'pages/AdminUserProfilePage';
import AdminInquiriesPage from 'pages/AdminInquiriesPage';
import UserProfilePage from 'pages/UserProfilePage';
import UserSupportPage from 'pages/UserSupportPage';
import AdminLoginPage from 'pages/AdminLoginPage';
import UserNotificationsPage from 'pages/UserNotificationsPage';
import ProfileEditPage from 'pages/ProfileEditPage';
import AdminMessagesPage from 'pages/AdminMessagesPage';
import WatchlistPage from 'pages/WatchlistPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

const App = () => {
  return (
    <MainLayout>
      <Routes>
        <Route path='*' element={<NotFound />} />
        <Route path="/about" element={<About />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/" element={<ListingsPage />} />
        <Route path="/admin/listing/create" element={<AdminCreateListingPage />} />
        <Route path="/listing/view" element={<ListingSinglePage />} />
        <Route path="/listing" element={<AdminMarketPlaceDashboardPage />} />
        <Route path="/watchlist" element={<WatchlistPage />} />
        <Route path="/admin/tag/create" element={<AdminTagsCreatePage />} />
        <Route path="/admin/listing/update" element={<AdminEditListingPage />} />
        <Route path="/admin/users" element={<AdminUsersPage />} />
        <Route path="/admin/user" element={<AdminUserProfilePage />} />
        <Route path="/admin/inquiries" element={<AdminInquiriesPage />} />
        <Route path="/profile" element={<UserProfilePage />} />
        <Route path="/support" element={<UserSupportPage />} />
        <Route path="/admin/messages" element={<AdminMessagesPage />} />
        <Route path="/admin/login" element={<AdminLoginPage />} />
        <Route path="/notifications" element={<UserNotificationsPage />} />
        <Route path="/profile/update" element={<ProfileEditPage />} />
        <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
        {/* <Route element={<NotFound />} /> */}
      </Routes>
    </MainLayout>
  );
};

export default App;
