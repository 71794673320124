import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory, useNavigate } from 'react-router-dom'
import EmptyLayout from 'layout/EmptyLayout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Container, Button, CardFooter, Input, CardBody, Label, ModalHeader, ModalBody, ModalFooter, Card, CardHeader, Col, Modal, Table, Row, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { BsSearch, BsFillPlusCircleFill } from 'react-icons/bs';
import { FiFilter } from 'react-icons/fi';
import { BiSort } from 'react-icons/bi';
import { FaTimes } from 'react-icons/fa'
import { HiArrowLeft } from 'react-icons/hi'
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'
import { CiCircleRemove } from 'react-icons/ci'
import Sport from '../assets/Sports.png';
import home from '../assets/home-hobbies-img.png';
import excercise from '../assets/excercise.png';
import Sidebar from 'components/Sidebar';
import { Spinner } from 'reactstrap';
import axios from 'axios';
import DownloadIcon from "../assets/download-icon.png";
import PageSpinner from 'components/PageSpinner.jsx';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { GiConsoleController, GiHamburgerMenu } from 'react-icons/gi';
import Moment from 'moment';
import { AiFillFileExcel } from 'react-icons/ai';

const initialFormData = Object.freeze({
    'tag_ids': '',
    'featured_image': '',
    'store_url': '',
    'store_name': '',
    'brand_name': '',
    'brand_amazon': '',
    'brand_vendor_central': '',
    'web_stores': '',
    'business_created': '',
    'platform_sales': '',
    'country_sales': '',
    'trademark': '',
    // 'sm_accounts': '',
    'facebook_url': '',
    'twitter_url': '',
    'instagram_url': '',
    'linkedin_url': '',
    'business_growth': '',
    'revenue': '',
    'growth_rate_revenue': '',
    'asin_sales': '',
    'gross_profit_margin': '',
    'ebitda_margin': '',
    'gross_profit': '',
    'ltm_ebitda': '',
    'ltm_gmv': '',
    'ebitda': '',
    'return_rate': '',
    'product_sales_price': '',
    'inventory_quantity': '',
    'expenses': '',
    'reason_category': '',
    'certification_requirements': '',
    'amazon_brand_registry': '',
    'amazon_accelerator': '',
    'self_developed_product': '',
    'product_development_speed': '',
    'product_development_plan': '',
    'category_competition': '',
    'competitor_brands': '',
    'core_quality_issues': '',
    'office_address': '',
    'warehouse_address': '',
    'number_of_employees': '',
    'customer_service_process': '',
    'refund_process': '',
    'ppc_revenue_cost': '',
    'ppc_advertising_cost': '',
    'ppc_advertising_changes': '',
    'asin_ranking': '',
    'marketing_agency': '',
    'advertising': '',
    'marketing_expenses': '',
    'giveaways': '',
    'account_violations': '',
    'asin_violations': '',
    'growth_opportunities': '',
    'development_plan': '',
    'suppliers': '',
    'supplier_location': '',
    'supplier_proportion': '',
    'supplier_account_period': '',
    'supplier_length_of_cooperation': '',
    'supplier_contracts': '',
    'supplier_minimum_order': '',
    'supplier_share_control': '',
    'supplier_exclusive_contract': '',
    'supplier_selection_criteria': '',
    'quality_control': '',
    'stock_out_occurence': '',
    'reason_for_stockout': '',
    'ipi_score': '',
    'logistic_providers': '',
    'sea_air_freight_proportion': '',
    'domestic_warehouse_3rd_party': '',
    'overseas_warehouse_3rd_party': '',
    'proportion_of_goods': '',
    'fbm': '',
    'proportion_of_fbm': '',
    'reason_for_sale': '',
    'valued_transaction_process': '',
    'asking_price': '',
    'deal_closure': '',
    'upfront_payment': '',
    'vps_login_access': '',
});

const initialAmazonIncomeFormData = Object.freeze({
    'amazon_income': '',
});

const initialAmazonCogsFormData = Object.freeze({
    'amazon_cogs': '',
});

const initialAmazonExpensesFormData = Object.freeze({
    'amazon_expenses': '',
});

const initialEbitdaFormData = Object.freeze({
    'ebitda_chart': '',
});

export function InfoBox(props) {
    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          onClickOutside && onClickOutside();
        }
      };
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, [onClickOutside]);

    if (!props.show)
      return null;

    return (
      <div ref={ref} className='card border-0 m-0'>
        {props.children}
      </div>);
  }

const AdminMarketPlaceEditPage = (props) => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    let query = useQuery();
    const listing_id = query.get('listing_id');
    const [marketData, setMarketData] = useState([]);
    const [formData, setFormData] = useState(initialFormData);
    const [formStatus, setFormStatus] = useState('standby');
    const [draftFormStatus, setdraftFormStatus] = useState('standby');
    const [error, setError] = useState('');
    const [imageUpload, setImageUpload] = useState([]);
    const [preview, setPreview] = useState();
    const [fileLimit, setFileLimit] = useState(false);
    const [tagsData, setTagsData] = useState([]);
    const [tagIds, setTagIds] = useState([]);
    const [tagsListShow, setTagsListShow] = useState(false);
    const [checkBoxId, setCheckBoxId] = useState();
    const [tagsBoxStyle, setTagsBoxStyle] = useState('card-show-tags-hidden');
    const [isSidebar, setIsSidebar] = useState(false);
    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
    })

    const [advertising, setAdvertising] = useState(false);
    const [accountViolation, setAccountViolation] = useState(false);
    const [asinViolation, setAsinViolation] = useState(false);
    const [exclusive, setExlusive] = useState(false);
    const [fbm, setFbm] = useState(false);
    const [ppc, setPpc] = useState(false);
    const [stockout, setStockout] = useState(false);

    const [amazonIncomeFormData, setAmazonIncomeFormData] = useState(initialAmazonIncomeFormData);
    const [amazonCogsFormData, setAmazonCogsFormData] = useState(initialAmazonCogsFormData);
    const [amazonExpensesFormData, setAmazonExpensesFormData] = useState(initialAmazonExpensesFormData);
    const [ebitdaFormData, setEbitdaFormData] = useState(initialEbitdaFormData);

    const [uploadStatus, setUploadStatus] = useState('standby');

    const [amazonIncomeId, setAmazonIncomeId] = useState([]);
    const [amazonCogsId, setAmazonCogsId] = useState([]);
    const [amazonExpensesId, setAmazonExpensesId] = useState([]);
    const [ebitdaId, setEbitdaId] = useState([]);

    // const [fileData, setFileData] = useState();
    const [amazonIncomefileName, setAmazonIncomeFileName] = useState();
    const [amazonCogsfileName, setAmazonCogsfileName] = useState();
    const [amazonExpensesFileName, setAmazonExpensesFileName] = useState();
    const [ebitdaFileName, setEbitdaFileName]  = useState();

    const handleChangeAmazonIncome = (event) => {
        var data_file = event.target.files[0];
        submitAmazonIncome(event.target.files[0]);
        setAmazonIncomeFormData({
            ...amazonIncomeFormData,
            amazon_income: amazonIncomeId
        });
        setAmazonIncomeFileName(data_file.name);
    }

    const submitAmazonIncome = (event) => {
        // event.preventDefault();
            const dataArray = new FormData();
            dataArray.append("file", event);
            axios.post(process.env.REACT_APP_API_ENDPOINT + "upload-file", dataArray, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                // successfully uploaded response
                //console.log(response.data);
                if (response.data.status == "Success") {
                    var media_id = response.data.data.id;
                    setAmazonIncomeId(amazonIncomeId => [...amazonIncomeId, media_id]);
                }
            })
            .catch((error) => {
                // error response
                console.log(error);
                toast.error('There has been an error with the server, please try again later!', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setFormStatus("standby");
            });
    };

    const handleChangeAmazonCogs = (event) => {
        var data_file = event.target.files[0];
        submitAmazonCogs(event.target.files[0]);
        setAmazonCogsFormData({
            ...amazonCogsFormData,
            amazon_cogs: amazonCogsId
        });
        setAmazonCogsfileName(data_file.name);
    }

    const submitAmazonCogs = (event) => {
        // event.preventDefault();
            const dataArray = new FormData();
            dataArray.append("file", event);
            axios.post(process.env.REACT_APP_API_ENDPOINT + "upload-file", dataArray, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                // successfully uploaded response
                //console.log(response.data);
                if (response.data.status == "Success") {
                    var media_id = response.data.data.id;
                    setAmazonCogsId(amazonCogsId => [...amazonCogsId, media_id]);
                }
            })
            .catch((error) => {
                // error response
                console.log(error);
                toast.error('There has been an error with the server, please try again later!', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setFormStatus("standby");
            });
    };

    const handleChangeAmazonExpenses = (event) => {
        var data_file = event.target.files[0];
        submitAmazonExpenses(event.target.files[0]);
        setAmazonExpensesFormData({
            ...amazonExpensesFormData,
            amazon_expenses: amazonExpensesId
        });
        setAmazonExpensesFileName(data_file.name);
    }

    const submitAmazonExpenses = (event) => {
        // event.preventDefault();
            const dataArray = new FormData();
            dataArray.append("file", event);
            axios.post(process.env.REACT_APP_API_ENDPOINT + "upload-file", dataArray, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                // successfully uploaded response
                //console.log(response.data);
                if (response.data.status == "Success") {
                    var media_id = response.data.data.id;
                    setAmazonExpensesId(amazonExpensesId => [...amazonExpensesId, media_id]);
                }
            })
            .catch((error) => {
                // error response
                console.log(error);
                toast.error('There has been an error with the server, please try again later!', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setFormStatus("standby");
            });
    };

    const handleChangeEbitda = (event) => {
        var data_file = event.target.files[0];
        submitEbitda(event.target.files[0]);
        setEbitdaFormData({
            ...ebitdaFormData,
            ebitda_chart: ebitdaId
        });
        setEbitdaFileName(data_file.name);
    }

    const submitEbitda = (event) => {
        // event.preventDefault();
            const dataArray = new FormData();
            dataArray.append("file", event);
            axios.post(process.env.REACT_APP_API_ENDPOINT + "upload-file", dataArray, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                // successfully uploaded response
                //console.log(response.data);
                if (response.data.status == "Success") {
                    var media_id = response.data.data.id;
                    setEbitdaId(ebitdaId => [...ebitdaId, media_id]);
                }
            })
            .catch((error) => {
                // error response
                console.log(error);
                toast.error('There has been an error with the server, please try again later!', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setFormStatus("standby");
            });
    };

   function getSidebar() {
       setIsSidebar(!isSidebar);
   }

    const [inputFields, setInputFields] = useState([
        { name: "", code: "" },
        { name: "", code: "" },
        { name: "", code: "" },
        { name: "", code: "" },
        { name: "", code: "" }
    ]);

    const [supplierFields, setSupplierFields] = useState([
        { name: "" },
        { name: "" },
        { name: "" },
        { name: "" },
        { name: "" }
    ]);

    const handleChangeSuppliers = (index, evnt) => {
        const { name, value } = evnt.target;

        const list = [...supplierFields];
        let realValue = value;
        if (value == 0) {
            realValue = "";
        }
        list[index][name] = realValue;
        setSupplierFields(list);

        setFormData({
            ...formData,
            name: list,
        })
    };

    const handleChangeCompetitors = (index, evnt) => {
        const { name, value } = evnt.target;

        const list = [...inputFields];
        let realValue = value;
        if (value == 0) {
            realValue = "";
        }
        list[index][name] = realValue;
        setInputFields(list);

        setFormData({
            ...formData,
            name: list,
        })
    };

    const toggleShowTags = () => {
        setTagsListShow(true);
        setTagsBoxStyle('card-show-tags-show');
    }

    const toggleHideTags = () => {
        setTagsListShow(false);
        setTagsBoxStyle('card-show-tags-hidden');
    }

    const handleChangeCheck = (id) => {
        setTagIds(tagIds => [...tagIds, id]);
    }

    const handleChangeUnchecked = (id) => {
        let filterId = tagIds.filter(item => item !== id);
        setTagIds(filterId)
    }

    const fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(null, reader.result)
        }
        reader.onerror = function (error) {
            cb(error, null)
        }
    }

    const handleChangeImageFile = ({ target }) => {
        if (target.files < 1 || !target.validity.valid) {
            return
        }

        if (target.files && target.files[0]) {
            setPreview(URL.createObjectURL(target.files[0]));
        }

        const dataArray = new FormData();
        dataArray.append("url", target.files[0]);
        axios.post(process.env.REACT_APP_API_ENDPOINT + "image/upload", dataArray, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            // successfully uploaded response
            //console.log(response.data);
            if (response.data.status == "Success") {
                var filename = response.data.data.url;
                setFormData({
                    ...formData,
                    featured_image: filename
                });
            } else {
                toast.error('There has been an error with the server, please try again later!', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch((error) => {
            // error response
            console.log(error);
            toast.error('There has been an error with the server, please try again later!', {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // setFormStatus("standby");
        });
    };

    // const submitDocument = (event) => {
    //     // event.preventDefault();
    //     setUploadStatus('loading');
    //     const dataArray = new FormData();
    //     dataArray.append('file', event);
    //     axios.post(process.env.REACT_APP_API_ENDPOINT + 'file_upload?award_id=' + listing_id + '?user_id=' + currentUser + '&token=' + token, dataArray, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //         },
    //     }).then((response) => {
    //         if (response.data.status == 'Success') {
    //             var media_id = response.data.data.id;
    //             var media_url = response.data.data.filename;
    //             setCollateralIds(collateralIds => [...collateralIds, media_id]);
    //             // setImages(documents => [...images, event]);

    //             let reader = new FileReader();
    //             let file = event;

    //             reader.onloadend = () => {
    //                 setCollateralDocs(documents => [...documents, { media_id: media_id, url: media_url }]);
    //             };
    //             reader.readAsDataURL(file);
    //             // setReloadCount(reloadCount + 1);
    //             if (collateralIds.length >= uploadedCollaterals) {
    //                 setUploadStatus('standby');
    //             } else {
    //                 console.log(mediaIds.length);
    //                 console.log(uploadedFiles);
    //             }
    //         } else {
    //             setUploadStatus('standby');
    //         }
    //     })
    //         .catch((error) => {
    //             // error response
    //             // console.log(error);
    //             toast.error('There has been an error with the server, please try again later!', {
    //                 position: 'top-right',
    //                 autoClose: 1500,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //             setUploadStatus('standby');
    //         });
    // };

    // const handleChangeFile = (e) => {
    //     Array.from(e.target.files).forEach((single_file, index) => {
    //         submitDocument(e.target.files[index]); // Prints the index at which the loop is currently at
    //     });
    // };

    const preventDefault = (e) => {
        e.preventDefault();
    }

    // page content
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleChangeDec = (e) => {
        const{value,name} = e.target;

        let str = value.toString();
        str = str.slice(0, (str.indexOf(".")) + 10 + 1);
        Number(str);

        if (value >= 9999999999.9999999999) {
            setFormData({
                ...formData,
                [name]:Number(str)
            })
        } else {
            setFormData({
                ...formData,
                [name]: value
            })
        }
    }
    const handleChangeInt = (e) => {
        const{value,name} = e.target;

        if (value >= 999999999){
            setFormData({
                ...formData,
                [name]: 999999999,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    }

    // Advertising
    const handleChangeAdvertisingYesNo = (e) => {
        var value = e.target.value;
        if (value == "Yes") {
            setAdvertising(true);
            setFormData({
                ...formData,
                advertising: e.target.value,
            });
        } else {
            setAdvertising(false);
            setFormData({
                ...formData,
                advertising: e.target.value,
            });
        }

        // setImageUpload(e.target.files[0]);
    };

    const handleChangeAdvertising = (e) => {

        if (advertising) {
            setFormData({
                ...formData,
                advertising: e.target.value,
            });
        } else {
            setAdvertising(false);
            setFormData({
                ...formData,
                advertising: "No",
            });
        }

        // setImageUpload(e.target.files[0]);
    };

    // End of Advertising

    // Account Violation
    const handleChangeViolationYesNo = (e) => {
        var value = e.target.value;
        if (value == "Yes") {
            setAccountViolation(true);
            setFormData({
                ...formData,
                account_violations: e.target.value,
            });
        } else {
            setAccountViolation(false);
            setFormData({
                ...formData,
                account_violations: e.target.value,
            });
        }
        // setImageUpload(e.target.files[0]);
    };

    const handleChangeViolation = (e) => {
        if (accountViolation) {
            setAccountViolation(true);
            setFormData({
                ...formData,
                account_violations: e.target.value,
            });
        } else {
            setAccountViolation(false);
            setFormData({
                ...formData,
                account_violations: "No",
            });
        }

        // setImageUpload(e.target.files[0]);
    };
    // End of Account Violation

    // ASIN Violation
    const handleChangeAsinYesNo = (e) => {
        var value = e.target.value;
        if (value == "Yes") {
            setAsinViolation(true);
            setFormData({
                ...formData,
                asin_violations: e.target.value,
            });
        } else {
            setAsinViolation(false);
            setFormData({
                ...formData,
                asin_violations: e.target.value,
            });
        }
    };

    const handleChangeAsin = (e) => {
        if (asinViolation) {
            setAsinViolation(true);
            setFormData({
                ...formData,
                asin_violations: e.target.value,
            });
        } else {
            setAsinViolation(false);
            setFormData({
                ...formData,
                asin_violations: "No",
            });
        }

    };
    // End of ASIN Violation

    // Exclusive Contract

    // Exclusive
    const handleChangeExclusiveYesNo = (e) => {
        var value = e.target.value;
        if (value == "Yes") {
            setExlusive(true);
            setFormData({
                ...formData,
                supplier_exclusive_contract: '',
            });
        } else {
            setExlusive(false);
            setFormData({
                ...formData,
                supplier_exclusive_contract: e.target.value,
            });
        }
    };

    const handleChangeExclusive = (e) => {
        if (exclusive) {
            setExlusive(true);
            setFormData({
                ...formData,
                supplier_exclusive_contract: e.target.value,
            });
        } else {
            setExlusive(false);
            setFormData({
                ...formData,
                supplier_exclusive_contract: "No",
            });
        }

    };
    // End of Exlusive Contract

    // FBM
    const handleChangeFbmYesNo = (e) => {
        var value = e.target.value;
        if (value == "Yes") {
            setFbm(true);
            setFormData({
                ...formData,
                fbm: e.target.value,
            });
        } else {
            setFbm(false);
            setFormData({
                ...formData,
                fbm: e.target.value,
            });
        }
    };

    const handleChangeFbm = (e) => {
        if (fbm) {
            setFbm(true);
            setFormData({
                ...formData,
                fbm: e.target.value,
            });
        } else {
            setFbm(false);
            setFormData({
                ...formData,
                fbm: "No",
            });
        }

    };
    // End of FBM

    // PPC
    const handleChangePpcYesNo = (e) => {
        var value = e.target.value;
        if (value == "Yes") {
            setPpc(true);
            setFormData({
                ...formData,
                ppc_advertising_changes: e.target.value,
            });
        } else {
            setPpc(false);
            setFormData({
                ...formData,
                ppc_advertising_changes: e.target.value,
            });
        }
    };

    const handleChangePpc = (e) => {
        if (ppc) {
            setPpc(true);
            setFormData({
                ...formData,
                ppc_advertising_changes: e.target.value,
            });
        } else {
            setPpc(false);
            setFormData({
                ...formData,
                ppc_advertising_changes: "No",
            });
        }

    };
    // End of PPC

    // Stockout Occurent
    const handleChangeStockoutYesNo = (e) => {
        var value = e.target.value;
        if (value == "Yes") {
            setStockout(true);
            setFormData({
                ...formData,
                stock_out_occurence: e.target.value,
            });
        } else {
            setStockout(false);
            setFormData({
                ...formData,
                stock_out_occurence: e.target.value,
            });
        }
    };

    const handleChangeStockout = (e) => {
        if (stockout) {
            setStockout(true);
            setFormData({
                ...formData,
                stock_out_occurence: e.target.value,
            });
        } else {
            setStockout(false);
            setFormData({
                ...formData,
                stock_out_occurence: "No",
            });
        }

    };
    // End of Stockout Occurence

    const submitItem = () => {
        setFormStatus('loading')
            if (amazonIncomeId) {
                axios({
                    method: 'put',
                    url: process.env.REACT_APP_API_ENDPOINT + 'listing/' + listing_id + '/amazon_income?import_id=' + amazonIncomeId,
                    // data: ({
                    //     amazon_income: amazonIncomeId,
                    // })
                }).then(function (response) {
                    if (response.data.status == 'Success') {
                // setFormStatus('standby');
                    }
                }).catch(function (error) {
                    setError(error)
                    setFormStatus('standby');
                })
            }
            if (amazonCogsId) {
                axios({
                    method: 'put',
                    url: process.env.REACT_APP_API_ENDPOINT + 'listing/' + listing_id + '/amazon_cogs?import_id=' + amazonCogsId,
                    // data: ({
                    //     amazon_income: amazonIncomeId,
                    // })
                }).then(function (response) {
                    if (response.data.status == 'Success') {
                // setFormStatus('standby');
                    }
                }).catch(function (error) {
                    setError(error)
                    setFormStatus('standby');
                })
            }
            if (amazonExpensesId) {
                axios({
                    method: 'put',
                    url: process.env.REACT_APP_API_ENDPOINT + 'listing/' + listing_id + '/amazon_expenses?import_id=' + amazonExpensesId,
                    // data: ({
                    //     amazon_income: amazonIncomeId,
                    // })
                }).then(function (response) {
                    if (response.data.status == 'Success') {
                // setFormStatus('standby');
                    }
                }).catch(function (error) {
                    setError(error)
                    setFormStatus('standby');
                })
            }
            if (ebitdaId) {
                axios({
                    method: 'put',
                    url: process.env.REACT_APP_API_ENDPOINT + 'listing/' + listing_id + '/ebitda_chart?import_id=' + ebitdaId,
                    // data: ({
                    //     amazon_income: amazonIncomeId,
                    // })
                }).then(function (response) {
                    if (response.data.status == 'Success') {
                // setFormStatus('standby');
                    }
                }).catch(function (error) {
                    setError(error)
                    setFormStatus('standby');
                })
            }
            axios({
                method: 'put',
                url: process.env.REACT_APP_API_ENDPOINT + 'listing/' + listing_id,
                data: ({
                    tag_ids: tagIds,
                    featured_image: formData.featured_image,
                    store_url: formData.store_url,
                    store_name: formData.store_name,
                    brand_name: formData.brand_name,
                    brand_amazon: formData.brand_amazon,
                    brand_vendor_central: formData.brand_vendor_central,
                    web_stores: formData.web_stores,
                    business_created: formData.business_created,
                    platform_sales: formData.platform_sales,
                    country_sales: formData.country_sales,
                    facebook_url: formData.facebook_url,
                    twitter_url: formData.twitter_url,
                    instagram_url: formData.instagram_url,
                    linkedin_url: formData.linkedin_url,
                    trademark: formData.trademark,
                    // sm_accounts: formData.sm_accounts,
                    business_growth: formData.business_growth,
                    revenue: formData.revenue,
                    growth_rate_revenue: formData.growth_rate_revenue,
                    asin_sales: formData.asin_sales,
                    gross_profit_margin: formData.gross_profit_margin,
                    ebitda_margin: formData.ebitda_margin,
                    gross_profit: formData.gross_profit,
                    ebitda: formData.ebitda,
                    return_rate: formData.return_rate,
                    product_sales_price: formData.product_sales_price,
                    inventory_quantity: formData.inventory_quantity,
                    expenses: formData.expenses,
                    reason_category: formData.reason_category,
                    certification_requirements: formData.certification_requirements,
                    amazon_brand_registry: formData.amazon_brand_registry,
                    amazon_accelerator: formData.amazon_accelerator,
                    self_developed_product: formData.self_developed_product,
                    product_development_speed: formData.product_development_speed,
                    product_development_plan: formData.product_development_plan,
                    category_competition: formData.category_competition,
                    // competitor_brands: formData.competitor_brands,
                    core_quality_issues: formData.core_quality_issues,
                    office_address: formData.office_address,
                    warehouse_address: formData.warehouse_address,
                    number_of_employees: formData.number_of_employees,
                    customer_service_process: formData.customer_service_process,
                    refund_process: formData.refund_process,
                    ppc_revenue_cost: formData.ppc_revenue_cost,
                    ppc_advertising_cost: formData.ppc_advertising_cost,
                    ppc_advertising_changes: formData.ppc_advertising_changes,
                    asin_ranking: formData.asin_ranking,
                    marketing_agency: formData.marketing_agency,
                    advertising: formData.advertising,
                    marketing_expenses: formData.marketing_expenses,
                    giveaways: formData.giveaways,
                    account_violations: formData.account_violations,
                    asin_violations: formData.asin_violations,
                    growth_opportunities: formData.growth_opportunities,
                    development_plan: formData.development_plan,
                    suppliers: formData.suppliers,
                    supplier_location: formData.supplier_location,
                    supplier_proportion: formData.supplier_proportion,
                    supplier_account_period: formData.supplier_account_period,
                    supplier_length_of_cooperation: formData.supplier_length_of_cooperation,
                    supplier_contracts: formData.supplier_contracts,
                    supplier_minimum_order: formData.supplier_minimum_order,
                    supplier_share_control: formData.supplier_share_control,
                    supplier_exclusive_contract: formData.supplier_exclusive_contract,
                    supplier_selection_criteria: formData.supplier_selection_criteria,
                    quality_control: formData.quality_control,
                    stock_out_occurence: formData.stock_out_occurence,
                    reason_for_stockout: formData.reason_for_stockout,
                    ipi_score: formData.ipi_score,
                    logistic_providers: formData.logistic_providers,
                    sea_air_freight_proportion: formData.sea_air_freight_proportion,
                    domestic_warehouse_3rd_party: formData.domestic_warehouse_3rd_party,
                    overseas_warehouse_3rd_party: formData.overseas_warehouse_3rd_party,
                    proportion_of_goods: formData.proportion_of_goods,
                    fbm: formData.fbm,
                    proportion_of_fbm: formData.proportion_of_fbm,
                    reason_for_sale: formData.reason_for_sale,
                    valued_transaction_process: formData.valued_transaction_process,
                    asking_price: formData.asking_price,
                    deal_closure: formData.deal_closure,
                    upfront_payment: formData.upfront_payment,
                    vps_login_access: formData.vps_login_access,
                    // suppliers: supplierFields,
                    competitor_brands: inputFields,
                })
            }).then(function (response) {
                if (response.data.status == 'Success') {
                    toast.success('Listing updated successfully!', {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        onClose: () =>  navigate('/')
                    });
                    setFormStatus('standby');
                } else {
                    toast.error('An error occured. Please try again or contact the administrator.', {
                        position: 'top-right',
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setFormStatus('standby')
                }
            }).catch(function (error) {
                setError(error)
            })
    };

    const draftUpdateSubmit = () => {
        setdraftFormStatus('loading')
            if (amazonIncomeId) {
                axios({
                    method: 'put',
                    url: process.env.REACT_APP_API_ENDPOINT + 'listing/' + listing_id + '/amazon_income?import_id=' + amazonIncomeId,
                    // data: ({
                    //     amazon_income: amazonIncomeId,
                    // })
                }).then(function (response) {
                    if (response.data.status == 'Success') {
                // setFormStatus('standby');
                    }
                }).catch(function (error) {
                    setError(error)
                    setdraftFormStatus('standby');
                })
            }
            if (amazonCogsId) {
                axios({
                    method: 'put',
                    url: process.env.REACT_APP_API_ENDPOINT + 'listing/' + listing_id + '/amazon_cogs?import_id=' + amazonCogsId,
                    // data: ({
                    //     amazon_income: amazonIncomeId,
                    // })
                }).then(function (response) {
                    if (response.data.status == 'Success') {
                // setFormStatus('standby');
                    }
                }).catch(function (error) {
                    setError(error)
                    setdraftFormStatus('standby');
                })
            }
            if (amazonExpensesId) {
                axios({
                    method: 'put',
                    url: process.env.REACT_APP_API_ENDPOINT + 'listing/' + listing_id + '/amazon_expenses?import_id=' + amazonExpensesId,
                    // data: ({
                    //     amazon_income: amazonIncomeId,
                    // })
                }).then(function (response) {
                    if (response.data.status == 'Success') {
                // setFormStatus('standby');
                    }
                }).catch(function (error) {
                    setError(error)
                    setdraftFormStatus('standby');
                })
            }
            if (ebitdaId) {
                axios({
                    method: 'put',
                    url: process.env.REACT_APP_API_ENDPOINT + 'listing/' + listing_id + '/ebitda_chart?import_id=' + ebitdaId,
                    // data: ({
                    //     amazon_income: amazonIncomeId,
                    // })
                }).then(function (response) {
                    if (response.data.status == 'Success') {
                // setFormStatus('standby');
                    }
                }).catch(function (error) {
                    setError(error)
                    setdraftFormStatus('standby');
                })
            }
            axios({
                method: 'put',
                url: process.env.REACT_APP_API_ENDPOINT + 'listing/' + listing_id + '/draft/update',
                data: ({
                    tag_ids: tagIds,
                    featured_image: formData.featured_image,
                    store_url: formData.store_url,
                    store_name: formData.store_name,
                    brand_name: formData.brand_name,
                    brand_amazon: formData.brand_amazon,
                    brand_vendor_central: formData.brand_vendor_central,
                    web_stores: formData.web_stores,
                    business_created: formData.business_created,
                    platform_sales: formData.platform_sales,
                    country_sales: formData.country_sales,
                    facebook_url: formData.facebook_url,
                    twitter_url: formData.twitter_url,
                    instagram_url: formData.instagram_url,
                    linkedin_url: formData.linkedin_url,
                    trademark: formData.trademark,
                    // sm_accounts: formData.sm_accounts,
                    business_growth: formData.business_growth,
                    revenue: formData.revenue,
                    growth_rate_revenue: formData.growth_rate_revenue,
                    asin_sales: formData.asin_sales,
                    gross_profit_margin: formData.gross_profit_margin,
                    ebitda_margin: formData.ebitda_margin,
                    gross_profit: formData.gross_profit,
                    ebitda: formData.ebitda,
                    return_rate: formData.return_rate,
                    product_sales_price: formData.product_sales_price,
                    inventory_quantity: formData.inventory_quantity,
                    expenses: formData.expenses,
                    reason_category: formData.reason_category,
                    certification_requirements: formData.certification_requirements,
                    amazon_brand_registry: formData.amazon_brand_registry,
                    amazon_accelerator: formData.amazon_accelerator,
                    self_developed_product: formData.self_developed_product,
                    product_development_speed: formData.product_development_speed,
                    product_development_plan: formData.product_development_plan,
                    category_competition: formData.category_competition,
                    // competitor_brands: formData.competitor_brands,
                    core_quality_issues: formData.core_quality_issues,
                    office_address: formData.office_address,
                    warehouse_address: formData.warehouse_address,
                    number_of_employees: formData.number_of_employees,
                    customer_service_process: formData.customer_service_process,
                    refund_process: formData.refund_process,
                    ppc_revenue_cost: formData.ppc_revenue_cost,
                    ppc_advertising_cost: formData.ppc_advertising_cost,
                    ppc_advertising_changes: formData.ppc_advertising_changes,
                    asin_ranking: formData.asin_ranking,
                    marketing_agency: formData.marketing_agency,
                    advertising: formData.advertising,
                    marketing_expenses: formData.marketing_expenses,
                    giveaways: formData.giveaways,
                    account_violations: formData.account_violations,
                    asin_violations: formData.asin_violations,
                    growth_opportunities: formData.growth_opportunities,
                    development_plan: formData.development_plan,
                    suppliers: formData.suppliers,
                    supplier_location: formData.supplier_location,
                    supplier_proportion: formData.supplier_proportion,
                    supplier_account_period: formData.supplier_account_period,
                    supplier_length_of_cooperation: formData.supplier_length_of_cooperation,
                    supplier_contracts: formData.supplier_contracts,
                    supplier_minimum_order: formData.supplier_minimum_order,
                    supplier_share_control: formData.supplier_share_control,
                    supplier_exclusive_contract: formData.supplier_exclusive_contract,
                    supplier_selection_criteria: formData.supplier_selection_criteria,
                    quality_control: formData.quality_control,
                    stock_out_occurence: formData.stock_out_occurence,
                    reason_for_stockout: formData.reason_for_stockout,
                    ipi_score: formData.ipi_score,
                    logistic_providers: formData.logistic_providers,
                    sea_air_freight_proportion: formData.sea_air_freight_proportion,
                    domestic_warehouse_3rd_party: formData.domestic_warehouse_3rd_party,
                    overseas_warehouse_3rd_party: formData.overseas_warehouse_3rd_party,
                    proportion_of_goods: formData.proportion_of_goods,
                    fbm: formData.fbm,
                    proportion_of_fbm: formData.proportion_of_fbm,
                    reason_for_sale: formData.reason_for_sale,
                    valued_transaction_process: formData.valued_transaction_process,
                    asking_price: formData.asking_price,
                    deal_closure: formData.deal_closure,
                    upfront_payment: formData.upfront_payment,
                    vps_login_access: formData.vps_login_access,
                    // suppliers: supplierFields,
                    competitor_brands: inputFields,
                })
            }).then(function (response) {
                if (response.data.status == 'Success') {
                    toast.success('Draft updated successfully!', {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        onClose: () =>  navigate('/')
                    });
                    setdraftFormStatus('standby');
                } else {
                    toast.error('An error occured. Please try again or contact the administrator.', {
                        position: 'top-right',
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setdraftFormStatus('standby')
                }
            }).catch(function (error) {
                setError(error)
            })
    };

    useEffect(() => {
        // This is the axios GET
        axios.get(process.env.REACT_APP_API_ENDPOINT + 'tag')
            .then((response) => {
                const data = response.data;
                const result = data.data;
                setTagsData(result);
                console.log("Tags Data", tagsData);
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        axios.get(process.env.REACT_APP_API_ENDPOINT + 'listing/' + listing_id)
            .then((response) => {
                const data = response.data;
                const result = data.data;
                // console.log("Result Data",result.listing_tags);
                // const listing_array = result.listing_tags.id;
                // console.log("Listing Array", listing_array);
                var result_tag_ids = result.listing_tags;
                var tagIdsLoop = result_tag_ids.map(s => s.id);
                setTagIds(tagIdsLoop);
                setFormData({
                    ...formData,
                    status: result.status,
                    listing_tags: result.listing_tags.id,
                    featured_image: result.featured_image,
                    store_url: result.store_url,
                    store_name: result.store_name,
                    brand_name: result.brand_name,
                    brand_amazon: result.brand_amazon,
                    brand_vendor_central: result.brand_vendor_central,
                    web_stores: result.web_stores,
                    business_created: result.business_created,
                    platform_sales: result.platform_sales,
                    country_sales: result.country_sales,
                    facebook_url: result.facebook_url,
                    twitter_url: result.twitter_url,
                    instagram_url: result.instagram_url,
                    linkedin_url: result.linkedin_url,
                    trademark: result.trademark,
                    business_growth: result.business_growth,
                    revenue: result.revenue,
                    growth_rate_revenue: result.growth_rate_revenue,
                    asin_sales: result.asin_sales,
                    gross_profit_margin: result.gross_profit_margin,
                    ebitda_margin: result.ebitda_margin,
                    gross_profit: result.gross_profit,
                    ebitda: result.ebitda,
                    ltm_ebitda: result.ltm_ebitda,
                    ltm_gmv: result.ltm_gmv,
                    return_rate: result.return_rate,
                    product_sales_price: result.product_sales_price,
                    inventory_quantity: result.inventory_quantity,
                    expenses: result.expenses,
                    reason_category: result.reason_category,
                    certification_requirements: result.certification_requirements,
                    amazon_brand_registry: result.amazon_brand_registry,
                    amazon_accelerator: result.amazon_accelerator,
                    self_developed_product: result.self_developed_product,
                    product_development_speed: result.product_development_speed,
                    product_development_plan: result.product_development_plan,
                    category_competition: result.category_competition,
                    // competitor_brands: result.competitor_brands,
                    core_quality_issues: result.core_quality_issues,
                    office_address: result.office_address,
                    warehouse_address: result.warehouse_address,
                    number_of_employees: result.number_of_employees,
                    customer_service_process: result.customer_service_process,
                    refund_process: result.refund_process,
                    ppc_revenue_cost: result.ppc_revenue_cost,
                    ppc_advertising_cost: result.ppc_advertising_cost,
                    ppc_advertising_changes: result.ppc_advertising_changes,
                    asin_ranking: result.asin_ranking,
                    marketing_agency: result.marketing_agency,
                    advertising: result.advertising,
                    marketing_expenses: result.marketing_expenses,
                    giveaways: result.giveaways,
                    account_violations: result.account_violations,
                    asin_violations: result.asin_violations,
                    growth_opportunities: result.growth_opportunities,
                    development_plan: result.development_plan,
                    suppliers: result.suppliers,
                    supplier_location: result.supplier_location,
                    supplier_proportion: result.supplier_proportion,
                    supplier_account_period: result.supplier_account_period,
                    supplier_length_of_cooperation: result.supplier_length_of_cooperation,
                    supplier_contracts: result.supplier_contracts,
                    supplier_minimum_order: result.supplier_minimum_order,
                    supplier_share_control: result.supplier_share_control,
                    supplier_exclusive_contract: result.supplier_exclusive_contract,
                    supplier_selection_criteria: result.supplier_selection_criteria,
                    quality_control: result.quality_control,
                    stock_out_occurence: result.stock_out_occurence,
                    reason_for_stockout: result.reason_for_stockout,
                    ipi_score: result.ipi_score,
                    logistic_providers: result.logistic_providers,
                    sea_air_freight_proportion: result.sea_air_freight_proportion,
                    domestic_warehouse_3rd_party: result.domestic_warehouse_3rd_party,
                    overseas_warehouse_3rd_party: result.overseas_warehouse_3rd_party,
                    proportion_of_goods: result.proportion_of_goods,
                    fbm: result.fbm,
                    proportion_of_fbm: result.proportion_of_fbm,
                    reason_for_sale: result.reason_for_sale,
                    valued_transaction_process: result.valued_transaction_process,
                    asking_price: result.asking_price,
                    deal_closure: result.deal_closure,
                    upfront_payment: result.upfront_payment,
                    vps_login_access: result.vps_login_access,
                });

                if (result.supplier_exclusive_contract != "No" && result.supplier_exclusive_contract != "" && result.supplier_exclusive_contract) {
                    setExlusive(true);
                } else {
                    setExlusive(false);
                }

                if (result.asin_violations != "No" && result.asin_violations != "" && result.asin_violations) {
                    setAsinViolation(true);
                } else {
                    setAsinViolation(false);
                }

                if (result.account_violations != "No" && result.account_violations != "" && result.account_violations) {
                    setAccountViolation(true);
                } else {
                    setAccountViolation(false);
                }

                if (result.ppc_advertising_changes != "No" && result.ppc_advertising_changes != "" && result.ppc_advertising_changes) {
                    setPpc(true);
                } else {
                    setPpc(false);
                }

                if (result.advertising != "No" && result.advertising != "" && result.advertising) {
                    setAdvertising(true);
                } else {
                    setAdvertising(false);
                }

                if (result.stock_out_occurence != "No" && result.stock_out_occurence != "" && result.stock_out_occurence) {
                    setStockout(true);
                } else {
                    setStockout(false);
                }

                if (result.fbm != "No" && result.fbm != "" && result.fbm) {
                    setFbm(true);
                } else {
                    setFbm(false);
                }

                if(result.competitor_brands !== null && result.competitor_brands.length > 0){
                    setInputFields(result.competitor_brands);
                } else {
                    setInputFields(
                        [
                            { name: "", code: "" },
                            { name: "", code: "" },
                            { name: "", code: "" },
                            { name: "", code: "" },
                            { name: "", code: "" }
                        ]
                    )
                }

                // console.log("Tags Data Effect", tagsData);
                // setSupplierFields(result.suppliers);
            }).catch(error => {
                // setError(error);
                toast.error('An error occured. Please try again or contact the administrator.', {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }, []);

    return (
        <section id='marketing-create'>
            <Container fluid>
            <ToastContainer style={{ width: "370px" }} />
                <Row>
                    <Col lg='2'>
                        {isSidebar || windowDimension.winWidth > 1024 ?
                            <Sidebar clickSidebar={getSidebar}/>
                            :
                            null
                        }
                        <button onClick={getSidebar} className={isSidebar ? "hidden" : "hamburger-btn show"}><GiHamburgerMenu /></button>
                        {/* <Sidebar /> */}
                    </Col>
                    <Col lg='10' className='right-container'>
                        <div className="mobile-display mb-3">
                            <div>
                                <h2 className='header'>Edit Listing</h2>
                                <div className="divider mb-0"></div>
                            </div>
                            <div>
                                <button className='button back view-button' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button>
                            </div>
                        </div>
                        <div className="desktop-display">
                            <Row className='utility pb-0'>
                                <Col lg='8' className="mb-0">
                                    <h2 className="ss-header mb-0">Edit Listing</h2>
                                    <div className="divider"></div>
                                </Col>
                                <Col lg='4' className='text-end'>
                                    <button className='button back view-button' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button>
                                </Col>
                            </Row>
                        </div>
                        <Card className="mb-3">
                            <CardBody className='create-input'>
                                <Row>
                                    <Col lg="12">
                                        <Label className='list-head mb-0'>GENERAL INFORMATION</Label>
                                        <hr />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Tags List</Label>
                                        <div className='position-relative'>
                                            {tagsListShow ?
                                                // <InfoBox show={tagsListShow} onClickOutside={() => { setTagsListShow(false); setTagsBoxStyle('card-show-tags-hidden'); }}>
                                                    <button type="button" className="button-tags" onClick={toggleHideTags}>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <span className="">
                                                                {tagsData.length > 0 ?
                                                                    <>
                                                                        {tagsData.map(({ id, name }, index) => (
                                                                            <>
                                                                                {tagIds.indexOf(id) > -1 ?
                                                                                    <>{name}{index+1 < tagsData.length ? ", ": null} </>
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                                {tagIds.length > 0 ?
                                                                    null
                                                                    :
                                                                    <p className="choose-categories mb-0">Choose categories here...</p>
                                                                }

                                                            </span>
                                                            <IoIosArrowUp />
                                                        </div>
                                                    </button>
                                                // </InfoBox>
                                            :
                                                <button type="button" className="button-tags" onClick={toggleShowTags}>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <span className="">
                                                            {tagsData.length > 0 ?
                                                                <>
                                                                    {tagsData.map(({ id, name }, index) => (
                                                                        <>
                                                                            {tagIds.indexOf(id) > -1 ?
                                                                                <>{name}{index+1 < tagsData.length ? ", ": null}</>
                                                                                :
                                                                                null
                                                                            }
                                                                        </>
                                                                    ))}
                                                                </>
                                                                :
                                                                null
                                                            }
                                                            {tagIds.length > 0 ?
                                                                null
                                                                :
                                                                <p className="choose-categories mb-0">Choose categories here...</p>
                                                            }

                                                        </span>
                                                        <IoIosArrowDown />
                                                    </div>
                                                </button>
                                            }
                                            <InfoBox show={tagsListShow} onClickOutside={() => { setTagsListShow(false); setTagsBoxStyle('card-show-tags-hidden'); }}>
                                                <Card className={tagsBoxStyle}>
                                                    <CardBody>
                                                        <Row>
                                                            {tagsData ?
                                                                <>
                                                                    {tagsData.length > 0 ?
                                                                        <>
                                                                            {tagsData.map(({ id, name }, index) => (
                                                                                <Col lg="12">
                                                                                    {/* <p>ID {tagIds}</p> */}
                                                                                    {tagIds.indexOf(id) > -1 ?
                                                                                        <>
                                                                                            <p className='mb-0' key={index}><input type="checkbox" className='me-2' value={id} onChange={function (event) { handleChangeUnchecked(id); }} defaultChecked/>{name}</p>
                                                                                        </>
                                                                                        :
                                                                                        <p className='mb-0'><input type="checkbox" className='me-2' value={id} onChange={function (event) { handleChangeCheck(id); }}/>{name}</p>
                                                                                    }
                                                                                </Col>
                                                                            ))}
                                                                        </>
                                                                        :
                                                                        <div className='text-center'><PageSpinner /></div>
                                                                    }
                                                                </>
                                                                :
                                                                <p>No tags available!</p>
                                                            }
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </InfoBox>
                                        </div>
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Store URL</Label>
                                        <Input type='text' maxLength={255} name='store_url' value={formData.store_url} onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Store Name</Label>
                                        <Input type='text' maxLength={255} name='store_name' value={formData.store_name} onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Brand Name</Label>
                                        <Input type='text' maxLength={255} name='brand_name' value={formData.brand_name} onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Are the brands being used in Amazon Store</Label>
                                        <Input type='select' name="brand_amazon" value={formData.brand_amazon} onChange={handleChangeInt} >
                                            <option value=""></option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Input>
                                        {/* <Input type='number' name='brand_amazon' onChange={handleChange} /> */}
                                    </Col>
                                    <Col lg='6'  className="mb-3">
                                        <Label>Are the brands being used in Vendor Central?</Label>
                                        <Input type='select' name="brand_vendor_central" value={formData.brand_vendor_central} onChange={handleChangeInt} >
                                            <option value=""></option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Input>
                                        {/* <Input type='text' name='brand_vendor_central' onChange={handleChange} /> */}
                                    </Col>
                                    <Col lg='6'  className="mb-3">
                                        <Label>Are the brands being sold in other platforms?</Label>
                                        <Input type='select' name="web_stores" value={formData.web_stores} maxLength={255} onChange={handleChange} >
                                            <option value=""></option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Input>
                                        {/* <Input type='text' name='web_stores' onChange={handleChange} /> */}
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Business Created</Label>
                                        {/* <Input name='business_created' type='date' value={Moment(formData.business_created).format('YYYY-MM-DD')} maxLength={255} onChange={handleChange} /> */}
                                        <Input name='business_created' type='text' value={formData.business_created} placeholder="dd/mm/yyyy" maxLength={255} onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        {/* <Label>Platform Approximate Sales</Label> */}
                                        <Label>Sales Share on Each Platform</Label>
                                        <Input type='text' maxLength={255} name='platform_sales' value={formData.platform_sales} onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        {/* <Label>Country Approximate Sales</Label> */}
                                        <Label>Sales Share in Each Region</Label>
                                        <Input type='text' maxLength={255} name='country_sales' value={formData.country_sales} onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Trade Mark</Label>
                                        <Input type='text' maxLength={255} name='trademark' value={formData.trademark} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" className="mb-3">
                                    <Label>Featured Image</Label>
                                        <Card>
                                            <CardBody className="file-upload">
                                                {/* {imageUpload.file !== "" ?
                                                <> */}
                                                        <Label htmlFor="fileUpload">
                                                            <img src={DownloadIcon} className="download_icon" />
                                                            <p className="mb-0">Choose file</p>
                                                            <p className="mb-0">Please upload 1 (one) image file</p>
                                                        </Label>
                                                            <input
                                                            id="fileUpload"
                                                            type="file"
                                                            name="product_image"
                                                            // ref={hiddenFileInputType}
                                                            onChange={handleChangeImageFile}
                                                            style={{ display: 'none' }}
                                                            accept='image/png, image/jpg, image/jpeg'
                                                            multiple
                                                            required
                                                        />
                                                {/* </> */}

                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="6" className="mb-3">
                                        <Label>Image</Label>
                                        {formData.featured_image !== "" ?
                                            <Card className='image-card'>
                                                <CardBody className='text-center'>
                                                    <img src={process.env.REACT_APP_URL + "/storage/gallery/"+formData.featured_image} className="market-image-create" />
                                                </CardBody>
                                            </Card>
                                            :
                                            <Card className='image-card'>
                                                <CardBody>
                                                    <p>Your image will display here....</p>
                                                </CardBody>
                                            </Card>
                                        }
                                    </Col>
                                </Row>
                                <Card className='mt-3'>
                                    <CardBody>
                                        <Row>
                                            <Col lg='12'>
                                                <Label>Social Media Accounts</Label>
                                            </Col>
                                            <Col lg='6' className="mb-3">
                                                <Label>Facebook Account</Label>
                                                <Input type='text' name='facebook_url' maxLength={255} value={formData.facebook_url} onChange={handleChange} />
                                            </Col>
                                            <Col lg='6' className="mb-3">
                                                <Label>Twitter Account</Label>
                                                <Input type='text' name='twitter_url' maxLength={255} value={formData.twitter_url} onChange={handleChange} />
                                            </Col>
                                            <Col lg='6' className="mb-3">
                                                <Label>Instagram Account</Label>
                                                <Input type='text' name='instagram_url' maxLength={255} value={formData.instagram_url} onChange={handleChange} />
                                            </Col>
                                            <Col lg='6' className="mb-3">
                                                <Label>LinkedIn Account</Label>
                                                <Input type='text' name='linkedin_url' maxLength={255} value={formData.linkedin_url} onChange={handleChange} />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </CardBody>
                        </Card>
                        <Card className="mb-3">
                            <CardBody>
                                <Row>
                                    <Col lg="12">
                                        <Label className='list-head mb-0'>FINANCIAL INFORMATION</Label>
                                        <hr />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Business Started to Increase</Label>
                                        <Input type='date' name='business_growth' maxLength={255} value={formData.business_growth} onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Revenue in the Last 12 Months</Label>
                                        <Input type='number' name='revenue' value={formData.revenue} onChange={handleChangeDec} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Growth Rate Revenue</Label>
                                        <Input type='number' name='growth_rate_revenue' value={formData.growth_rate_revenue} onChange={handleChangeDec} />
                                    </Col>
                                    {/* <Col lg='6' className="mb-3">
                                        <Label>Top 5 ASINs in the Last 12 Months</Label>
                                        <Input type='number' name='asin_sales' value={formData.asin_sales} onChange={handleChange} />
                                    </Col> */}
                                    <Col lg='6' className="mb-3">
                                        <Label>Gross Profit in the Last 12 Months</Label>
                                        <Input type='number' name='gross_profit' value={formData.gross_profit} onChange={handleChangeDec} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>EBITDA in the Last 12 Months</Label>
                                        <Input type='number' name='ebitda' value={formData.ebitda} onChange={handleChangeDec} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Return Rate in the Last 12 Months</Label>
                                        <Input type='number' name='return_rate' value={formData.return_rate} onChange={handleChangeDec} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Product Sales Prices in the Last 12 Months</Label>
                                        <Input type='number' name='product_sales_price' value={formData.product_sales_price} onChange={handleChangeInt} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Latest Inventory Quantity</Label>
                                        <Input type='number' name='inventory_quantity' value={formData.inventory_quantity} onChange={handleChangeInt} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Expenses</Label>
                                        <Input type='number' name='expenses' value={formData.expenses} maxLength={255} onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        {/* <Label>Top 5 ASINs in the Last 12 Months</Label> */}
                                        <Label>Top 5 ASIN Shares in the Last 12 Months</Label>
                                        <Input type='number' name='asin_sales' value={formData.asin_sales} onChange={handleChangeDec} />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="mb-3">
                            <CardBody>
                                <Row>
                                    <Col lg="12">
                                        <Label className='list-head mb-0'>DOCUMENT UPLOAD</Label>
                                        <hr />
                                    </Col>
                                    <Col lg="6" className="mb-3">
                                        <Label className='mb-0'>Amazon Income</Label>
                                        <Card className='mt-3'>
                                            <CardBody className="file-upload">
                                                    {amazonIncomeId == "" ?
                                                        <>
                                                            <Label htmlFor="fileAmazonIncome">
                                                                <img src={DownloadIcon} className="download_icon" />
                                                                <p className="mb-0">Choose file</p>
                                                                <p className="mb-0">Please upload 1 (one) excel file</p>
                                                            </Label>
                                                            <input
                                                                id="fileAmazonIncome"
                                                                type="file"
                                                                name="amazon_income"
                                                                // ref={hiddenFileInputType}
                                                                onChange={handleChangeAmazonIncome}
                                                                style={{ display: 'none' }}
                                                                // accept='image/png, image/jpg, image/jpeg'
                                                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                                // multiple
                                                                // required
                                                            />
                                                        </>
                                                    :
                                                        <>
                                                            <AiFillFileExcel fontSize={80} fill="#B6B6B6" className="file-type"/><br />
                                                            <p>{amazonIncomefileName}</p>
                                                        </>
                                                    }
                                                {/* </> */}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="6" className="mb-3">
                                        <Label className='mb-0'>Amazon COGS</Label>
                                        <Card className='mt-3'>
                                            <CardBody className="file-upload">
                                            {amazonCogsId == "" ?
                                                <>
                                                    <Label htmlFor="fileAmazonCogs">
                                                        <img src={DownloadIcon} className="download_icon" />
                                                        <p className="mb-0">Choose file</p>
                                                        <p className="mb-0">Please upload 1 (one) excel file</p>
                                                    </Label>
                                                    <input
                                                        id="fileAmazonCogs"
                                                        type="file"
                                                        name="amazon_cogs"
                                                        // ref={hiddenFileInputType}
                                                        onChange={handleChangeAmazonCogs}
                                                        style={{ display: 'none' }}
                                                        // accept='image/png, image/jpg, image/jpeg'
                                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                        // multiple
                                                        // required
                                                    />
                                                </>
                                                    :
                                                        <>
                                                            <AiFillFileExcel fontSize={80} fill="#B6B6B6" className="file-type"/><br />
                                                            <p>{amazonCogsfileName}</p>
                                                        </>
                                                    }
                                                {/* </> */}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="6" className="mb-3">
                                        <Label className='mb-0'>Amazon Expenses</Label>
                                        <Card className='mt-3'>
                                            <CardBody className="file-upload">
                                                {amazonExpensesId == "" ?
                                                    <>
                                                        <Label htmlFor="fileAmazonExpenses">
                                                            <img src={DownloadIcon} className="download_icon" />
                                                            <p className="mb-0">Choose file</p>
                                                            <p className="mb-0">Please upload 1 (one) excel file</p>
                                                        </Label>
                                                        <input
                                                            id="fileAmazonExpenses"
                                                            type="file"
                                                            name="amazon_expenses"
                                                            // ref={hiddenFileInputType}
                                                            onChange={handleChangeAmazonExpenses}
                                                            style={{ display: 'none' }}
                                                            // accept='image/png, image/jpg, image/jpeg'
                                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                            // multiple
                                                            // required
                                                        />
                                                    </>
                                                    :
                                                        <>
                                                            <AiFillFileExcel fontSize={80} fill="#B6B6B6" className="file-type"/><br />
                                                            <p>{amazonExpensesFileName}</p>
                                                        </>
                                                    }
                                                {/* </> */}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="6" className="mb-3">
                                        <Label className='mb-0'>EBITDA</Label>
                                        <Card className='mt-3'>
                                            <CardBody className="file-upload">
                                            {ebitdaId == "" ?
                                                    <>
                                                <Label htmlFor="fileEbitda">
                                                    <img src={DownloadIcon} className="download_icon" />
                                                    <p className="mb-0">Choose file</p>
                                                    <p className="mb-0">Please upload 1 (one) excel file</p>
                                                </Label>
                                                <input
                                                    id="fileEbitda"
                                                    type="file"
                                                    name="ebitda_chart"
                                                    // ref={hiddenFileInputType}
                                                    onChange={handleChangeEbitda}
                                                    style={{ display: 'none' }}
                                                    // accept='image/png, image/jpg, image/jpeg'
                                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                    // multiple
                                                    // required
                                                />
                                                 </>
                                                    :
                                                        <>
                                                            <AiFillFileExcel fontSize={80} fill="#B6B6B6" className="file-type"/><br />
                                                            <p>{ebitdaFileName}</p>
                                                        </>
                                                    }
                                                {/* </> */}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="mb-3">
                            <CardBody>
                                <Row>
                                    <Col lg="12">
                                        <Label className='list-head mb-0'>PRODUCTS, BRANDS AND COMPETITION</Label>
                                        <hr />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Reason for choosing the products and category</Label>
                                        <Input type='text' name='reason_category' maxLength={255} value={formData.reason_category} onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Certification Requirements</Label>
                                        <Input type='select' name="certification_requirements" value={formData.certification_requirements} onChange={handleChangeInt} >
                                            <option value=""></option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Input>
                                        {/* <Input type='text' name='certification_requirements' onChange={handleChange} /> */}
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Amazon Brand Registry Enrolled</Label>
                                        <Input type='select' name="amazon_brand_registry" value={formData.amazon_brand_registry} onChange={handleChangeInt} >
                                            <option value=""></option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Input>
                                        {/* <Input type='text' name='amazon_brand_registry' onChange={handleChange} /> */}
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Amazon Accelerator Program Enrolled</Label>
                                        <Input type='select' name="amazon_accelerator" value={formData.amazon_accelerator} onChange={handleChangeInt} >
                                            <option value=""></option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Input>
                                        {/* <Input type='text' name='amazon_accelerator' onChange={handleChange} /> */}
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Self Developed Products</Label>
                                        <Input type='select' name="self_developed_product" value={formData.self_developed_product} onChange={handleChangeInt} >
                                            <option value=""></option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Input>
                                        {/* <Input type='text' name='self_developed_product' onChange={handleChange} /> */}
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Speed of New Product Developments</Label>
                                        <Input type='text' maxLength={255} name='product_development_speed' value={formData.product_development_speed} onChange={handleChangeInt} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>New Product Development</Label>
                                        <Input type='text' maxLength={255} name='product_development_plan' value={formData.product_development_plan} onChange={handleChangeInt} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Category Competition</Label>
                                        <Input type='text' maxLength={255} name='category_competition' value={formData.category_competition} onChange={handleChange} />
                                    </Col>
                                    {/* <Col lg='6'>
                                        <Label>Competitor Brand</Label>
                                        <Input type='text' name='competitor_brands' value={formData.competitor_brands} onChange={handleChange} />
                                    </Col> */}
                                    <Col lg='12' className="mb-3">
                                        <Label>Core Quality Issues</Label>
                                        <Input type='textarea' rows={5} name='core_quality_issues' className="mb-3" maxLength={255} value={formData.core_quality_issues} onChange={handleChange} />
                                    </Col>
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                <Label>Top 5 Competitors</Label>
                                                {inputFields ?
                                                <>
                                                    {inputFields.length > 0 ?
                                                        <>
																													{inputFields.map((data, index) => {
																															const { id, name, code } = data;
																															return (
																																	<>
																																			<div className='position-relative'>
																																					<Row>
																																							<Col lg="6" className="mb-3">
																																									<Input
																																											onChange={(evnt) => handleChangeCompetitors(index, evnt)}
																																											name='name'
																																											value={name}
																																											placeholder={`Competitor ` + [index + 1]}
																																											maxLength={255}
																																									/>
																																							</Col>
																																							<Col lg="6" className="mb-3">
																																									<Input
																																											onChange={(evnt) => handleChangeCompetitors(index, evnt)}
																																											name='code'
																																											value={code}
																																											placeholder={`Code ` + [index + 1]}
																																											maxLength={255}
																																									/>
																																							</Col>
																																					</Row>
																																			</div>
																																	</>
																															)
																													})}
                                                        </>
                                                        :
                                                        <>
																													{inputFields.map((data, index) => {
																																const { id, name, code } = data
																																return (
																																		<>
																																				<div className='position-relative'>
																																						<Row>
																																								<Col lg="6" className="mb-3">
																																										<Input
																																												onChange={(evnt) => handleChangeCompetitors(index, evnt)}
																																												name='name'
																																												value={name}
																																												placeholder={`Competitor ` + [index + 1]}
																																												maxLength={255}
																																										/>
																																								</Col>
																																								<Col lg="6" className="mb-3">
																																										<Input
																																												onChange={(evnt) => handleChangeCompetitors(index, evnt)}
																																												name='code'
																																												value={code}
																																												placeholder={`Code ` + [index + 1]}
																																												maxLength={255}
																																										/>
																																								</Col>
																																						</Row>
																																				</div>
																																		</>
																																)
																														})}
                                                        </>

                                                    }
                                                </>
                                                :
                                                    null
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="mb-3">
                            <CardBody>
                                <Row>
                                    <Col lg="12">
                                        <Label className='list-head mb-0'>OPERATIONS</Label>
                                        <hr />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Office Address</Label>
                                        <Input type='text'  value={formData.office_address} maxLength={255} name='office_address' onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Warehouse Address</Label>
                                        <Input type='text' value={formData.warehouse_address} maxLength={255} name='warehouse_address' onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Number of Employees</Label>
                                        <Input type='number' value={formData.number_of_employees} maxLength={255} name='number_of_employees' onChange={handleChangeInt} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Customer Service Process</Label>
                                        <Input type='text' value={formData.customer_service_process} maxLength={255} name='customer_service_process' onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Return and Refund</Label>
                                        <Input type='text' value={formData.refund_process} maxLength={255} name='refund_process' onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>TAcos (%)</Label>
                                        <Input type='number' value={formData.ppc_revenue_cost} maxLength={255} name='ppc_revenue_cost' onChange={handleChangeDec} />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Acos (%)</Label>
                                        <Input type='number' value={formData.ppc_advertising_cost} rows="2" name='ppc_advertising_cost' onChange={handleChangeDec} />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Have there been any significant changes in the PPC advertising cost as a percentage of total revenue in the last 12 months?</Label>
                                        {/* <Input type='text' name='ppc_advertising_changes' onChange={handleChange} /> */}
                                        {formData.ppc_advertising_changes != "No" && formData.ppc_advertising_changes && formData.ppc_advertising_changes != "" ?
                                            <Input type='select' name="ppc_advertising_changes" onChange={handleChangePpcYesNo} >
                                                <option value=""></option>
                                                <option value="Yes" selected>Yes</option>
                                                <option value="No">No</option>
                                            </Input>
                                            :
                                            <Input type='select' name="ppc_advertising_changes" onChange={handleChangePpcYesNo} >
                                                <option value=""></option>
                                                <option value="Yes">Yes</option>
                                                <option value="No" selected>No</option>
                                            </Input>
                                        }
                                        {ppc ?
                                            <Input type='textarea' className="mt-3" maxLength={255} placeholder="Please specify the change" rows="2" value={formData.ppc_advertising_changes} name='ppc_advertising_changes' onChange={handleChangePpc} />
                                            :
                                            null
                                        }
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Top 5 ASINs Ranking</Label>
                                        <Input type='textarea' value={formData.asin_ranking} maxLength={255} rows="2" name='asin_ranking' onChange={handleChange} />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Marketing Agency</Label>
                                        <Input type='text' value={formData.marketing_agency} maxLength={255} name='marketing_agency' onChange={handleChange} />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Whether using Amazon video Ads, Amazon DSP, Facebook Ads, Google Ads, advertising network, etc.?</Label>
                                        {formData.advertising != "No" && formData.advertising && formData.advertising != "" ?
                                            <Input type='select' name="advertising" onChange={handleChangeAdvertisingYesNo} >
                                                <option value=""></option>
                                                <option value="Yes" selected>Yes</option>
                                                <option value="No">No</option>
                                            </Input>
                                            :
                                            <Input type='select' value={formData.advertising} name="advertising" onChange={handleChangeAdvertisingYesNo} >
                                                <option value=""></option>
                                                <option value="Yes">Yes</option>
                                                <option value="No" selected>No</option>
                                            </Input>
                                        }
                                        {advertising ?
                                            <Input type='textarea' rows="2" className="mt-3" maxLength={255} value={formData.advertising} placeholder="Please specify spending" name='advertising' onChange={handleChangeAdvertising} />
                                            :
                                            null
                                        }
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>The Proportion of Marketing Expenses</Label>
                                        <Input type='textarea' value={formData.marketing_expenses} maxLength={255} name='marketing_expenses' onChange={handleChange} />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Whether using or used any giveaways, rebate solicitations (including insert cards) or other tactics to improve product ratings?</Label>
                                        <Input type='textarea' value={formData.giveaways} maxLength={255} rows="2" name='giveaways' onChange={handleChange} />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Has the account ever been suspended, including misjudgments?</Label>
                                        {formData.account_violations != "No" && formData.account_violations && formData.account_violations != "" ?
                                            <Input type='select' name="account_violations" onChange={handleChangeViolationYesNo} >
                                                <option value=""></option>
                                                <option value="Yes" selected>Yes</option>
                                                <option value="No">No</option>
                                            </Input>
                                            :
                                            <Input type='select' name="account_violations" maxLength={255} onChange={handleChangeViolationYesNo} >
                                            <option value=""></option>
                                            <option value="Yes">Yes</option>
                                            <option value="No" selected>No</option>
                                        </Input>
                                        }

                                        {accountViolation ?
                                            <Input type='textarea' value={formData.account_violations} className="mt-3" maxLength={255} placeholder='Please provide the information of when and why' rows="2" name='account_violations' onChange={handleChangeViolation} />
                                            :
                                            null
                                        }
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Has any ASIN ever been removed or received warnings?</Label>
                                        {formData.account_violations != "No" && formData.account_violations && formData.account_violations != "" ?
                                            <Input type='select' name="asin_violations" onChange={handleChangeAsinYesNo} >
                                                <option value=""></option>
                                                <option value="Yes" selected>Yes</option>
                                                <option value="No">No</option>
                                            </Input>
                                            :
                                            <Input type='select' name="asin_violations" onChange={handleChangeAsinYesNo} >
                                                <option value=""></option>
                                                <option value="Yes">Yes</option>
                                                <option value="No" selected>No</option>
                                            </Input>
                                        }

                                        {asinViolation ?
                                            <Input type='textarea' maxLength={255} value={formData.account_violations} className="mt-3" placeholder="Please provide the information of when and why" rows="2" name='asin_violations' onChange={handleChangeAsin} />
                                            :
                                            null
                                        }

                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Five future growth opportunities for the store, e.g. new product launches, advertising strategy optimization, new markets, etc.</Label>
                                        <Input type='textarea' rows="2" maxLength={255} name='growth_opportunities' onChange={handleChange} />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>If there is no such acquisition opportunity, how do you plan to develop the business?</Label>
                                        <Input type='textarea' rows="2" maxLength={255} name='development_plan' onChange={handleChange} />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="mb-3">
                            <CardBody>
                                <Row>
                                    <Col lg="12">
                                        <Label className='list-head mb-0'>SUPPLY CHAIN</Label>
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <Label>The types of top 5 suppliers (factory, wholesaler or trader)</Label>
                                        {/* {supplierFields.map((data, index) => {
                                                const { id, name } = data
                                                return (
                                                    <>
                                                        <Col lg="6" className="mb-3" key={index}>
                                                            <Input
                                                                onChange={(evnt) => handleChangeSuppliers(index, evnt)}
                                                                name='name'
                                                                value={name}
                                                                placeholder={`Supplier ` + [index + 1]}
                                                            />
                                                        </Col>
                                                    </>
                                                )
                                            })} */}
                                        <Input type='textarea' rows="2" className="mb-3" maxLength={255} value={formData.suppliers} name='suppliers' onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='12' className="mb-3">
                                        <Label>The locations of top 5 suppliers</Label>
                                        <Input type='textarea' value={formData.supplier_location} rows="2"  maxLength={255} name='supplier_location' onChange={handleChange} />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>The proportion of purchase amount of top 5 suppliers in the last 12 months</Label>
                                        <Input type='textarea' value={formData.supplier_proportion} rows="2" maxLength={255} name='supplier_proportion' onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>The account period of top 5 suppliers</Label>
                                        <Input type='text' value={formData.supplier_account_period} maxLength={255} name='supplier_account_period' onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>The length of cooperation of the top 5 suppliers</Label>
                                        <Input type='text' value={formData.supplier_length_of_cooperation} maxLength={255} name='supplier_length_of_cooperation' onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Whether there are contracts with the top 5 suppliers?</Label>
                                        {/* <Input type='text' name='supplier_contracts' onChange={handleChange} /> */}
                                        <Input type='select' value={formData.supplier_contracts} name="supplier_contracts" maxLength={255} onChange={handleChange} >
                                            <option value=""></option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </Input>
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>The minimum order requirement of top 5 suppliers</Label>
                                        <Input type='text' value={formData.supplier_minimum_order} name='supplier_minimum_order' maxLength={255} onChange={handleChange} />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Whether the top 5 suppliers include companies in which you own shares or control?</Label>
                                        {/* <Input type='textarea' rows="2" name='supplier_share_control' onChange={handleChange} /> */}
                                        <Input type='select' value={formData.supplier_share_control} name="supplier_share_control" maxLength={255} onChange={handleChange} >
                                            <option value=""></option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </Input>
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Whether the top 5 suppliers offer exclusive contracted development of products?</Label>
                                        {formData.supplier_exclusive_contract != "No" && formData.supplier_exclusive_contract && formData.supplier_exclusive_contract != "" ?
                                            <Input type='select' name="supplier_exclusive_contract"  onChange={handleChangeExclusiveYesNo} >
                                                <option value=""></option>
                                                <option value="Yes" selected>Yes</option>
                                                <option value="No">No</option>
                                            </Input>
                                            :
                                            <Input type='select' name="supplier_exclusive_contract" onChange={handleChangeExclusiveYesNo} >
                                                <option value=""></option>
                                                <option value="Yes">Yes</option>
                                                <option value="No" selected>No</option>
                                            </Input>
                                        }
                                        {exclusive && (formData.supplier_exclusive_contract != "No" && formData.supplier_exclusive_contract != "") ?
                                            <Input type='textarea' rows="2" className="mt-3" maxLength={255} value={formData.supplier_exclusive_contract} placeholder="Please provide the detail information" name='supplier_exclusive_contract' onChange={handleChangeExclusive} />
                                            :
                                            null
                                        }

                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Supplier Selection Criteria</Label>
                                        <Input type='textarea' rows="2" value={formData.supplier_selection_criteria} maxLength={255} name='supplier_selection_criteria' onChange={handleChange} />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>How do you process quality control?</Label>
                                        <Input type='textarea' rows="2" value={formData.quality_control} maxLength={255}  name='quality_control' onChange={handleChange} />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Did stockout occur frequently?</Label>
                                        {/* <Input type='textarea' rows="2" value={formData.stock_out_occurence} name='stock_out_occurence' onChange={handleChange} /> */}
                                        {formData.stock_out_occurence != "No" && formData.stock_out_occurence && formData.stock_out_occurence != "" ?
                                            <Input type='select' name="stock_out_occurence" onChange={handleChangeStockoutYesNo} >
                                                <option value=""></option>
                                                <option value="Yes" selected>Yes</option>
                                                <option value="No">No</option>
                                            </Input>
                                            :
                                            <Input type='select' name="stock_out_occurence" onChange={handleChangeStockoutYesNo} >
                                                <option value=""></option>
                                                <option value="Yes">Yes</option>
                                                <option value="No" selected>No</option>
                                            </Input>
                                        }
                                        {stockout ?
                                            <Input type='textarea' rows="2" className="mt-3" value={formData.stock_out_occurence} maxLength={255} placeholder="Please specify under what circumstances will there be out of stock" name='stock_out_occurence' onChange={handleChangeStockout} />
                                            :
                                            null
                                        }
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>The reasons and approximate dates of stockout in the last two years</Label>
                                        <Input type='textarea' rows="2" value={formData.reason_for_stockout} maxLength={255}  name='reason_for_stockout' onChange={handleChange} />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>The IPI (Inventory Performance Index) score of your Amazon stores</Label>
                                        <Input type='text' value={formData.ipi_score} maxLength={255}  name='ipi_score' onChange={handleChange} />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="mb-3">
                            <CardBody>
                                <Row>
                                    <Col lg="12">
                                        <Label className='list-head mb-0'>LOGISTICS</Label>
                                        <hr />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Top 5 Logistic Providers Cost in the Last 12 Months</Label>
                                        <Input type='text' value={formData.logistic_providers} maxLength={255} name='logistic_providers' onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Sea and Air Freight Proportion in the Last 12 Months</Label>
                                        <Input type='text' value={formData.sea_air_freight_proportion} maxLength={255} name='sea_air_freight_proportion' onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Domestic Warehouse Third Party</Label>
                                        <Input type='text' value={formData.domestic_warehouse_3rd_party} maxLength={255} name='domestic_warehouse_3rd_party' onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Overseas Warehouse Third Party</Label>
                                        <Input type='text' value={formData.overseas_warehouse_3rd_party} maxLength={255} name='overseas_warehouse_3rd_party' onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Proportion of Goods</Label>
                                        <Input type='text' value={formData.proportion_of_goods} maxLength={255} name='proportion_of_goods' onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Proportion of FBM</Label>
                                        <Input type='text' value={formData.proportion_of_fbm} maxLength={255}  name='proportion_of_fbm' onChange={handleChange} />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Is there any FBM?</Label>
                                        {/* <Input type='text' name='fbm' onChange={handleChange} /> */}
                                        {formData.fbm != "No" && formData.fbm && formData.fbm != "" ?
                                            <Input type='select' name="fbm" onChange={handleChangeFbmYesNo} >
                                                <option value=""></option>
                                                <option value="Yes" selected>Yes</option>
                                                <option value="No">No</option>
                                            </Input>
                                            :
                                            <Input type='select' name="fbm" onChange={handleChangeFbmYesNo} >
                                                <option value=""></option>
                                                <option value="Yes">Yes</option>
                                                <option value="No" selected>No</option>
                                            </Input>
                                        }
                                        {fbm ?
                                            <Input type='textarea' value={formData.fbm} rows="2" maxLength={255} placeholder="Please provide the approximate proportion" className="mt-3" name='fbm' onChange={handleChangeFbm} />
                                            :
                                            null
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg="12">
                                        <Label className='list-head mb-0'>ACQUISITION</Label>
                                        <hr />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Reason for selling the business</Label>
                                        <Input type='text' value={formData.reason_for_sale} maxLength={255} name='reason_for_sale' onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Most valued transaction process</Label>
                                        <Input type='text' value={formData.valued_transaction_process} maxLength={255} name='valued_transaction_process' onChange={handleChange} />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Asking Price</Label>
                                        <Input type='text' value={formData.asking_price} maxLength={255} name='asking_price' onChange={handleChangeInt} step="0.01" />
                                    </Col>
                                    <Col lg='6' className="mb-3">
                                        <Label>Expected Deal Closure</Label>
                                        <Input type='text' value={formData.deal_closure} maxLength={255}  name='deal_closure' onChange={handleChange} />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>What is the proportion of upfront payment you expect?</Label>
                                        <Input type='text' value={formData.upfront_payment} maxLength={255} name='upfront_payment' onChange={handleChange} />
                                    </Col>
                                    <Col lg='12' className="mb-3">
                                        <Label>Would you provide VPS login access, Sellerboard login access or Seller Central view permissions?</Label>
                                        {/* <Input type='text' name='vps_login_access' onChange={handleChange} /> */}
                                        <Input type='select' value={formData.vps_login_access} maxLength={255} name="vps_login_access" onChange={handleChange} >
                                            <option value=""></option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Col lg='12' className='py-3 text-end'>
                            {formData.status == "Draft" ?
                                <>
                                    {draftFormStatus != 'standby' ?
                                        <button className='button me-3' id="update-pass-btn" onClick={preventDefault}>UPDATING...</button>
                                        :
                                        <button className='button me-3 view-button' id="update-pass-btn" onClick={draftUpdateSubmit}>UPDATE DRAFT</button>
                                    }
                                     {formStatus != 'standby' ?
                                        <button className='button' id="update-pass-btn" onClick={preventDefault}>PUBLISHING...</button>
                                        :
                                        <button className='button view-button' id="update-pass-btn" onClick={submitItem}>PUBLISH</button>
                                    }
                                </>
                            :
                                <>
                                    {formStatus != 'standby' ?
                                        <button className='button' id="update-pass-btn" onClick={preventDefault}>UPDATING...</button>
                                        :
                                        <button className='button view-button' id="update-pass-btn" onClick={submitItem}>UPDATE</button>
                                    }
                                </>
                            }
                        </Col>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

export default AdminMarketPlaceEditPage;
