import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory, useNavigate } from 'react-router-dom'
import EmptyLayout from 'layout/EmptyLayout';
import {
    Container, Button, CardFooter, Input, CardBody, Label, ModalHeader, ModalBody, ModalFooter, Card, Col, Modal, Table, Row, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,

} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsSearch, BsTrash } from 'react-icons/bs';
import { FiFilter } from 'react-icons/fi';
import { BiSort } from 'react-icons/bi';
import { AiOutlinePlus } from 'react-icons/ai'
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'
import Sport from '../assets/Sports.png';
import home from '../assets/home-hobbies-img.png';
import excercise from '../assets/excercise.png';
import Sidebar from 'components/Sidebar';
import { Spinner } from 'reactstrap';
import PageSpinner from 'components/PageSpinner';
import axios from 'axios';
import NoImageAvail from '../assets/no-image.png';
import ProfileImage from "../assets/profile_placeholder.png";
import { BsThreeDotsVertical, BsFillPencilFill } from 'react-icons/bs';
import { GoDeviceDesktop } from 'react-icons/go';
import { GrEdit } from 'react-icons/gr';
import { FaArrowLeft } from 'react-icons/fa';
import { useCookies } from 'react-cookie';
import { HiArrowLeft } from 'react-icons/hi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';

const initialUserData = Object.freeze({
    first_name: '',
    last_name: '',
    company_name: '',
    company_position: '',
    contact_phone: '',
    company_email: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    province: '',
    company_industry: '',
    company_phone: '',
    email: '',
    image_url: ''
});

const initialChangePass = Object.freeze({
    current_password: '',
    new_password: '',
    confirm_new_password: '',
});

const initialFormData = Object.freeze({
    'ltm_gmv_from': '',
    'ltm_gmv_to': '',
    'ebitda_from': '',
    'ebitda_to': '',
    'ebitda_margin_from': '',
    'yoy_growth_from': '',
});

function AdminUserProfilePage(props) {
    // page content
    const navigate = useNavigate();
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const user_id = query.get('id');
    const initialTagIds = [1,2,3];
    const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn', 'userRole', 'awardStatus', 'token']);
    const currentUser = cookies.currentUser;
    const [user, setUser] = useState([]);
    const [userLoading, setUserLoading] = useState(true);
    const [imgFile, setImgFile] = useState("");
    const [profileData, setProfileData] = useState(initialUserData);
    const [formStatus, setFormStatus] = useState("standby");
    const [activeTab, setActivetab] = useState(1);
    const [changePass, setChangePass] = useState(initialChangePass);
    const [isSidebar, setIsSidebar] = useState(false);
    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
    })
    const [tags, setTags] = useState([]);
    const [formData, setFormData] = useState(initialFormData);
    const [tagsData, setTagsData] = useState([]);
    const [tagsDataLength, setTagsDataLength] = useState(0);
    const [error, setError] = useState('');
    const [tagIds, setTagIds] = useState([]);
    const [tagsBoxStyle, setTagsBoxStyle] = useState('card-show-tags-hidden');
    const [tagsListShow, setTagsListShow] = useState(false);
    const [tagsReady, setTagsReady] = useState(false);

    function getSidebar() {
        setIsSidebar(!isSidebar);
    }

    function handleKeyDown(e) {
        if (e.key !== 'Enter') return
        const value = e.target.value
        if (!value.trim()) return
        if (tags) {
            if (tags.length > 0) {
                setTags([...tags, value]);
            } else {
                setTags([value])
            }
        } else {
            setTags([value]);
        }
        e.target.value = ''
    }

    function removeTag(index) {
        setTags(tags.filter((el, i) => i !== index))
    }

    // Update Profile
    const [updateProfileShow, setUpdateProfileShow] = useState(false);


    const toggleUpdateProfileShow = () => {
        setUpdateProfileShow(!updateProfileShow);
    }

    const preventDefault = (e) => {
        e.preventDefault();
    }

    const handleActiveTab = (e) => {
        setActivetab(e)
    }

    const handleChangePass = (e) => {
        setChangePass({
            ...changePass,
            [e.target.name]: e.target.value
        })

    }
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
       
        // setImageUpload(e.target.files[0]);

    };
    const handleChangeCheck = (id) => {
        setTagIds([...tagIds, id]);
        console.log('tagarray id', tagIds);
    }

    const handleChangeUnchecked = (id) => {
        let filterId = tagIds.filter(item => item !== id);
        // console.log('removeID', filteredArray)
        setTagIds(filterId)

        console.log("tag IDs uncheck", filterId);
    }

    const toggleHideTags = () => {
        setTagsListShow(false);
        setTagsBoxStyle('card-show-tags-hidden');
    }

    const toggleShowTags = () => {
        setTagsListShow(true);
        setTagsBoxStyle('card-show-tags-show');
    }

    const submitItem = () => {
        setFormStatus('loading');
        axios({
            method: 'put',
            url: process.env.REACT_APP_API_ENDPOINT + 'profile/' + currentUser + '/update',
            data: ({
                tags: tagIds,
                keywords: tags,
                ltm_gmv_from: formData.ltm_gmv_from,
                ltm_gmv_to: formData.ltm_gmv_to,
                ebitda_from: formData.ebitda_from,
                ebitda_to: formData.ebitda_to,
                ebitda_margin_from: formData.ebitda_margin_from,
                yoy_growth_from: formData.yoy_growth_from,
            })
        }).then(function (response) {
            if (response.data.status == 'Success') {
                toast.success('Notifications updated successfully!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setFormStatus('standby');
                // navigate('/');
            } else {
                toast.error('Notifications update failed!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setFormStatus('standby');
            }
        }).catch(function (error) {
            // setError(error);
            toast.error('An error occured. Please try again or contact the administrator.', {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setFormStatus('standby');
        })

    };

    function getCurrentUser() {
        if (currentUser) {
            axios.get(process.env.REACT_APP_API_ENDPOINT + 'user/' + currentUser)
                // axios.get('https://bis.jenocabrera.online/api/user/'+user_id)
                .then((response) => {
                    const data = response.data;
                    const result = data.data;
                    console.log("result data", result);
                    if (result) {
                        setUserLoading(false);
                        setUser(result);
                        setImgFile(result.image_url);
                        setFormData({
                            ...formData,
                            ltm_gmv_from: result.ltm_gmv_from,
                            ltm_gmv_to: result.ltm_gmv_to,
                            ebitda_from: result.ebitda_from,
                            ebitda_to: result.ebitda_to,
                            ebitda_margin_from: result.ebitda_margin_from,
                            yoy_growth_from: result.yoy_growth_from,
                            ltm_ebitda: result.ltm_ebitda,
                            // keywords: result.tags,
                            // category_ids: result.category_ids
                        });
                        if(result.tags) {
                            setTagIds(result.tags);
                        } else {
                            setTagIds([...tagIds, result.tags]);
                        }
                        
                        setTags(result.keywords);
                    }
                }).catch(error => {
                    // setError(error);
                    toast.error('An error occured. Please try again or contact the administrator.', {
                        position: 'top-right',
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setUserLoading(false);
                });
        }
    }

    function getTags() {
        if (tagsDataLength < 1) {
            axios.get(process.env.REACT_APP_API_ENDPOINT + 'tag')
                .then((response) => {
                    const data = response.data;
                    const result = data.data;

                    if (result) {
                        setTagsData(result);
                        setTagsDataLength(result.length);
                        setTagsReady(true);
                    } else {
                        setTagsReady(false);
                    }
                }).catch(error => {
                    // setError(error);
                    toast.error('An error occured. Please try again or contact the administrator.', {
                        position: 'top-right',
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTagsReady(false);
                });


        }
    }

    useEffect(() => {
        getTags();
        getCurrentUser();
    }, []);

    return (
        <>
            <ToastContainer style={{ width: "370px" }} />
            <section id='user-profile'>
                <Container fluid>
                    <Row>
                        <Col lg='2'>
                            {/* <Sidebar /> */}
                            {isSidebar || windowDimension.winWidth > 1024 ?
                                <Sidebar clickSidebar={getSidebar} />
                                :
                                null
                            }
                            <button onClick={getSidebar} className={isSidebar ? "hidden" : "hamburger-btn show"}><GiHamburgerMenu /></button>
                        </Col>
                        <Col lg='10' className='right-container'>
                            <div className="page-container">
                                <div>
                                    <div className="mobile-display">
                                        {activeTab == 1 ?
                                            <div>
                                                <h2 className="ss-header mb-0">Profile</h2>
                                                <div className="divider mb-0"></div>
                                            </div>
                                            : activeTab == 2 ?
                                                <div>
                                                    <h2 className="ss-header mb-0">Security</h2>
                                                    <div className="divider mb-0"></div>
                                                </div>
                                                :
                                                <div>
                                                    <h2 className="ss-header mb-0">Notifications</h2>
                                                    <div className="divider mb-0"></div>
                                                </div>
                                        }
                                        {/* <button className='button back view-button mb-3' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button> */}
                                    </div>
                                    <Row>
                                        <Col lg={3}>
                                            <Card className='mb-3'>
                                                <CardBody>
                                                    {imgFile ?
                                                        // <div className='profile-picture-container' style={{ backgroundImage: "url(" + process.env.REACT_APP_URL + "/storage/gallery/"+imgFile+ ")" }}></div>
                                                        <div className='profile-picture-container' style={{ backgroundImage: "url(https://bis.jenocabrera.online/storage/gallery/" + imgFile + ")" }}></div>
                                                        :
                                                        <div className='profile-picture-container' style={{ backgroundImage: "url(" + ProfileImage + ")" }}></div>
                                                    }
                                                    <h3 className="ss-subheader text-center mb-0 mt-3">{user.first_name} {user.last_name}</h3>
                                                </CardBody>
                                            </Card>
                                            <Card className='mb-3'>
                                                <CardBody>
                                                    <button type="button" className="button profile-button mb-3" onClick={function () { setActivetab(1); }}>Profile</button>
                                                    <button type="button" className="button profile-button mb-3" onClick={function () { setActivetab(2); }}>Security</button>
                                                    <button type="button" className="button profile-button" onClick={function () { setActivetab(3); }}>Notifications</button>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={9}>
                                            <div className="desktop-display">
                                                <Row>
                                                    <Col lg="9" className="mb-0">
                                                        {activeTab == 1 ?
                                                            <h2 className="ss-header mb-0">Profile</h2>
                                                            : activeTab == 2 ?
                                                                <h2 className="ss-header mb-0">Security</h2>
                                                                :
                                                                <h2 className="ss-header mb-0">Notifications</h2>
                                                        }
                                                        <div className="divider"></div>
                                                    </Col>
                                                    <Col lg="3" className="mb-0 text-right">
                                                        {activeTab == 1 ?
                                                            <button className='button back view-button profile-button-mobile' onClick={() => navigate('/profile/update?user_id=' + currentUser)}><BsFillPencilFill className='btn-icon me-3' />EDIT</button>
                                                            :
                                                            null
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                            {activeTab == 1 ?
                                                <>
                                                    {!userLoading ?
                                                        <>
                                                            <Card className="preview_content">
                                                                <CardBody>
                                                                    <h3 className="ss-subheader">PERSONAL DETAILS</h3>
                                                                    <hr />
                                                                    <Row>
                                                                        <Col lg="6" className="mb-0">
                                                                            <p className="ss_col_title">PHONE NUMBER:</p>
                                                                            <p className="ss_entry_content">
                                                                                {user.contact_phone != "" ?
                                                                                    <>
                                                                                        {user.contact_phone}
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        -
                                                                                    </>
                                                                                }
                                                                            </p>
                                                                            <hr />
                                                                        </Col>
                                                                        <Col lg="6" className="mb-0">
                                                                            <p className="ss_col_title">EMAIL ADDRESS:</p>
                                                                            <p className="ss_entry_content">
                                                                                {user.email != "" ?
                                                                                    <>
                                                                                        {user.email}
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        -
                                                                                    </>
                                                                                }
                                                                            </p>
                                                                            <hr />
                                                                        </Col>
                                                                        <Col lg="6" className="mb-0">
                                                                            <p className="ss_col_title">ADDRESS:</p>
                                                                            <p className="ss_entry_content">
                                                                                {user.address_line_1 != "" && user.city != "" && user.province ?
                                                                                    null
                                                                                    :
                                                                                    <>
                                                                                        -
                                                                                    </>
                                                                                }
                                                                                {user.address_line_1}
                                                                                {user.city != "" ?
                                                                                    <>
                                                                                        , {user.city}
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                {user.province != "" ?
                                                                                    <>
                                                                                        , {user.province}
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </p>
                                                                        </Col>
                                                                        {/* <Col lg="6" className="mb-0">
                                                                <p className="ss_col_title">ADDRESS LINE 2:</p>
                                                                <p className="ss_entry_content">{user.address_line_2}, {user.city}, {user.province}</p>
                                                            </Col> */}
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                            <Card className="preview_content mt-3">
                                                                <CardBody>
                                                                    <h3 className="ss-subheader">COMPANY DETAILS</h3>
                                                                    <hr />
                                                                    <Row>
                                                                        <Col lg="6" className="mb-0">
                                                                            <p className="ss_col_title">COMPANY</p>
                                                                            <p className="ss_entry_content">{user.company_name ? user.company_name : "-"}</p>
                                                                            <hr />
                                                                        </Col>
                                                                        <Col lg="6" className="mb-0">
                                                                            <p className="ss_col_title">POSITION</p>
                                                                            <p className="ss_entry_content">{user.company_position ? user.company_position : "-"}</p>
                                                                            <hr />
                                                                        </Col>
                                                                        <Col lg="6" className="mb-0">
                                                                            <p className="ss_col_title">INDUSTRY</p>
                                                                            <p className="ss_entry_content">{user.company_industry ? user.company_industry : "-"}</p>
                                                                            <hr />
                                                                        </Col>
                                                                        <Col lg="6" className="mb-0">
                                                                            <p className="ss_col_title">COMPANY EMAIL</p>
                                                                            <p className="ss_entry_content">{user.company_email ? user.company_email : "-"}</p>
                                                                            <hr />
                                                                        </Col>
                                                                        <Col lg="6" className="mb-0">
                                                                            <p className="ss_col_title">COMPANY PHONE</p>
                                                                            <p className="ss_entry_content">{user.company_phone ? user.company_phone : "-"}</p>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </>
                                                        :
                                                        <Card>
                                                            <CardBody>
                                                                <div className='text-center'><PageSpinner /></div>
                                                            </CardBody>
                                                        </Card>
                                                    }

                                                </>
                                                : activeTab == 2 ?
                                                    <>
                                                        <Card className='change-pass'>
                                                            <CardBody>
                                                                <div className='login-inner'>
                                                                    <p className='ss-subheader'>CHANGE PASSWORD</p>
                                                                    <hr></hr>
                                                                    <Label>Current password</Label>
                                                                    <Input name="current_password" className="mb-3" type="password" onChange={handleChangePass} />
                                                                    <Label>New password</Label>
                                                                    <Input name="new_password" type="password" className="input-password mb-3" onChange={handleChangePass} />
                                                                    <Label>Confirm new password</Label>
                                                                    <Input name="confirm_new_password" type="password" className="input-password" onChange={handleChangePass} />
                                                                    <Row>
                                                                        <Col lg="12" className='text-right'>
                                                                            <button className='button update-pass-btn mt-3' id="update-pass-btn">Update</button>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </>
                                                    :
                                                    <>
                                                        <Card className='mb-3'>
                                                            <CardBody>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <div className="data-container">
                                                                            <Row>
                                                                                <Col lg={8}>
                                                                                    <div className="data-value">
                                                                                        <p className='text-left fw-bold'>LTM GMV</p>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <div className="d-flex">
                                                                                        <Input type='number' className='me-3' placeholder='From' name='ltm_gmv_from' value={formData.ltm_gmv_from} onChange={handleChange} />
                                                                                        <Input type='number' className='me-3' placeholder='To' name='ltm_gmv_to' value={formData.ltm_gmv_to} onChange={handleChange} />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                        <Card className='mb-3'>
                                                            <CardBody>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <div className="data-container">
                                                                            <Row>
                                                                                <Col lg={8}>
                                                                                    <div className="data-value">
                                                                                        <p className='text-left fw-bold'>EBITDA</p>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <div className="d-flex">

                                                                                        <Input className='me-3' type='number' placeholder='From' name='ebitda_from' value={formData.ebitda_from} onChange={handleChange} />

                                                                                        <Input className='me-3' type='number' placeholder='To' name='ebitda_to' value={formData.ebitda_to} onChange={handleChange} />
                                                                                    </div>

                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                        <Card className='mb-3'>
                                                            <CardBody>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <Row>
                                                                            <Col lg={8}>
                                                                                <div className="data-value">
                                                                                    <p className='text-left fw-bold'> EBITDA Margin</p>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={4}>
                                                                                <div className="d-flex">
                                                                                    <Input type='number' className='me-3' placeholder='From' name='ebitda_margin_from' value={formData.ebitda_margin_from} onChange={handleChange} />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>

                                                            </CardBody>
                                                        </Card>
                                                        <Card className='mb-3'>
                                                            <CardBody>
                                                                <Row>
                                                                    <Col lg={8}>
                                                                        <div className="data-value">
                                                                            <p className="text-left fw-bold">Year on Year Growth</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        <div className="d-flex">
                                                                            <Input className='me-3' type='number' placeholder='From (Number of Years)' name='yoy_growth_from' value={formData.yoy_growth_from} onChange={handleChange} />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    
                                                        <Row>
                                                            <Col lg={6}>
                                                                <Card className='mb-3'>
                                                                    <CardBody className='mb-6'>
                                                                        <div className="data-value">
                                                                            <p className="text-left fw-bold">Keywords</p>
                                                                            <div className="data-value d-flex">
                                                                                {/* <p>Sports</p> */}
                                                                                <div className="tags-input-container">
                                                                                    {tags ?
                                                                                        <>
                                                                                            {tags.length > 0 ?
                                                                                                <>
                                                                                                    {tags.map((tag, index) => (
                                                                                                        <div className="tag-item" key={index}>
                                                                                                            <span className="text">{tag}</span>
                                                                                                            <span className="close" onClick={() => removeTag(index)}>&times;</span>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </>
                                                                                                :
                                                                                                // <span className="text">No keywords found</span>
                                                                                                null
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        // <span className="text">No keywords found</span>
                                                                                        null
                                                                                    }
                                                                                    <input type="text" onKeyDown={handleKeyDown} className="tags-input" placeholder="Type keywords here..." />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Card>
                                                                    <CardBody className='mb-6'>
                                                                        <div className="data-container">
                                                                            <p className='text-left fw-bold'>Categories</p>
                                                                            <div className="data-value d-flex">
                                                                                {tagsListShow ?
                                                                                    <button type="button" className="button-tags" onClick={toggleHideTags}>
                                                                                        <div className='d-flex justify-content-between align-items-center'>
                                                                                            <span className="">
                                                                                                {tagsData ?
                                                                                                    <>
                                                                                                        {tagsData.length > 0 ?
                                                                                                            <>
                                                                                                                {tagsData.map(({ id, name }, index) => (
                                                                                                                    <>
                                                                                                                        {tagIds.indexOf(id) > -1 ?
                                                                                                                            <>
                                                                                                                                {name}{index+1 < tagsData.length ? ", ": null}
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            null
                                                                                                                        }
                                                                                                                    </>
                                                                                                                ))}
                                                                                                            </>
                                                                                                        :
                                                                                                        null
                                                                                                        }
                                                                                                    </>
                                                                                                :
                                                                                                    null
                                                                                                }
                                                                                                {tagIds.length > 0 ?
                                                                                                    null
                                                                                                    :
                                                                                                    <>Choose categories here...</>
                                                                                                }
                                                                                            </span>
                                                                                            <IoIosArrowUp />
                                                                                        </div>
                                                                                    </button>
                                                                                    :
                                                                                    <button type="button" className="button-tags" onClick={toggleShowTags}>
                                                                                        <div className='d-flex justify-content-between align-items-center'>
                                                                                            <span className="">
                                                                                                {tagsData ?
                                                                                                    <>
                                                                                                        {tagsData.length > 0 ?
                                                                                                        <>
                                                                                                            {tagsData.map(({ id, name }, index) => (
                                                                                                                <>
                                                                                                                    {tagIds.indexOf(id) > -1 ?
                                                                                                                        <> 
                                                                                                                            {name}{index+1 < tagsData.length ? ", ": null}
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        null
                                                                                                                    }
                                                                                                                </>
                                                                                                            ))}
                                                                                                        </>
                                                                                                        :
                                                                                                        null
                                                                                                        }
                                                                                                    </>
                                                                                                :
                                                                                                    null
                                                                                                }
                                                                                                {tagIds.length > 0 ?
                                                                                                    null
                                                                                                    :
                                                                                                    <>Choose categories here...</>
                                                                                                }
                                                                                            </span>
                                                                                            <IoIosArrowDown />
                                                                                        </div>
                                                                                    </button>
                                                                                }
                                                                            </div>
                                                                            <Card className={tagsBoxStyle}>
                                                                                <CardBody>
                                                                                    <Row>
                                                                                        {tagsData ?
                                                                                            <>
                                                                                                {tagsData.length > 0 ?
                                                                                                    <>
                                                                                                        {tagsData.map(({ id, name }, index) => (
                                                                                                            <Col lg="12" key={index}>
                                                                                                                {tagIds.indexOf(id) > -1 ?
                                                                                                                    <p className='mb-0'><input type="checkbox" className='me-2' value={id} onChange={function (event) { handleChangeUnchecked(id); }} defaultChecked/>{name}</p>
                                                                                                                    :
                                                                                                                    <p className='mb-0'><input type="checkbox" className='me-2' value={id} onChange={function (event) { handleChangeCheck(id); }} />{name}</p>
                                                                                                                }
                                                                                                            </Col>
                                                                                                        ))}
                                                                                                    </>
                                                                                                    :
                                                                                                    <div className='text-center'><PageSpinner /></div>
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            <p>No tags available!</p>
                                                                                        }
                                                                                    </Row>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                        <Col lg='12' className='py-3 text-end'>
                                                            {formStatus != 'standby' ?
                                                                <button className='button' id="update-pass-btn" onClick={preventDefault}>SUBMITTING...</button>
                                                                :
                                                                <button className='button view-button' id="update-pass-btn" onClick={submitItem}>SUBMIT</button>
                                                            }
                                                        </Col>
                                                    </>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Update Profile */}
            <Modal
                isOpen={updateProfileShow}
                centered
                fade={false}
            // id="modal"
            >
                <button type="button" className="react-modal-close" data-dismiss="modal" aria-label="Close" onClick={toggleUpdateProfileShow}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <ModalHeader>
                    <strong>NEW TAG</strong>
                </ModalHeader>
                <ModalBody>

                </ModalBody>
                <ModalFooter className="text-end">
                    <button className='button view-button-close me-2' onClick={toggleUpdateProfileShow}>CANCEL</button>
                    {formStatus != "standby" ?
                        <button className='button view-button' onClick={preventDefault}>SENDING...</button>
                        :
                        <button className='button view-button' onClick={preventDefault}>SUBMIT</button>
                    }

                </ModalFooter>
            </Modal>
        </>
    )
}

export default AdminUserProfilePage;