import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory, useNavigate } from 'react-router-dom'
import EmptyLayout from 'layout/EmptyLayout';
import {
    Container, Button, CardFooter, Input, CardBody, Label, ModalHeader, ModalBody, ModalFooter, Card, Col, Modal, Table, Row, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,

} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from 'components/Sidebar';
import { Spinner } from 'reactstrap';
import PageSpinner from 'components/PageSpinner';
import axios from 'axios';
import NoImageAvail from '../assets/no-image.png';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GoDeviceDesktop } from 'react-icons/go';
import { GrEdit } from 'react-icons/gr';
import ProfileImage from "../assets/profile_placeholder.png";
import { AiFillLock, AiFillUnlock } from 'react-icons/ai';
import { GiHamburgerMenu } from 'react-icons/gi';
import { HiArrowLeft } from 'react-icons/hi';
import { BiCalendarAlt } from 'react-icons/bi';
import { FaUser } from 'react-icons/fa';

function AdminInquiriesPage(props) {
    const [inquiries, setInquiries] = useState([]);
    const [error, setError] = useState('');
    const [inquiriesLoading, setInquiriesLoading] = useState(true);
    const [reloadCount, setReloadCount] = useState(1);
    const [isSidebar, setIsSidebar] = useState(false);
    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
      })

      function getSidebar() {
        setIsSidebar(!isSidebar);
      }

    // page content
    const navigate = useNavigate();

    const unlockStatus = (e) => {
        axios.put(process.env.REACT_APP_API_ENDPOINT + 'inquiry/'+e, 
            {
                status: "Unlocked",
            }
        )
        .then(({ data }) => {
            // do something with success
            const json = data;
            if (json.status == "Success") {
                // Success
                toast.success('View listing unlocked successfully!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setReloadCount(reloadCount + 1);
            } else {
                // Failed
                toast.error('View listing unlock failed, please try again!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch((err) => {
            // Catch Server error
            // Alert server error here
            toast.error('An error occured. Please try again or contact the administrator.', {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    const lockStatus = (e) => {
        axios.put(process.env.REACT_APP_API_ENDPOINT + 'inquiry/'+e, 
            {
                status: "Locked",
            }
        )
        .then(({ data }) => {
            // do something with success
            const json = data;
            if (json.status == "Success") {
                // Success
                toast.success('View listing locked successfully!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setReloadCount(reloadCount + 1);
            } else {
                // Failed
                toast.error('View listing lock failed, please try again!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch((err) => {
            // Catch Server error
            // Alert server error here
            toast.error('View listing lock failed, please try again!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    useEffect(() => {
        // This is the axios GET

        axios.get(process.env.REACT_APP_API_ENDPOINT + 'inquiry')
            .then((response) => {
                const data = response.data;
                const result = data.data;
                console.log("Results Data:", result);
                if (result) {
                    setInquiriesLoading(false);
                    setInquiries(result);
                }
            }).catch(error => {
                toast.error('There has been an error with the server, please try again later!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }, [reloadCount]);

    return (
        <section id='marketing'>
            <ToastContainer style={{ width: "370px" }} />
            <Container fluid>
                <Row>
                    <Col lg='2'>
                    {isSidebar || windowDimension.winWidth > 1024 ?
                        <Sidebar clickSidebar={getSidebar}/>
                        :
                        null
                    }
                    <button onClick={getSidebar} className={isSidebar ? "hidden" : "hamburger-btn show"}><GiHamburgerMenu /></button>
                        {/* <Sidebar /> */}
                    </Col>
                    <Col lg='10' className='right-container'>
                        <div className="pb-5">
                            <div className="mobile-display">
                                <div>
                                    <h2 className="ss-header mb-0">Requests</h2>
                                    <div className="divider mb-0"></div>
                                </div>
                                <div>
                                    {/* <button className='button back view-button' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button> */}
                                </div>
                            </div>
                            <div className="desktop-display">
                                <Row>
                                    <Col lg="9" className="mb-0">
                                        <h2 className="ss-header mb-0">Requests</h2>
                                        <div className="divider"></div>
                                    </Col>
                                    <Col lg="3" className="mb-0 text-right">
                                        {/* <button className='button back view-button mb-3' onClick={() => navigate(-1)}><HiArrowLeft className='btn-icon me-3' />BACK</button> */}
                                    </Col>
                                </Row>
                            </div>
                            {inquiriesLoading ?
                                <>
                                    <Card>
                                        <CardBody className="text-center">
                                            <PageSpinner />
                                        </CardBody>
                                    </Card>
                                </>
                                :
                                <>
                                    {inquiries.length > 0 ?
                                        <>
                                            {inquiries.map(({ id, user, subject, message, created_at, status, listing }, index) => (
                                                <Card className="mb-3 p-2">
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg={8}>
                                                                
                                                                <p className="small muted mb-1">
                                                                    <FaUser /> <a href={`/admin/user?id=${user.id}`} >{user.name}</a>
                                                                </p>
                                                                <p className="small muted mb-1 ">
                                                                    {listing.listing_number}
                                                                </p>
                                                                <p className="mb-0">
                                                                    <strong>{listing.store_name}</strong>
                                                                </p>
                                                                <div className="divider-small"></div>
                                                                <p>{message}</p>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <p className="small muted mb-0 ">
                                                                    <BiCalendarAlt /> {new Date(created_at).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric',  hour: '2-digit', minute:'2-digit' })}
                                                                </p>
                                                                
                                                            </Col>
                                                            <Col lg={12}>
                                                                {status == "Locked" ?
                                                                    <div className="lock-unlock-container" onClick={function(){ unlockStatus(id)}}>
                                                                        <AiFillLock size="25px" color="#D9524E" />
                                                                    </div>
                                                                    :
                                                                    <div className="lock-unlock-container" onClick={function(){ lockStatus(id)}}>
                                                                        <AiFillUnlock size="25px" color="#5DB85D" />
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                                
                                            ))}
                                        </>
                                        :
                                        <>
                                            <Card>
                                                <CardBody className="text-center">
                                                    <p className="mb-0">No records found.</p>
                                                </CardBody>
                                            </Card>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AdminInquiriesPage;